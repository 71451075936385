export const privateRoutes = {
  users: {
    users: { route: '/users', regx: /\/users/, availableFor: [7] },
    user: { route: '/users/:id',  regx: /\users\/:id/, availableFor: [] },
    userNew: { route: '/users/new', regx: /\/users\/new/, availableFor: [] }
  },
  projects: {
    projects: { route: '/projects', regx: /\/projects/, availableFor: [3, 7, 4] },
    project: { route: '/projects/:id', regx: /\/projects\/:id/, availableFor: [3, 7] },
    projectNew: { route: '/projects/new', regx: /\/projects\/new/, availableFor: [3] }
  },
  tasks: {
    tasks: { route: '/tasks', regx: /\/tasks/, availableFor: [ 3, 7, 2, 6, 4] },
    task: { route: '/tasks/:id', regx: /\/tasks\/:id/, availableFor: [7, 2, 6, 4] },
  },
  clients: {
    clients: { route: '/clients', regx: /\/clients/, availableFor: [3] },
    client: { route: '/clients/:id', regx: /\/clients\/:id/, availableFor: [3] },
    clientNew: { route: '/clients/new', regx: /\/clients\/new/, availableFor: [3] }
  },
  settings: {
    settings: { route: '/settings', regx: /\/settings/, availableFor: [] },
    filetypes: { route: '/settings/filetypes', regx: /\/settings\/filetypes/, availableFor: [] },
  },
  reports: {
    reports: { route: '/reports', regx: /\/reports/, availableFor: [] },
    projects: { route: '/reports/projects', regx: /\/reports\/projects/, availableFor: [] },
  },
  study: {
    study: { route: '/study', regx: /\/study/, availableFor: [1,2,3,4,5,6,7] },
    oneStudy: { route: '/study/:id', regx: /\/study\/:id/, availableFor: [] },
    newStudy: { route: '/study/new', regx: /\/study\/new/, availableFor: [] },
  }
  
};