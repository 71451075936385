import { fork, all } from 'redux-saga/effects';

import { handleSigninSubmit, } from './auth/data/saga';
import usersSaga from './users/data/saga';
import settingsSaga from './settings/data/saga';
import clientsSaga from './clients/data/saga';
import projectsSaga from './projects/data/saga';
import tasksSaga from './tasks/data/saga';
import notifSaga from './notifications/data/saga';
import reportsWatcher from './reports/data/saga';
import studyWatcher from './study/data/saga';

export default function* rootSaga() {
  yield all([
    fork(handleSigninSubmit),
    fork(usersSaga),
    fork(settingsSaga),
    fork(clientsSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(notifSaga),
    fork(reportsWatcher),
    fork(studyWatcher),
  ]);
}
