import { useEffect, useState } from "react";
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid, Checkbox, ListItem as MListItem, List, ListItemIcon, ListItemText, TableSortLabel, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authActions from 'modules/auth/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, CheckBoxItem, ListItem, Popover } from 'shared/Molecules';
import { Icon } from 'shared/Atoms';
import usersActions from 'modules/users/data/actions';
import { Link } from 'react-router-dom';
import { checkForbiddenAccess, checkAvailableAccess } from 'utils/helperFunctions';
import { useHistory } from 'react-router-dom';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

    .blocked-user {
      border-radius: 5px;
      background: red;
      padding: 5px;
      color: #fff;
      font-size: 9px;
    }

  .MuiTableSortLabel-icon {
    opacity: 0.2 ;
    color: #8f9bb3 !important;
  }
`;

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, control, watch } = useForm();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(1000);
  const [order, setOrder] = useState('default');
  const [orderBy, setOrderBy] = useState('surname');
  const [filterRoles, setFilterRoles] = useState([1, 2, 3, 4, 5, 6, 7]);

  useEffect(() => {
    const func = () => {
      dispatch(usersActions.getUsers.request({
        page,
        perPage,
        order,
        orderBy,
        filterRoles: JSON.stringify(filterRoles),
      }))
    }
    func();
    let intervalId = setInterval(func, 60000)
    return () => clearInterval(intervalId);
  }, [order, orderBy, filterRoles])

  useEffect(() => {
    dispatch(usersActions.getRoles.request())
  }, [])

  const { roles } = useSelector(state => state.usersReducer)

  const { users } = useSelector(state => state.usersReducer)
  const createSortHandler = (property) => {
    if (orderBy === property) {
      setOrder(prev => prev === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(property);
      setOrder('desc')
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(undefined);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverId(undefined)
  };

  const showFilter = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id)
    console.log(id)
  }

  const handleToggleFilterRoles = (value) => () => {
    const currentIndex = filterRoles.indexOf(value);
    const newChecked = [...filterRoles];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilterRoles(newChecked);
    // handleClosePopover()
  };

  const headCells = [
    { id: 'surname', label: 'ФИО', points: 4, sort: true, filter: false },
    { id: 'roles', label: 'Роль', points: 4, sort: false, filter: true },
    { id: 'phone', label: 'Телефон', points: 2, sort: false, filter: false },
    { id: 'inWork', label: 'В работе', points: 2, sort: true, filter: false },
  ];

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Пользователи</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OutlinedInputField
            makedStyles='mb-15'
            type="text"
            label=""
            placeholder="Поиск по ФИО"
            name="userSearch"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          {checkAvailableAccess([]) &&
            <ButtonLink to="/users/new" fullWidth={true} type="submit" variant="contained">+ Создать пользователя</ButtonLink>}
        </Grid>
        {headCells.map(({ id, label, points, sort, filter }) => {
          return (
            <Grid item xs={points} key={id} className='d-flex'>
              {sort ?
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'desc'}
                  onClick={() => createSortHandler(id)}
                >
                  {label}
                </TableSortLabel>
                :
                label
              }

              {filter &&
                <Icon
                  name='settings'
                  className={`text-gray ${!sort ? 'ml-5' : ''}`}
                  onClick={(event) => showFilter(event, id)}
                />}
            </Grid>
          )
        })}
        <Grid item xs={12} component="ul">
          {users?.items?.filter(({ surname, name, patronymic }) => (surname + ' ' + name + ' ' + patronymic)?.toLowerCase().includes(watch('userSearch')?.toLowerCase()))
            .map(({ id, surname, name, patronymic, roles, active, phone, inWork }) => {
              let crassRoles = roles.filter(({ roleId }) => filterRoles.includes(roleId))
              if (crassRoles.length === 0) return null;
              return (
                <ListItem className='p-15' onClick={() => checkAvailableAccess([]) ? history.push(`users/${id}`) : null}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {surname} {name} {patronymic}
                      {active !== 1 ? <><br/><br/><span className="blocked-user">Заблокирован</span></> : ''}
                    </Grid>
                    <Grid item xs={4}>
                      {roles?.map(({ label }) => label + ' ')}
                    </Grid>
                    <Grid item xs={2} className='text-center'>{phone}</Grid>
                    <Grid item xs={2} className='text-center'>{inWork}</Grid>
                  </Grid>
                </ListItem>
              )
            })}
        </Grid>
      </Grid>
      <Popover
        handleClick={handleOpenPopover}
        handleClose={handleClosePopover} anchorEl={anchorEl} id={popoverId}
      >
        <List dense={true}>
          {roles?.map(({ id, label }) => {
            const labelId = `checkbox-list-label-${id}`;
            return (
              <MListItem key={id} button onClick={handleToggleFilterRoles(id)} className='py-0'>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={filterRoles.indexOf(id) !== -1}
                    tabIndex={-1}
                    color="primary"
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={label} />
              </MListItem>
            );
          })}
        </List>
      </Popover>
    </ContentWrapperBlock >
  );
}

export default User
