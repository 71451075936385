import { createRequestTypes, action } from '../../common/actions';

export const UPLOAD_FILES = createRequestTypes('UPLOAD_FILES');
export const GET_PROJECTS = createRequestTypes('GET_PROJECTS');
export const GET_PROJECT = createRequestTypes('GET_PROJECT');
export const GET_PROJECTS_STATUSES = createRequestTypes('GET_PROJECTS_STATUSES');

export const SET_PROJECT_STATUS = createRequestTypes('SET_PROJECT_STATUS');

const projectsActions = {
  getProjects: {
    request: (data) => action(GET_PROJECTS.REQUEST, { payload: data }),
    success: (data) => action(GET_PROJECTS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_PROJECTS.FAILURE, { payload: error }),
  },

  getProject: {
    request: (data) => action(GET_PROJECT.REQUEST, { payload: data }),
    success: (data) => action(GET_PROJECT.SUCCESS, { payload: data }),
    failure: (error) => action(GET_PROJECT.FAILURE, { payload: error }),
  },

  getProjectsStatuses: {
    request: () => action(GET_PROJECTS_STATUSES.REQUEST),
    success: (data) => action(GET_PROJECTS_STATUSES.SUCCESS, { payload: data }),
    failure: (error) => action(GET_PROJECTS_STATUSES.FAILURE, { payload: error }),
  },

  setProjectStatus: {
    request: (data) => action(SET_PROJECT_STATUS.REQUEST, { payload: data }),
    success: (data) => action(SET_PROJECT_STATUS.SUCCESS, { payload: data }),
    failure: (error) => action(SET_PROJECT_STATUS.FAILURE, { payload: error }),
  },

  uploadFiles: {
    request: (files, data, callback) => action(UPLOAD_FILES.REQUEST, { files, payload: data, callback }),
    success: (data) => action(UPLOAD_FILES.SUCCESS, { payload: data }),
    failure: (error) => action(UPLOAD_FILES.FAILURE, { payload: error }),
  },
};

export default projectsActions;
