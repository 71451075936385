import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  getProjectExcelReport: async (payload) => request.get(`/reports/projects/excel?from=${payload.from}&to=${payload.to}&type=${payload.type}`),
  downloadReport: async (payload) => {
    return request.get(`/reports/download/${payload.fileName}/${payload.fileType}`, { responseType: 'blob' })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${payload.fileName}.${payload.fileType}`)
        document.body.appendChild(link);
        link.click();
      })
  }
};

export default apiWrapper(api);
