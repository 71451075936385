import { GET_FILE_TYPES, PUT_FILE_TYPE, POST_FILE_TYPE, SIGNOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from './actions';

const initialState = {
  fileTypes: [],
  fileTypesLoading: false,
  fileTypesError: false,

  putFileTypesLoading: false,
  putFileTypesError: false,

  postFileTypesLoading: false,
  postFileTypesError: false,
};

function settingsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILE_TYPES.REQUEST:
      return { ...state, fileTypesLoading: true, fileTypesError: false };

    case GET_FILE_TYPES.SUCCESS:
      return {
        ...state,
        fileTypesLoading: false,
        fileTypesError: false,
        fileTypes: payload.data,
      };

    case GET_FILE_TYPES.FAILURE:
      return {
        ...state,
        fileTypesLoading: false,
        fileTypesError: payload.message,
      };

    case PUT_FILE_TYPE.REQUEST: return { ...state, putFileTypesLoading: true, putFileTypesError: false, };
    case PUT_FILE_TYPE.SUCCESS: return { ...state, putFileTypesLoading: false, putFileTypesError: false, };
    case PUT_FILE_TYPE.FAILURE: return { ...state, putFileTypesLoading: false, putFileTypesError: payload.message, };


    case POST_FILE_TYPE.REQUEST: return { ...state, postFileTypesLoading: true, postFileTypesError: false, };
    case POST_FILE_TYPE.SUCCESS: return { ...state, postFileTypesLoading: false, postFileTypesError: false, };
    case POST_FILE_TYPE.FAILURE: return { ...state, postFileTypesLoading: false, postFileTypesError: payload.message, };
    // case SIGNOUT.REQUEST:
    //   return { ...state, loading: true, error: false };

    // case SIGNOUT.SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     isLoggedIn: false,
    //   };

    // case SIGNOUT.FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     isLoggedIn: false,
    //     error: payload.message,
    //   };

    // case FORGOT_PASSWORD.REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: false,
    //     username: payload.username,
    //   };

    // case FORGOT_PASSWORD.SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     changePasswordVisible: true,
    //     forgotPasswordVisible: false,
    //     isForgotForm: true,
    //   };

    // case FORGOT_PASSWORD.FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: payload.message,
    //   };

    // case CHANGE_PASSWORD.REQUEST:
    //   return { ...state, loading: true, error: false };

    // case CHANGE_PASSWORD.SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: false,
    //     changePasswordVisible: false,
    //   };

    // case CHANGE_PASSWORD.FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: payload.message,
    //   };

    default:
      return state;
  }
}

export default settingsReducer;
