import { UPLOAD_FILES, GET_PROJECTS, GET_PROJECT, GET_PROJECTS_STATUSES, SET_PROJECT_STATUS } from './actions';

const initialState = {
  projects: [],
  loading: false,
  error: false,

  project: {},
  projectLoading: false,
  projectError: false,

  projectsStatuses: [],
  projectsStatusesLoading: false,
  projectsStatusesError: false,

  uploadLoading: false,
  uploadError: false,
};

function projectsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROJECTS.REQUEST: return { ...state, loading: true, error: false };
    case GET_PROJECTS.SUCCESS: return { ...state, loading: false, error: false, projects: payload.data };
    case GET_PROJECTS.FAILURE: return { ...state, loading: false, error: payload.message };

    case GET_PROJECT.REQUEST: return { ...state, projectLoading: true, projectError: false };
    case GET_PROJECT.SUCCESS: return { ...state, projectLoading: false, projectError: false, project: payload.data };
    case GET_PROJECT.FAILURE: return { ...state, projectLoading: false, projectError: payload.message };

    case GET_PROJECTS_STATUSES.REQUEST: return { ...state, projectsStatusesLoading: true, projectsStatusesError: false };
    case GET_PROJECTS_STATUSES.SUCCESS: return { ...state, projectsStatusesLoading: false, projectsStatusesError: false, projectsStatuses: payload.data };
    case GET_PROJECTS_STATUSES.FAILURE: return { ...state, projectsStatusesLoading: false, projectsStatusesError: payload.message };

    case UPLOAD_FILES.REQUEST: return { ...state, uploadLoading: true, uploadError: false };
    case UPLOAD_FILES.SUCCESS: return { ...state, uploadLoading: false, uploadError: false };
    case UPLOAD_FILES.FAILURE: return { ...state, uploadLoading: false, uploadError: payload.message };    

    case SET_PROJECT_STATUS.SUCCESS: return { ...state };
    case SET_PROJECT_STATUS.FAILURE: return { ...state };    
    
    default:
      return state;
  }
}


export default projectsReducer;
