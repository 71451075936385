import { useEffect, useState } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TableSortLabel, Typography, Grid } from '@material-ui/core';
import clientsActions from 'modules/clients/data/actions';
import { ButtonLink, OutlinedInputField, ListItem } from 'shared/Molecules';
import { Link } from 'react-router-dom';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }

  .MuiTableSortLabel-icon {
    opacity: 0.2 ;
    color: #8f9bb3 !important;
  }
`;

const Tasks = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, } = useForm();

  let page = 1, perPage = 1000;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');

  const createSortHandler = (property) => {
    if (orderBy === property) {
      setOrder(prev => prev === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(property);
      setOrder('desc')
    }
  };
  
  // useEffect(() => {
  //   let intervalId = setInterval(() => {
     
  //   }, 60000)
  //   return () => clearInterval(intervalId);
  // }, [])

  useEffect(() => {
    let func = () =>  dispatch(clientsActions.getClients.request({ page, perPage, order, orderBy, search: watch('search') || '', }))
    func();
    let intervalId = setInterval(func, 60000)
    return () => clearInterval(intervalId);
  }, [order, orderBy, watch('search')])

  const { clients } = useSelector(state => state.clientsReducer);

  const headCells = [
    { id: 'id', label: 'ID', points: 2, },
    { id: 'client', label: 'Имя клинта', points: 6, },
    { id: 'totalProjectsInWork', label: 'Кол-во проектов в работе', points: 4, },
  ];

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Клиенты</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OutlinedInputField
            makedStyles='mb-15'
            type="text"
            label=""
            placeholder="Поиск по названию клиента"
            name="search"
            control={control}
          />
        </Grid>
        <Grid item xs={6}>
          <ButtonLink to="/clients/new" fullWidth={true} type="submit" variant="contained">+ Добавить нового клиента</ButtonLink>
        </Grid>
        <Grid item xs={12} component="ul">
          <Grid container spacing={2} className="p-10">
            {headCells.map(({ id, label, points }) => {
              return (
                <Grid item xs={points} key={id} className='d-flex'>
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'desc'}
                    onClick={() => createSortHandler(id)}
                  >
                    {label}
                  </TableSortLabel>
                </Grid>
              )
            })}
          </Grid>
          {clients?.items?.map(({ id, name, totalProjectsInWork }) => {
            return (
              <ListItem>
                <Link className="link" to={`clients/${id}`}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>{id}</Grid>
                    <Grid item xs={6}>{name}</Grid>
                    <Grid item xs={4}>{totalProjectsInWork}</Grid>
                  </Grid>
                </Link>
              </ListItem>
            )
          })}
        </Grid>
      </Grid>
    </ContentWrapperBlock>
  );
}

export default Tasks
