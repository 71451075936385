import { useEffect, useState } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Typography, Grid, IconButton } from '@material-ui/core';

import { Icon } from 'shared/Atoms';
import { ButtonMainAccent, OutlinedInputField, ListItem, OutlinedSelectField, ReactSelect } from 'shared/Molecules';
import { toast } from 'react-toastify';
import clientsActions from 'modules/clients/data/actions';
import settingsActions from 'modules/settings/data/actions';


const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }
`;

const NewClient = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch } = useForm();

  let page = 1, perPage = 1000;

  useEffect(() => {
    dispatch(clientsActions.getClients.request({ page, perPage }))
    dispatch(settingsActions.getFileTypes.request())
  }, [])

  const { fileTypes } = useSelector(state => state.settingsReducer);
  const [prices, setPrice] = useState([]);
  const addToPrice = () => {
    if (watch('fileType') && watch('price')) {
      setPrice(prev => [...prev.filter(({ fileType }) => fileType.id !== watch('fileType')),
      { fileType: fileTypes.filter((value) => value.id === watch('fileType'))[0], price: watch('price') }])
    }
  }

  const removeFromPrice = (id) => {
    setPrice(prev => [...prev.filter(({ fileType }) => fileType.id !== id)])
  }

  const onSubmit = data => {
    if (prices.length) {
      dispatch(clientsActions.postClient.request({ ...data, priceList: prices }));
    } else {
      toast.error('Необходимо заполнить хотябы одну позицию из прайса!')
    }
  };
  console.log(fileTypes)

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Клиенты</Typography>
      <FormControl
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className="w-100"
      >
        <Grid container spacing={2} xs={12}>
          <Grid item xs={6}>
            <OutlinedInputField
              makedStyles='mb-15'
              type="text"
              label="Имя"
              placeholder="Имя"
              name="name"
              control={control}
              rules={{ required: 'Поле обязательно к заполнению' }}
            />
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography className=" mb-30 semi-bold" variant="subtitle"> Прайс - лист (Увидит продажник) </Typography>
              </Grid>
              <Grid item xs={6}>
                <ReactSelect
                  makedStyles='mb-15'
                  label="Тип файла"
                  placeholder="Тип файла"
                  name="fileType"
                  control={control}
                  options={fileTypes.map(({ id, name }) => { return { value: id, label: name }})}
                />
              </Grid>
              <Grid item xs={4}>
                <OutlinedInputField
                  makedStyles='mb-15'
                  type="text"
                  label="Цена"
                  placeholder="Цена"
                  name="price"
                  control={control}
                />
              </Grid>
              <Grid item xs={2} className="center-content">
                <IconButton onClick={addToPrice}>
                  <Icon name="add" fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
            <OutlinedInputField
              type="text"
              label="Основные требования (Увидят верстальщики и контролёры) "
              placeholder="Основные требования"
              name="requirements"
              control={control}
              multiline
              minRows={8}
              defaultValue={''}
            />
          </Grid>
          <Grid item xs={6} className="mt-20">
            {prices.map(({ fileType, price }, i) => {
              console.log(prices)
              return (
                <ListItem className="d-flex  align-items-center justify-content-space-between px-15" key={i}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} className="d-flex  align-items-center justify-content-space-between">{fileType.name}</Grid>
                    <Grid item xs={2} className="d-flex  align-items-center justify-content-space-between">{price}</Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeFromPrice(fileType.id)}>
                        <Icon name="delete" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </Grid>

          <Grid item xs={12}>
            <ButtonMainAccent className="mt-10" fullWidth={true} type="submit" variant="contained">Добавить клиента</ButtonMainAccent>
          </Grid>
        </Grid>
      </FormControl>
    </ContentWrapperBlock>
  );
}

export default NewClient
