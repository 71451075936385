import { useEffect, useState, useRef } from 'react'
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid, IconButton, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import reportsActions from 'modules/reports/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, CheckBoxItem, ListItem, Loader, StrokeLabel, NativeDatePicker } from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { Icon, OutlinedInput } from 'shared/Atoms';
import { toast } from 'react-toastify';
import moment from 'moment';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
`;

const ProjectsExcel = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();




  const [reportType, setReportType] = useState('dates');

  const onSubmit = data => {
    dispatch(reportsActions.getProjectExcelReport.request({ ...data, type: reportType }

    ))
    // dispatch(authActions.signin.request(data));
  };

  return (
    <ContentWrapperBlock>
      <Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1">Отчеты по проектам в excel</Typography>
      <Grid container>
        <FormControl
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 mb-40"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ButtonMainAccent onClick={() => setReportType('dates')} fullWidth={true} variant="contained" className="text-white bg-darkGray">
                По датам
              </ButtonMainAccent>
            </Grid>
            <Grid item xs={6}>
              <ButtonMainAccent onClick={() => setReportType('projectNumbers')} fullWidth={true} variant="contained" className="text-white bg-success">
                По номерам проектов
              </ButtonMainAccent>
            </Grid>
            {
              reportType === 'dates' && (
                <>
                  <Grid item xs={12} ><Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1"> По датам</Typography></Grid>
                  <Grid item xs={5} >
                    <NativeDatePicker
                      required
                      fullWidth={true}
                      label="C"
                      placeholder="C"
                      name="from"
                      control={control}
                      rules={{ required: 'Поле обязательно к заполнению' }}
                    />
                  </Grid>
                  <Grid item xs={2} className='center-content text-bold' >
                    --
                  </Grid>
                  <Grid item xs={5} >
                    <NativeDatePicker
                      required
                      fullWidth={true}
                      label="По (включительно)"
                      placeholder="По "
                      name="to"
                      control={control}
                      rules={{ required: 'Поле обязательно к заполнению' }}
                    />
                  </Grid>
                </>
              )
            }
            {
              reportType === 'projectNumbers' && (
                <>
                  <Grid item xs={12} ><Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1">По номерам проектов</Typography></Grid>
                  <Grid item xs={5} >
                    <OutlinedInputField
                      required
                      type="number"
                      label="C"
                      placeholder="C"
                      name="from"
                      control={control}
                      defaultValue={''}
                      rules={{ required: 'Поле обязательно к заполнению' }}
                    />
                  </Grid>
                  <Grid item xs={2} className='center-content text-bold' >
                    --
                  </Grid>
                  <Grid item xs={5} >
                    <OutlinedInputField
                      required
                      type="number"
                      label="По (включительно)"
                      placeholder="По"
                      name="to"
                      control={control}
                      defaultValue={''}
                      rules={{ required: 'Поле обязательно к заполнению' }}
                    />
                  </Grid>
                </>
              )
            }
            <Grid item xs={12}>
              <ButtonMainAccent fullWidth={true} type="submit" variant="contained">Скачать отчет</ButtonMainAccent>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </ContentWrapperBlock >
  );

}

export default ProjectsExcel