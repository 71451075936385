import { useState, useEffect } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, List, ListItem as MListItem, ListItemIcon, Checkbox, ListItemText, Tooltip, TableSortLabel, IconButton } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, NativeDatePicker, ListItem, Popover } from 'shared/Molecules';
import { Icon, Typography, Select } from 'shared/Atoms';
import studyActions from 'modules/study/data/actions';
import studyServices from 'services/study';
import { projectStatusColorMapper } from 'shared/constants';
import { checkForbiddenAccess, checkAvailableAccess } from 'utils/helperFunctions';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }

  .MuiTableSortLabel-icon {
    opacity: 0.2 ;
    color: #8f9bb3 !important;
  }
`;

const Projects = () => {
  const dispatch = useDispatch();
  const { control, watch, setValue } = useForm();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [order, setOrder] = useState('default');
  const [orderBy, setOrderBy] = useState('id');


  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(studyActions.getStudyMaterials.request())
  }, [])

  useEffect(() => {
    setPage(1)
  }, [watch('search')])

  const { studyMaterials } = useSelector(state => state.studyReducer);

  const headCells = [
    { id: 'name', label: 'Наименование', points: 1, sort: false, filter: false },
    { id: 'status', label: 'Статус', points: 2, sort: false, filter: false },
    { id: 'client', label: 'Клиент', points: 2, sort: false, filter: false },
    { id: 'number', label: '№ в уч. клиента', points: 2, sort: false, filter: false },
    { id: 'salesman', label: 'Продажник', points: 2, sort: false, filter: false },
    { id: 'deadline', label: 'Срок сдачи', points: 3, sort: false, filter: false },
  ];

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Обучающие материалы</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OutlinedInputField
            makedStyles='mb-15'
            type="text"
            placeholder="Поиск по наименованию"
            name="search"
            control={control}
          />
        </Grid>
        {checkAvailableAccess([]) &&
          <Grid item xs={6}>
            <ButtonLink to="/study/new" fullWidth={true} type="submit" variant="contained">+ Добавить новый обучающий материал</ButtonLink>
          </Grid>
        }
        <Grid item xs={12} component="ul">
          {/* <Grid container spacing={2} className="p-10 mb-5" >
            {headCells.map(({ id, label, points, sort, filter }) => {
              return (
                <Grid item xs={points} key={id} className='d-flex'>
                  {sort ?
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'desc'}
                      onClick={() => createSortHandler(id)}
                    >
                      {label}
                    </TableSortLabel>
                    :
                    label
                  }

                  {filter &&
                    <Icon
                      aria-describedby={id}
                      name='settings'
                      className={`text-gray ${!sort ? 'ml-5' : ''}`}
                      // onClick={(event) => showFilter(event, id)}
                    />}
                </Grid>
              )
            })}

          </Grid> */}
          {studyMaterials?.length === 0 && <ListItem ><div className='text-center p-15'>Материалы не найдены</div></ListItem>}
          {studyMaterials?.filter(({name}) => name?.toLowerCase().includes(watch('search')?.toLowerCase())).map(({ id, name, type, link, clientFileName, serverFileName, dateTime }) => {
            return (
              <ListItem className='p-5 px-15'>
                <Grid container spacing={2} alignItems='center'>
                  {/* <Grid item xs={1} className="text-bold">{id}</Grid> */}
                  <Grid item xs={8} className='text-bold'>{name}</Grid>
                  <Grid item xs={2}>{dateTime}</Grid>
                  <Grid item xs={1}>
                    <IconButton
                      title={type === 'cloud' ? 'Открыть в новой вкладке' : 'Скачать материал'}
                      onClick={(event) => {
                        if (type === 'cloud') {
                          event.stopPropagation();
                          window.open(link, "_blank")
                        } else {
                          studyServices.downloadStudyMaterial(serverFileName, clientFileName)
                        }
                      }}>
                      <Icon name={type === 'cloud' ? 'doubleArrow' : 'cloudDownload'} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    {checkAvailableAccess([]) &&
                      <IconButton
                        title='Редактировать'
                        onClick={() => history.push(`study/${id}`)}>
                        <Icon name='edit'/>
                      </IconButton>
                    }
                  </Grid>
                </Grid>
              </ListItem>
            )
          })}
        </Grid>
      </Grid>
    </ContentWrapperBlock>
  );
}

export default Projects
