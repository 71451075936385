import { useEffect, useState } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Divider, List, ListItem as MListItem, ListItemText, AppBar, Tabs, Tab, Box } from '@material-ui/core';

import { Icon, Typography } from 'shared/Atoms';
import { ButtonMainAccent, OutlinedInputField, Popover, ListItem } from 'shared/Molecules';
import projectsActions from 'modules/projects/data/actions';
import usersActions from 'modules/users/data/actions';
import commonServices from 'services/common';
import { projectStatusColorMapper } from 'shared/constants';
import { checkForbiddenAccess, checkAvailableAccess } from 'utils/helperFunctions';

import SetExecutorForm from './SetExecutorForm';
import DevideFile from './DevideFile';
import ReadyItem from './ReadyItem';
import LinkItem from './LinkItem';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }

  .MuiBox-root {
     padding: 0;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const Project = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setValue, reset, register } = useForm();
  const { location } = useSelector(state => state.router);
  const { project } = useSelector(state => state.projectsReducer);
  const { executors } = useSelector(state => state.usersReducer);


  useEffect(() => {
    dispatch(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
    dispatch(usersActions.getExecutors.request())
    // dispatch(settingsActions.getFileTypes.request())
    return () => dispatch(projectsActions.getProject.success({ data: {} }))
  }, [])

  const { fileTypes } = useSelector(state => state.settingsReducer);

  const [valueTabs, setValueTabs] = useState(0);

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  const [openedTasks, setOpenedTasks] = useState({});

  const addOpenTask = (id, type) => {
    if (openedTasks[id] === type) {
      setOpenedTasks(prev => {
        let newO = { ...prev };
        delete newO[id];
        return newO
      })
    } else {
      setOpenedTasks(prev => {
        return { ...prev, [id]: type }
      })
    }
  }
  console.log(openedTasks)

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(undefined);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverId(undefined)
  };
  const showFilter = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id)
  }

  const setProjectStatus = (statusId) => {
    handleClosePopover();
    dispatch(projectsActions.setProjectStatus.request({ id: location.pathname.split('/')[2], statusId }))
  }

  const downloadAllFiles = () => {
    project?.tasks?.forEach(({ clientReadyFileName, serverReadyFileName }) => {
      commonServices.downloadFile(serverReadyFileName, clientReadyFileName)
    })
  }
  const { devideFileLoading } = useSelector(state => state.tasksReducer);
  if (!Object.keys(project).length) {
    return null;
  }

  const topContainerData = [
    { mapper: true, label: 'Статус', value: project.statusName },
    { mapper: false, label: 'Срок сдачи проекта', value: project.dateTimeEnd },
    { mapper: false, label: 'Осталось дней на выполнение', value: (project.dateDiff < 0 ? 'Просрочено на ' + project.dateDiff : project.dateDiff) + ' д.' },
    { mapper: false, label: 'Создатель проекта', value: project.userNameCreate },
  ];

  return (
    <ContentWrapperBlock>
      <Typography className="" variant="h1">
        Проект №{project.id}
        {checkAvailableAccess([3]) &&
          <IconButton onClick={(event) => showFilter(event, '9')}><Icon name='verticalDots' fontSize="medium" /></IconButton>
        }{' '}
        {project.numberInClient !== "" && 'Клиентский № ' + project.numberInClient}
      </Typography>

      <Popover
        handleClick={handleOpenPopover}
        handleClose={handleClosePopover}
        anchorEl={anchorEl}
        id={popoverId}
      >
        <List dense={true}>
          <MListItem button onClick={() => setProjectStatus(5)} className='py-0'>
            <ListItemText primary={'Отказ оплаты'} />
          </MListItem>
          <MListItem button onClick={() => setProjectStatus(4)} className='py-0'>
            <ListItemText primary={'Отменен'} />
          </MListItem>
        </List>
      </Popover>

      <Typography className=" mb-20 text-gray" fontSize="0.9" >
        <span className="mr-20"> Клиент: {project.clientName}</span>
        Дата создания: {project.dateTimeCreated}
      </Typography>

      <ListItem className='mb-30'>
        <div className="d-flex  align-items-center justify-content-space-between p-15 ">
          {topContainerData.map(({ mapper, label, value }) => (
            <div className="">
              <Typography className="text-gray" fontSize="1.1">{label}</Typography>
              <Typography className="text-bold d-flex align-items-center" fontSize="1.2">
                {mapper && <div className='mr-5 ' style={{ borderRadius: '50%', width: '10px', height: '10px', backgroundColor: projectStatusColorMapper[project.statusId] || 'black' }}></div>}
                {value}
              </Typography>
            </div>
          ))}
        </div>
        {
          project.statusId === 3 && checkAvailableAccess([3, 7]) &&
          <Grid container spacing={2} className='px-10 py-5' >
            <Grid item xs={4}>
              <ButtonMainAccent onClick={() => dispatch(projectsActions.setProjectStatus.request({ id: project.id, statusId: 6 }))} fullWidth={true} variant="contained" className="text-white">
                Завершить проект
              </ButtonMainAccent>
            </Grid>
            <Grid item xs={4}>
              <ButtonMainAccent onClick={downloadAllFiles} fullWidth={true} variant="contained" className="text-white bg-success">
                Скачать все файлы
              </ButtonMainAccent>
            </Grid>
            <Grid item xs={4}>
              <ButtonMainAccent onClick={() => commonServices.downloadZipFiles(project.id)} fullWidth={true} variant="contained" className="text-white bg-success">
                Скачать все файлы (zip)
              </ButtonMainAccent>
            </Grid>
          </Grid>
        }
      </ListItem>

      <AppBar position="static" color="default" className="bg-white" style={{ borderRadius: '10px', overflow: 'hidden', }}>
        <Tabs
          value={valueTabs}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Исходные файлы" {...a11yProps(0)} />
          <Tab label={`Задания в работе (${project?.tasks?.filter(({ statusId }) => statusId === 2 || statusId === 4 || statusId === 5 || statusId === 10).length})`} {...a11yProps(1)} />
          <Tab label={`На проверке (${project?.tasks?.filter(({ statusId }) => statusId === 3 || statusId === 6).length})`} {...a11yProps(2)} />
          <Tab label={`Выполнено (сдано 
            ${project?.tasks?.filter(({ pass, statusId }) => pass === 1 && statusId === 7).length} /
            ${project?.tasks?.filter(({ statusId }) => statusId === 7).length})`} {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OutlinedInputField
            type="text"
            label=""
            placeholder="Поиск по названию файла"
            name="search"
            control={control}
            className='mt-15'
          />
        </Grid>
      </Grid>

      <TabPanel value={valueTabs} index={0} className='w-100 p-0' >
        {project?.tasks?.filter(({ clientFileName }) => watch('search') ? (clientFileName).includes(watch('search')) : true)
          .map(({ id, clientFileName, projectId, serverFileName, pages, fileTypeName, statusId, pagesTypes }) => {
            return (
              <ListItem key={serverFileName} className='mt-15 px-15 py-10' pointer={false}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={5}>
                    <div className="d-flex-column  justify-content-center h-100">
                      <span className="semi-bold">{projectId}_{clientFileName}</span>
                      {pagesTypes.map(({ id, fileTypeName, pages }) => {
                        return (
                          <div className="mt-5">
                            {fileTypeName} {' '} {pages} стр.
                          </div>
                        )
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton title='Скачать исходник' onClick={() => commonServices.downloadFile(serverFileName, clientFileName)}>
                      <Icon name='cloudDownload' className='text-primary' fontSize='medium' />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3}>
                    {statusId === 1 && !checkForbiddenAccess([3]) &&
                      <ButtonMainAccent disabled={devideFileLoading} onClick={() => addOpenTask(id, 'devide')} fullWidth={true} variant="contained" className="text-white bg-darkGray">
                        Раздробить
                      </ButtonMainAccent>
                    }
                  </Grid>
                  <Grid item xs={3}>
                    {statusId === 1 && !checkForbiddenAccess([3]) &&
                      <ButtonMainAccent disabled={devideFileLoading} onClick={() => addOpenTask(id, 'work')} fullWidth={true} variant="contained" className="text-white bg-success">
                        Отдать в работу
                      </ButtonMainAccent>
                    }
                  </Grid>
                  {Object.keys(openedTasks).includes('' + id) && statusId === 1 && <Grid item xs={12} className='py-0'> <Divider /></Grid>}
                  {
                    Object.keys(openedTasks).includes('' + id) && openedTasks[id] === 'devide' && statusId === 1 && (
                      <Grid item xs={12} >
                        <DevideFile taskId={id} pagesTypes={pagesTypes}  />
                      </Grid>
                    )
                  }
                  {
                    Object.keys(openedTasks).includes('' + id) && openedTasks[id] === 'work' && statusId === 1 && (
                      <SetExecutorForm id={id} />
                    )
                  }
                </Grid>
              </ListItem>
            )
          })}
      </TabPanel>
      <TabPanel value={valueTabs} index={1} >
        {project?.tasks?.filter(({ statusId }) => statusId === 2 || statusId === 4 || statusId === 5 || statusId === 10)
          .filter(({ clientFileName }) => watch('search') ? (clientFileName).includes(watch('search')) : true)
          .map((task, index) => <LinkItem key={index} {...task} />)}
      </TabPanel>
      <TabPanel value={valueTabs} index={2} >
        {project?.tasks?.filter(({ statusId }) => statusId === 3 || statusId === 6)
          .filter(({ clientFileName }) => watch('search') ? (clientFileName).includes(watch('search')) : true)
          .map((task, index) => <LinkItem key={index} {...task} />)}
      </TabPanel>
      {/* На проверке || Выполнено */}
      <TabPanel value={valueTabs} index={3} >
        {project?.tasks?.filter(({ statusId }) => statusId === 7)
          .filter(({ clientFileName }) => watch('search') ? (clientFileName).includes(watch('search')) : true)
          .map((task, index) => <ReadyItem key={index} {...task} />)}
      </TabPanel>
    </ContentWrapperBlock >
  );
}

export default Project
