import { Button as MButton } from "@material-ui/core";

import styled, { css } from "styled-components";

const StyledButton = styled(MButton)`
  // padding: 0.9em 1.2em;
  border-radius: 4px;
  text-transform: none;
  // font-weight: 600;
  // font-size: 1rem;
  // line-height: 1.25;
  height: 40px;

  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor} !important;
    `}
`;

const Button = ({ textColor = "", ...props }) => (
  <StyledButton textColor={textColor} {...props} />
);

export default Button;
