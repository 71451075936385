import TextMaskedInput from "react-text-mask";

const MaskedInput = ({ inputRef, ...restProps }) => (
  <TextMaskedInput
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    {...restProps}
  />
);

export default MaskedInput;
