import { useEffect, useState } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Divider, Stepper, Step, StepLabel, } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';

import { Icon, Typography } from 'shared/Atoms';
import { ButtonMainAccent, Popover, ListItem } from 'shared/Molecules';

import { taskStatusColorMapper } from 'shared/constants';
import tasksActions from 'modules/tasks/data/actions';
import commonServices from 'services/common';

import { checkForbiddenAccess, checkAvailableAccess } from 'utils/helperFunctions';
import { useHistory } from 'react-router-dom';

import ChangeUser from './ChangeUser';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }
`;

const Task = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { location } = useSelector(state => state.router);
  const { task } = useSelector(state => state.tasksReducer);

  useEffect(() => {
    dispatch(tasksActions.getTask.request({ id: location.pathname.split('/')[2] }))
    // dispatch(settingsActions.getFileTypes.request())
    // return () => dispatch(tasksActions.getTask.success({ data: {} }))
  }, [])

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(undefined);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverId(undefined)
  };

  const showFilter = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id)
  }

  const getActiveStep = (statusId) => {
    switch (statusId) {
      case 1:
      case 2:
        return 0;
      case 3:
      case 4:
      case 5:
      case 6:
      case 10:
        return 1;
      case 7:
        return 2;
      default:
        return false
    }
  }
  const { user } = useSelector(state => state.authReducer);

  if (!Object.keys(task).length) {
    return null;
  }

  let total = 0;
  console.log(task.executorId, task.controllerId, user?.id, task.statusId)
  if (checkAvailableAccess([7])) {
    total++;
  }
  if (task.executorId === user?.id ) {
    total++;
  }
  if (task.controllerId === user?.id ) {
    total++;
  }

  if (total === 0) {
    return <Redirect to='/' />
  }


  const topContainerData = [
    { edit: false, mapper: true, label: 'Статус', value: task.statusName },
    { edit: false, link: true, mapper: false, label: 'Проект', value: task.projectId },
    { id: 'executor', edit: true, mapper: false, label: 'Исполнитель', value: task.executorName },
    { id: 'controller', edit: true, mapper: false, label: 'Контролер', value: task.controllerName },
    { edit: false, mapper: false, label: 'Срок сдачи', value: task.dateTimeEnd },
    { edit: false, mapper: false, label: 'Сдано', value: task.dateTimeReady },
  ];

  const taskData = [
    { head: true, label: 'Исходный файл:', data: '' },
    { head: false, label: 'Имя файла - ', data: task.clientFileName },
    { head: false, label: 'Объем - ', data: task.pages + ' стр.' },
    { head: false, label: 'Типы страниц - ', data: task?.pagesTypes?.map(({fileTypeName, pages}, index ) => {
      return `${fileTypeName} - ${pages} стр.${index ===  task?.pagesTypes?.length -1 ? '' : ', '}`
    }).join(' ')},
    { head: false, label: 'Дата загрузки - ', data: task.dateTimeCreated },
    { head: false, label: 'Основные требования - ', data: task.requirements },
    { head: true, label: 'Комментарий: ', data: task.comment },
  ]

  return (
    <ContentWrapperBlock>
      <Typography className="" variant="h1" noWrap>
        Задание № {task.projectId}_{task.clientFileName}
      </Typography>
      <Typography className=" mb-20 text-gray" fontSize="0.9" >
        {/* <span className="mr-20"> Клиент: {project.clientName}</span>
        Дата создания: {project.dateTimeCreated} */}
      </Typography>
      <ListItem className='p-15' pointer={false}>
        <div className="d-flex  align-items-center justify-content-space-between  mb-20">
          {topContainerData.map(({ id, edit, mapper, link, label, value }) => {
            if (value === '' || value === null) {
              return null;
            }
            return (
              <div className=" ">
                <Typography className="text-gray d-flex align-items-center" fontSize="1.1">
                  {label}
                  {edit && (task.statusId < 7 || task.statusId === 10) && checkAvailableAccess([7]) && <Icon name='edit' onClick={(event) => showFilter(event, id)} />}
                </Typography>

                {link ? <Typography className="text-bold" fontSize="1.2">
                  <Link className="text-bold color-blue" to={"/projects/" + value}>
                    {value}
                  </Link>
                </Typography> :
                  <Typography className="text-bold d-flex align-items-center" fontSize="1.2">
                    {mapper && <div className='mr-5 ' style={{ borderRadius: '50%', width: '10px', height: '10px', backgroundColor: taskStatusColorMapper[task.statusId] || 'black' }}></div>}
                    {value}
                  </Typography>}
              </div>
            )
          })}
        </div>
        <Divider />
        {taskData.map(({ head, label, data }) => {
          if (head) {
            return <>
              <Typography className="mt-10" fontSize="1.2" style={{ textDecoration: 'underline' }}>
                {label}
              </Typography>
              {data ? <Typography component='span' className="text-gray mt-10" fontSize="1.2" >{data}</Typography> : ''}
            </>
          } else {
            if (data) {
              return (
                <Typography className="mt-10" fontSize="1" >
                  {label}
                  <Typography component='span' className="text-bold" fontSize="1.3" >{data}</Typography>
                </Typography>
              )
            }
          }
        })}
        <Divider className="mt-15" />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ButtonMainAccent onClick={() => commonServices.downloadFile(task.serverFileName, task.clientFileName)} variant="contained" className="mt-15" fullWidth={true} type="button" >Скачать исходник</ButtonMainAccent>
          </Grid>
          {task.serverErrorFileName !== null && task.serverErrorFileName !== '' &&
            <Grid item xs={4}>
              <ButtonMainAccent
                onClick={() => commonServices.downloadFile(task.serverErrorFileName, task.clientErrorFileName)}
                variant="contained"
                className="mt-15 bg-primary"
                fullWidth={true}
                type="button"
              >
                Скачать файл с ошибкой
              </ButtonMainAccent>
            </Grid>
          }

          {((task.statusId < 7 && task.statusId > 1) || task.statusId === 10) && checkAvailableAccess([7]) && <Grid item xs={4}>
            <ButtonMainAccent
              onClick={() => dispatch(tasksActions.clearTask.request({ taskId: task.id }))}
              className="mt-15 bg-danger"
              fullWidth={true}
              type="button"
              variant="contained"
            >
              Удалить задачу
            </ButtonMainAccent>
            <Typography className="text-center text-gray mt-5" fontSize='0.85'>Будут удалены все файлы, относящиеся к этой задаче, кроме исходного</Typography>
          </Grid>
          }
        </Grid>
      </ListItem>

      <Stepper activeStep={getActiveStep(task.statusId)} orientation="vertical">
        {(task.statusId > 2 || task.statusId === 2) && task.statusId !== 8 &&
          <Step key={8128} className="">
            <StepLabel>
              <StepOne
                statusId={task.statusId}
                taskId={task.id}
                executorName={task.executorName}
                clientFileName={task.clientReadyFileName}
                serverFileName={task.serverReadyFileName}
                fileTypeName={task.fileType}
                pages={task.pages}
                dateTimeReady={task.dateTimeReady}
                executorId={task.executorId}
                controllerId={task.controllerId}
              />
            </StepLabel>
          </Step>
        }
        {task.statusId > 2 && task.statusId !== 8 &&
          <Step key={8128} className="">
            <StepLabel>
              <StepTwo
                controllerName={task.controllerName}
                statusId={task.statusId}
                taskId={task.id}
                executorName={task.executorName}
                clientFileName={task.clientFileName}
                fileTypeName={task.fileType}
                pages={task.pages}
                dateTimeReady={task.dateTimeReady}
                readyComment={task.readyComment}
                commentForAll={task.commentForAll}
                executorId={task.executorId}
                controllerId={task.controllerId}
              />
            </StepLabel>
          </Step>
        }

        {task.statusId === 7 &&
          <Step key={8128678} className="">
            <StepLabel>
              <StepThree
                controllerName={task.controllerName}
                statusId={task.statusId}
                taskId={task.id}
                executorName={task.executorName}
                clientFileName={task.clientReadyFileName}
                serverFileName={task.serverReadyFileName}
                fileTypeName={task.fileType}
                pages={task.pages}
                dateTimeReady={task.dateTimeReady}
              />
            </StepLabel>
          </Step>
        }
      </Stepper>
      <Popover
        handleClick={handleOpenPopover}
        handleClose={handleClosePopover} anchorEl={anchorEl} id={popoverId}
      >
        <ChangeUser
          type={popoverId}
          taskId={task.id}
          controllerId={task.controllerId}
          executorId={task.executorId}
          handleClosePopover={handleClosePopover}
        />
      </Popover>
    </ContentWrapperBlock >
  );
}

export default Task
