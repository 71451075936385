import { useEffect, useState } from 'react'
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid, IconButton, Input } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import settingsActions from 'modules/settings/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, CheckBoxItem, ListItem, Loader, StrokeLabel } from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/Atoms';
import { toast } from 'react-toastify';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

`;

// const useStyles = makeStyles({
//   outerPositioning: {
//     marginBottom: '2em',
//   },
//   headerButtonLinkOuterPositioning: {
//     marginLeft: '1em',
//   },
//   formControl: {
//     display: 'block',
//     width: '400px',
//   },
// });


const Filetypes = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();

  const onSubmit = data => {
    console.log('here')
    dispatch(settingsActions.postFileTypes.request(
      data,
      () => {
        // setValue
      }
    ))
    // dispatch(authActions.signin.request(data));
  };

  useEffect(() => {
    dispatch(settingsActions.getFileTypes.request())
  }, [])

  // const handleSubmit = (data) => {
  //   console.log(data)
  // }

  const { fileTypes, putFileTypesLoading } = useSelector(state => state.settingsReducer);


  const [editingItemId, setEditingItemId] = useState();
  const [editingItemValue, setEditingItemValue] = useState();
  const setEditItem = (itemId, itemValue) => {
    setEditingItemId(itemId);
    setEditingItemValue(itemValue)
  }

  const submitEdit = () => {
    console.log('here')
    if (editingItemValue === '') {
      toast.error('Заполните пожалуйста поле!')
    } else {
      dispatch(settingsActions.putFileType.request(
        { id: editingItemId, fileType: editingItemValue },
        () => {
          setEditingItemId(0);
          setEditingItemValue('')
        }
      ))
    }
  }

  const submitDelete = () => {
    dispatch(settingsActions.putFileType.request(
      { id: editingItemId, fileType: '' },
      () => {
        setEditingItemId(0);
        setEditingItemValue('')
      }
    ))
  }

  return (
    <ContentWrapperBlock>

      <Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1">Управление типами файлов</Typography>
      <Grid container>
        <Grid item xs={12} component="ul">
          {
            fileTypes.map(({ id, name, active }) => {
              if (editingItemId === id) {
                return (
                  <ListItem className="d-flex align-items-center justify-content-space-between  px-15" key={id}>
                    <Input type="text" value={editingItemValue} onChange={value => setEditingItemValue(value.target.value)} required />
                    <div className="">
                      {
                        active ?
                          <IconButton onClick={submitDelete}>
                            <Icon name="delete" />
                          </IconButton>
                          :
                          <IconButton onClick={submitDelete}>
                            <Icon name="reDelete" />
                          </IconButton>
                      }

                      <IconButton onClick={submitEdit}>
                        <Icon name="check" />
                      </IconButton>
                    </div>
                  </ListItem>
                )
              } else {
                return (
                  <ListItem className="d-flex align-items-center justify-content-space-between  px-15" key={id}>
                    <span> {name} {active === 0 ? <StrokeLabel>Неактивен</StrokeLabel> : '' }</span> 
                    <IconButton onClick={() => setEditItem(id, name)}>
                      <Icon name="edit" />
                    </IconButton>
                  </ListItem>
                )
              }
            })
          }
        </Grid>

        <FormControl
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          className="w-100 mt-40"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <OutlinedInputField
                type="text"
                label=""
                placeholder="Введите тип файла"
                name="fileType"
                control={control}
                rules={{
                  required: 'Тип файла обязателен',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonMainAccent fullWidth={true} type="submit" variant="contained">Добавить</ButtonMainAccent>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </ContentWrapperBlock >
  );

}

export default Filetypes