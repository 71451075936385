import { useEffect, useState } from 'react';
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid, TextField, IconButton, Autocomplete } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clientsActions from 'modules/clients/data/actions';
import studyActions from 'modules/study/data/actions';
import settingsActions from 'modules/settings/data/actions';

import { Icon } from 'shared/Atoms';
import {
  ButtonLink, ButtonMainAccent, OutlinedInputField, ReactSelect, Footer, CheckBoxItem, ListItem, Loader, StrokeLabel, OutlinedSelectField, NativeDatePicker,
  AutocompleteSelectField, InputBaseFile
} from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import UploadImages from './upload-images.component'
// import UploadService from "./upload-files.service";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Button, withStyles } from '@material-ui/core';
// import FileItem from './FileItem'
import projectsService from 'services/projects';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);



const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }
`;

const useStyles = makeStyles({
  outerPositioning: {
    marginBottom: '2em',
  },
  headerButtonLinkOuterPositioning: {
    marginLeft: '1em',
  },
  formControl: {
    display: 'block',
    width: '400px',
  },
});


const NewStudyMaterial = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setValue, reset } = useForm();

  const { postStudyMaterialLoading } = useSelector(state => state.studyReducer);

  const [progress, setProgress] = useState(0);

  const onSubmit = (data) => {
    setProgress(0);
    dispatch(studyActions.postStudyMaterial.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))
  }

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Добавить новый обучающий материал</Typography>
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)} className="w-100">
        <Grid container spacing={2} xs={12}>
          <Grid item xs={6}>
            <ReactSelect
              isDisabled={postStudyMaterialLoading}
              makedStyles={classes.outerPositioning}
              required
              label='Тип'
              placeholder="Тип"
              control={control}
              name='type'
              rules={{ required: 'Поле обязательно к заполнению', }}
              options={[{ value: 'cloud', label: 'Ссылка на облако' }, { value: 'download', label: 'Загрузка на наш сервер' }]}
            />
            <OutlinedInputField
              required
              disabled={postStudyMaterialLoading}
              makedStyles={classes.outerPositioning}
              type="text"
              label="Наименование материала"
              placeholder="Наименование материала"
              name="name"
              control={control}
              rules={{ required: 'Поле обязательно к заполнению', }}
            />
            {watch('type') === 'cloud' &&
              <OutlinedInputField
                required
                disabled={postStudyMaterialLoading}
                makedStyles={classes.outerPositioning}
                type="url"
                label="Ссылка"
                placeholder="Ссылка"
                name="link"
                control={control}
                rules={{ required: 'Поле обязательно к заполнению', }}
              />}
            {watch('type') === 'download' &&
              <InputBaseFile
                required
                disabled={postStudyMaterialLoading}
                control={control}
                name={"file"}
                rules={{ required: 'Поле обязательно к заполнению' }}
              />
            }
          </Grid>
          <Grid item xs={12}>{postStudyMaterialLoading && <BorderLinearProgress className="mt-15" variant="determinate" value={progress} />}</Grid>
          <Grid item xs={12}>
            <ButtonMainAccent disabled={postStudyMaterialLoading} loading={postStudyMaterialLoading} className="mt-10" fullWidth={true} type="submit" variant="contained">
              Добавить
            </ButtonMainAccent>
          </Grid>
        </Grid>
      </FormControl>
    </ContentWrapperBlock >
  );
}

export default NewStudyMaterial
