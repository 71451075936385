import {
  GET_NOTIFS,
  READ_ONE,
  DELETE_ALL_NOTIFS,
  READ_ALL,
} from './actions';

const initialState = {
  notifs: [],
  loading: false,
  error: false,

  readLoading: false,
  readError: false,

  deleteAllLoading: false,
  deleteAllError: false,
};

function notifReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_NOTIFS.REQUEST: return { ...state, loading: true, error: false };
    case GET_NOTIFS.SUCCESS: return { ...state, loading: false, error: false, notifs: payload.data };
    case GET_NOTIFS.FAILURE: return { ...state, loading: false, error: payload.message };

    case READ_ONE.REQUEST: return { ...state, readLoading: true, readError: false };
    case READ_ONE.SUCCESS: return { ...state, readLoading: false, readError: false };
    case READ_ONE.FAILURE: return { ...state, readLoading: false, readError: payload.message };

    case READ_ALL.REQUEST: return { ...state, readLoading: true, readError: false };
    case READ_ALL.SUCCESS: return { ...state, readLoading: false, readError: false };
    case READ_ALL.FAILURE: return { ...state, readLoading: false, readError: payload.message };

    case DELETE_ALL_NOTIFS.REQUEST: return { ...state, deleteAllLoading: true, deleteAllError: false };
    case DELETE_ALL_NOTIFS.SUCCESS: return { ...state, deleteAllLoading: false, deleteAllError: false };
    case DELETE_ALL_NOTIFS.FAILURE: return { ...state, deleteAllLoading: false, deleteAllError: payload.message };

    default:
      return state;
  }
}

export default notifReducer;
