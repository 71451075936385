import { useState, useEffect } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
 import { Typography, Grid, Checkbox, ListItem as MListItem, List, ListItemIcon, ListItemText, TableSortLabel } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import tasksActions from 'modules/tasks/data/actions';
import { ButtonMainAccent, OutlinedInputField, ListItem, NativeDatePicker, Popover } from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/Atoms';
import { taskStatusColorMapper } from 'shared/constants';
import {checkForbiddenAccess, checkAvailableAccess} from 'utils/helperFunctions';
import TaskItem from './TaskItem';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }

  .MuiTableSortLabel-icon {
    opacity: 0.2 ;
    color: #8f9bb3 !important;
  }
`;

const Tasks = () => {
  const dispatch = useDispatch();

  const { control, watch, setValue } = useForm();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [order, setOrder] = useState('default');
  const [orderBy, setOrderBy] = useState('id');
  const [filterStatuses, setFilterStatuses] = useState([2, 3, 4, 5, 6, 10]);

  const { user } = useSelector(state => state.authReducer);
  useEffect(() => {
    dispatch(tasksActions.getTasksStatuses.request())
    if(user?.rolesId.length === 1 && user?.rolesId.includes(2)){
      setFilterStatuses([2, 4, 5, 10])
    }
    if(user?.rolesId.length === 1 && user?.rolesId.includes(6)){
      setFilterStatuses([2, 4, 5, 10])
    }
    if(user?.rolesId.length === 2 && user?.rolesId.includes(2) && user?.rolesId.includes(6)){
      setFilterStatuses([2, 4, 5, 10])
    }
    if(user?.rolesId.length === 1 && user?.rolesId.includes(4)){
      setFilterStatuses([3, 6])
    }
  }, [user?.rolesId])

    useEffect(() => {
      let func = () => dispatch(tasksActions.getTasks.request({
        page,
        perPage,
        order,
        orderBy,
        filterStatuses: JSON.stringify(filterStatuses),
        dateFrom: watch('dateFrom') ? watch('dateFrom').replace('_', "\\" + "_") : 0,
        dateTo: watch('dateTo') || 0,
        search: watch('search') || '',
      }))
      func()
      let intervalId = setInterval(func, 60000)
      return () => clearInterval(intervalId);
    }, [page, perPage, order, orderBy, filterStatuses, watch('dateFrom'), watch('dateTo'), watch('search')])

    const { tasks, tasksStatuses } = useSelector(state => state.tasksReducer);

    const headCells = [
      // { id: 'id', label: '№', points: 1, sort: true, filter: false },
      { id: 'name', label: 'Название', points: 3, sort: true, filter: false },
      { id: 'status', label: 'Статус', points: 2, sort: false, filter: true },
      { id: 'executor', label: 'Исполнитель', points: 2, sort: true, filter: false },
      { id: 'controller', label: 'Контролер', points: 2, sort: true, filter: false },
      { id: 'deadline', label: 'Срок сдачи', points: 2, sort: true, filter: true },
    ];

    const createSortHandler = (property) => {
      if (orderBy === property) {
        setOrder(prev => prev === 'asc' ? 'desc' : 'asc')
      } else {
        setOrderBy(property);
        setOrder('desc')
      }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverId, setPopoverId] = useState(undefined);

    const handleOpenPopover = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
      setAnchorEl(null);
      setPopoverId(undefined)
    };

    const showFilter = (event, id) => {
      setAnchorEl(event.currentTarget);
      setPopoverId(id)
    }

    const handleToggleFilterStatuses = (value) => () => {
      const currentIndex = filterStatuses.indexOf(value);
      const newChecked = [...filterStatuses];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setFilterStatuses(newChecked);
      handleClosePopover()

    };

    const resetDates = () => {
      handleClosePopover()
      setValue('dateFrom', 0);
      setValue('dateTo', 0);
    }

    let totalSpiped = 0; 
    return (
      <ContentWrapperBlock>
        <Popover
          handleClick={handleOpenPopover}
          handleClose={handleClosePopover} anchorEl={anchorEl} id={popoverId}
        >
          {popoverId === 'status' &&
            <List dense={true}>
              {tasksStatuses?.map(({ id, name }) => {
                const labelId = `checkbox-list-label-${id}`;

                return (
                  <MListItem key={id} button onClick={handleToggleFilterStatuses(id)} className='py-0'>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={filterStatuses.indexOf(id) !== -1}
                        tabIndex={-1}
                        color="primary"
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={name} />
                  </MListItem>
                );
              })}
            </List>}

          {popoverId === 'deadline' &&
            <div className='p-10' style={{ width: '250px' }}>
              <NativeDatePicker
                fullWidth={true}
                label="От"
                placeholder="От"
                name="dateFrom"
                control={control}
                className='mb-15'
              />
              <NativeDatePicker
                fullWidth={true}
                label="До"
                placeholder="До"
                name="dateTo"
                control={control}
                className='mb-15'
              />
              <ButtonMainAccent fullWidth={true} onClick={resetDates}>Сбросить</ButtonMainAccent>
            </div>
          }
        </Popover>
        <Typography className=" mb-30" variant="h1">Задания</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OutlinedInputField
              makedStyles='mb-15'
              type="text"
              label=""
              placeholder="Поиск по названию"
              name="search"
              control={control}
            />
          </Grid>
          <Grid item xs={12} component="ul">
            <Grid container spacing={2} className="p-10 mb-5" >
              {headCells.map(({ id, label, points, sort, filter }) => {
                return (
                  <Grid item xs={points} key={id} className='d-flex'>
                    {sort ?
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : 'desc'}
                        onClick={() => createSortHandler(id)}
                      >
                        {label}
                      </TableSortLabel>
                      :
                      label
                    }

                    {filter &&
                      <Icon
                        name='settings'
                        className={`text-gray ${!sort ? 'ml-5' : ''}`}
                        onClick={(event) => showFilter(event, id)}
                      />}
                  </Grid>
                )
              })}

            </Grid>
            {tasks?.totalItems === 0 && <ListItem ><div className='text-center p-15'>Задания не найдены</div></ListItem>}
            {tasks?.items?.map((task) => {
              if(checkAvailableAccess([3,7])) return <TaskItem {...task} user={user}/>
            
              if (
                (task.executorId === user?.id)  ||
                (task.controllerId === user?.id )
              ) {
                return <TaskItem {...task} user={user}/>
              } else {
                totalSpiped++;
                if (totalSpiped === tasks?.totalItems) {
                  return <ListItem ><div className='text-center p-15'>Задания не найдены</div></ListItem>
                }
                return null;
              }
            }
            )}
          </Grid>
        </Grid>
        {tasks?.totalItems / perPage > 1 && <Pagination className="d-flex justify-content-center" count={Math.ceil(tasks?.totalItems / perPage)} page={page} onChange={(event, value) => setPage(value)} />}
      </ContentWrapperBlock>
    );
  }

export default Tasks
