import {
  POST_STUDY_MATERIAL,
  GET_STUDY_MATERIALS,
  GET_STUDY_MATERIAL,
  DELETE_STUDY_MATERIAL,
  PUT_STUDY_MATERIAL,
} from './actions';

const initialState = {
  studyMaterials: [],
  loading: false,
  error: false,

  studyMaterial: {},

  // projectsStatuses: [],
  // projectsStatusesLoading: false,
  // projectsStatusesError: false,

  postStudyMaterialLoading: false,
  postStudyMaterialLoadingError: false,
};

function studyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_STUDY_MATERIALS.REQUEST: return { ...state, loading: true, error: false };
    case GET_STUDY_MATERIALS.SUCCESS: return { ...state, loading: false, error: false, studyMaterials: payload.data };
    case GET_STUDY_MATERIALS.FAILURE: return { ...state, loading: false, error: payload.message };

    case GET_STUDY_MATERIAL.REQUEST: return { ...state, loading: true, error: false };
    case GET_STUDY_MATERIAL.SUCCESS: return { ...state, loading: false, error: false, studyMaterial: payload.data };
    case GET_STUDY_MATERIAL.FAILURE: return { ...state, loading: false, error: payload.message };

    case DELETE_STUDY_MATERIAL.REQUEST: return { ...state, loading: true, error: false };
    case DELETE_STUDY_MATERIAL.SUCCESS: return { ...state, loading: false, error: false };
    case DELETE_STUDY_MATERIAL.FAILURE: return { ...state, loading: false, error: payload.message };

    case POST_STUDY_MATERIAL.REQUEST: return { ...state, postStudyMaterialLoading: true, postStudyMaterialLoadingError: false };
    case POST_STUDY_MATERIAL.SUCCESS: return { ...state, postStudyMaterialLoading: false, postStudyMaterialLoadingError: false };
    case POST_STUDY_MATERIAL.FAILURE: return { ...state, postStudyMaterialLoading: false, postStudyMaterialLoadingError: payload.message };

    case PUT_STUDY_MATERIAL.REQUEST: return { ...state, loading: true, error: false };
    case PUT_STUDY_MATERIAL.SUCCESS: return { ...state, loading: false, error: false };
    case PUT_STUDY_MATERIAL.FAILURE: return { ...state, loading: false, error: payload.message };   

    default:
      return state;
  }
}


export default studyReducer;
