import React from "react";
import { FormControlLabel } from "@material-ui/core";

import { Checkbox } from "../../Atoms";

import styled from "styled-components";

const StyledFormControlLabel = styled(FormControlLabel)`
  // .MuiCheckbox-root {
  //   color: #8c6fff;
  // }
`;

const CheckBoxItem = ({ name, label, ...props }) => (
  <StyledFormControlLabel
    control={<Checkbox name={name} {...props} />}
    label={label}
    labelPlacement="end"
  />
);

export default CheckBoxItem;
