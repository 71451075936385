import React from "react";
import styled from "styled-components";

import { Input } from "../../Atoms";

const Label = styled.label`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #c5cee0;
  cursor: pointer;

  .input-radio {
    display: none;
    &:checked + div {
      background-color: #eae2ff;
      color: #8c6fff;
    }
  }

  .container-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 12px 19px;
    color: #8f9bb3;
    font-weight: 600;
  }
`;

const ButtonRadio = ({ name, label, className, ...props }) => {
  return (
    <Label>
      <Input
        {...props}
        className={`input-radio ${className || ""}`}
        type="radio"
        name={name}
      />
      <div className="container-wrapper">{label}</div>
    </Label>
  );
};

export default ButtonRadio;
