import { createRequestTypes, action } from '../../common/actions';

export const GET_NOTIFS = createRequestTypes('GET_NOTIFS');
export const DELETE_ALL_NOTIFS = createRequestTypes('DELETE_ALL_NOTIFS');
export const READ_ONE = createRequestTypes('READ_ONE');
export const READ_ALL = createRequestTypes('READ_ALL');


const notifActions = {
  getNotifs: {
    request: (data) => action(GET_NOTIFS.REQUEST, { payload: data }),
    success: (data) => action(GET_NOTIFS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_NOTIFS.FAILURE, { payload: error }),
  },

  deleteAllNotifs: {
    request: (data) => action(DELETE_ALL_NOTIFS.REQUEST, { payload: data }),
    success: (data) => action(DELETE_ALL_NOTIFS.SUCCESS, { payload: data }),
    failure: (error) => action(DELETE_ALL_NOTIFS.FAILURE, { payload: error }),
  },

  readOne: {
    request: (data) => action(READ_ONE.REQUEST, { payload: data }),
    success: (data) => action(READ_ONE.SUCCESS, { payload: data }),
    failure: (error) => action(READ_ONE.FAILURE, { payload: error }),
  },

  readAll: {
    request: (data) => action(READ_ALL.REQUEST, { payload: data }),
    success: (data) => action(READ_ALL.SUCCESS, { payload: data }),
    failure: (error) => action(READ_ALL.FAILURE, { payload: error }),
  },
};

export default notifActions;
