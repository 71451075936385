import React from "react";

import { Box } from "@material-ui/core";

import styled, { css } from "styled-components";

const InfoForm = styled(Box)`
  height: fit-content;
  width: 560px;
  margin: 0;
  padding: 40px 80px;
  box-sizing: border-box;

  ${({ customStyle }) =>
    css`
      ${customStyle || ""}
    `}
`;

const FormBlock = ({ children, ...props }) => (
  <InfoForm {...props}>{children}</InfoForm>
);

export default FormBlock;
