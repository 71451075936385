import {
  GET_USERS,
  GET_USER,
  POST_USER,
  PUT_USER,
  GET_ROLES,
  BLOCK_USER,
  GET_SALESMANS,
  GET_EXECUTORS,
  GET_CONTROLLERS,
  DELETE_USER,
} from './actions';

const initialState = {
  users: {},
  loading: false,
  error: false,

  roles: [],
  rolesLoading: false,
  rolesError: false.valueOf,

  user: {},
  userLoading: false,
  userError: false,

  putUserLoading: false,
  putUserError: false,

  blockUserLoading: false,
  blockUserError: false,

  salesmans: [],
  salesmansLoading: false,
  salesmansError: false,

  executors: [],
  executorsLoading: false,
  executorsError: false,

  controllers: [],
  controllersLoading: false,
  controllersError: false,

  deleteUserLoading: false,
  deleteUserError: false,
};

function usersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'CLEAR_ALL': return initialState;

    case BLOCK_USER.REQUEST: return { ...state, blockUserLoading: true, blockUserError: false };
    case BLOCK_USER.SUCCESS: return { ...state, blockUserLoading: false, blockUserError: false };
    case BLOCK_USER.FAILURE: return { ...state, blockUserLoading: false, blockUserError: payload.message };

    case DELETE_USER.REQUEST: return { ...state, deleteUserLoading: true, deleteUserError: false };
    case DELETE_USER.SUCCESS: return { ...state, deleteUserLoading: false, deleteUserError: false };
    case DELETE_USER.FAILURE: return { ...state, deleteUserLoading: false, deleteUserError: payload.message };

    case PUT_USER.REQUEST: return { ...state, putUserLoading: true, putUserError: false };
    case PUT_USER.SUCCESS: return { ...state, putUserLoading: false, putUserError: false };
    case PUT_USER.FAILURE: return { ...state, putUserLoading: false, putUserError: payload.message };

    case GET_USER.REQUEST: return { ...state, userLoading: true, userError: false };
    case GET_USER.SUCCESS: return { ...state, userLoading: false, userError: false, user: payload.data };
    case GET_USER.FAILURE: return { ...state, userLoading: false, userError: payload.message };

    case GET_USERS.REQUEST: return { ...state, loading: true, error: false };
    case GET_USERS.SUCCESS: return { ...state, loading: false, error: false, users: payload.data };
    case GET_USERS.FAILURE: return { ...state, loading: false, error: payload.message, isLoggedIn: false };

    case POST_USER.REQUEST: return { ...state, loading: true, error: false };
    case POST_USER.SUCCESS: return { ...state, loading: false, error: false };
    case POST_USER.FAILURE: return { ...state, loading: false, error: payload.message };

    case GET_ROLES.REQUEST: return { ...state, rolesLoading: true, rolesError: false };
    case GET_ROLES.SUCCESS: return { ...state, rolesLoading: false, rolesError: false, roles: payload.data };
    case GET_ROLES.FAILURE: return { ...state, rolesLoading: false, rolesError: payload.message };

    case GET_SALESMANS.REQUEST: return { ...state, salesmansLoading: true, salesmansError: false };
    case GET_SALESMANS.SUCCESS: return { ...state, salesmansLoading: false, salesmansError: false, salesmans: payload.data };
    case GET_SALESMANS.FAILURE: return { ...state, salesmansLoading: false, salesmansError: payload.message };

    case GET_EXECUTORS.REQUEST: return { ...state, executorsLoading: true, executorsError: false };
    case GET_EXECUTORS.SUCCESS: return { ...state, executorsLoading: false, executorsError: false, executors: payload.data };
    case GET_EXECUTORS.FAILURE: return { ...state, executorsLoading: false, executorsError: payload.message };

    case GET_CONTROLLERS.REQUEST: return { ...state, controllersLoading: true, controllersError: false };
    case GET_CONTROLLERS.SUCCESS: return { ...state, controllersLoading: false, controllersError: false, controllers: payload.data };
    case GET_CONTROLLERS.FAILURE: return { ...state, controllersLoading: false, controllersError: payload.message };


    default:
      return state;
  }
}

export default usersReducer;
