import { createRequestTypes, action } from '../../common/actions';

export const GET_PROJECT_EXCEL_REPORT = createRequestTypes('GET_PROJECT_EXCEL_REPORT');

const reportsActions = {
  getProjectExcelReport: {
    request: (data) => action(GET_PROJECT_EXCEL_REPORT.REQUEST, { payload: data }),
    success: (data) => action(GET_PROJECT_EXCEL_REPORT.SUCCESS, { payload: data }),
    failure: (error) => action(GET_PROJECT_EXCEL_REPORT.FAILURE, { payload: error }),
  },
};

export default reportsActions;
