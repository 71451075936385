import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './auth/data/reducer';
import usersReducer from './users/data/reducer';
import settingsReducer from './settings/data/reducer';
import clientsReducer from './clients/data/reducer';
import projectsReducer from './projects/data/reducer';
import tasksReducer from './tasks/data/reducer';
import notifsReducer from './notifications/data/reducer';
import reportsReducer from './reports/data/reducer';
import studyReducer from './study/data/reducer';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    usersReducer,
    settingsReducer,
    clientsReducer,
    projectsReducer,
    tasksReducer,
    notifsReducer,
    reportsReducer,
    studyReducer,
  });

export default rootReducer;
