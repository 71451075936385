import React, { Suspense } from "react";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.min.css";
import CssBaseline from "@material-ui/core/CssBaseline";

import Routes from "./routes";
import configureStore, { history } from "../store";
import { ErrorBoundary } from "../shared/Components";
import { Loader } from "../shared/Molecules";
import { mainTheme } from "../shared/mainTheme";
import "../style/global.scss";
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

const {store, persistor} = configureStore({});

export {store};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={mainTheme}>
            <CssBaseline />
            <ErrorBoundary>
              <Suspense fallback={<Loader />}>
                <Routes />
                <ToastContainer />
              </Suspense>
            </ErrorBoundary>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
