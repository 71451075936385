import { Box, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { MainMenu } from './components';
import { NavLink } from 'react-router-dom';
import intersection from 'lodash/intersection';
import './Main.css';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'shared/Atoms';
import { checkAvailableAccess } from 'utils/helperFunctions';

const MainMenuWrapper = styled(Grid)`
  display: flex;
  background: #fff;
  justify-content: flex-end;
  margin: 0 auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;
const MainContentWrapper = styled(Grid)`
  max-width: 1140px;
  margin: 0 auto;

  .nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 635px;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    box-sizing: border-box;

    ul {
      padding: 0;
       li {
        list-style-type: none;
       }
    }
  }

  .nav-link {
    display: block;
    width: 100%;
    padding: 12px 10px ;
    margin-bottom: 10px;
    border-radius: 10px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 3px 8px 0px #0003;
    }
    .text {
      margin-left: 10px;
    }
  }
  .selected {
    background-color: #1B53F4;
    color: #fff;
   
  }

  .content {
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
  }
`;


const Main = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch({ type: 'CLEAR_ALL' })
    localStorage.clear()
  }
  const mainMenuRoutes = [
    { path: '/projects', label: 'Проекты', icon: 'folder', availableFor: [1, 3, 7, 4] },
    { path: '/tasks', label: 'Задания', icon: 'file', availableFor: [1, 3, 7, 2, 6, 4] },
    { path: '/clients', label: 'Клиенты', icon: 'clients', availableFor: [1, 3] },
    { path: '/users', label: 'Пользователи', icon: 'user', availableFor: [1, 7] },
    { path: '/settings', label: 'Настройки', icon: 'settings', availableFor: [1] },
    { path: '/reports', label: 'Отчеты', icon: 'assessment', availableFor: [1, 5] },
    { path: '/study', label: 'Обучение', icon: 'school', availableFor: [1,2,3,4,5,6,7] },
    // { path: '/exit', label: 'Выйти', icon: <Exit /> },
  ]
  return (
    <Box>
      <Grid container spacing={2} style={{ maxWidth: '1140px', margin: '0 auto', padding: '0 10px 6px 10px' }} >
        <MainMenuWrapper item xs={12} >
          <MainMenu />
        </MainMenuWrapper>
      </Grid>
      <MainContentWrapper container spacing={2}>
        <Grid item xs={2} >
          <nav className='nav'>
            <ul>
              {mainMenuRoutes.map(({ path, label, icon, availableFor }) => {
                if (!checkAvailableAccess(availableFor)) return;
                return (
                  <li>
                    <NavLink to={path} className="nav-link" activeClassName="selected">
                      <Icon name={icon} />
                      <span className="text"> {label} </span>
                    </NavLink>
                  </li>)
              })}
              <li className="nav-link" onClick={logOut}>
                <Icon name='exit' />
                <span className="text"> Выйти </span>
              </li>
            </ul>
          </nav>
        </Grid>
        <Grid item xs={10}>
          <div className="content">
            <Box>{children}</Box>
          </div>
        </Grid>
      </MainContentWrapper>
    </Box>
  );
};

export default Main;
