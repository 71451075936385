import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  getUsers: async (payload) => {
    console.log(payload)
    return request.get(`/users?page=${payload.page}&perPage=${payload.perPage}&order=${payload.order}&orderBy=${payload.orderBy}&filterRoles=${payload.filterRoles}`);
  },
  getUser: async (payload) => {
    console.log(payload)
    return request.get(`/users/${payload.userId}`);
  },
  getRoles: async () => request.get(`/users/roles`),
  postUser: async (payload) => {
    console.log(payload)
    return request.post(`/users`, payload);
  },
  putUser: async (payload) => {
    console.log(payload)
    return request.put(`/users/${payload.userId}`, payload);
  },
  blockUser: async (payload) => request.put(`/users/${payload.userId}/block`, payload),
  deleteUser: async (payload) => request.delete(`/users/${payload.userId}`, payload),
  getSalesmans: async () => request.get(`/users/salesmans`),
  getExecutors: async () => request.get(`/users/executors`),
  getControllers: async () => request.get(`/users/controllers`),
};

export default apiWrapper(api);
