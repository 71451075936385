import React from "react";

import { Box } from "@material-ui/core";

import styled from "styled-components";

import { CircularProgress } from "shared/Atoms";

const StyledLi = styled.li`
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #fff;
  color: #3A405F;
  // padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  list-style-type: none;
  ${({pointer}) => pointer ? 'cursor: pointer;' : ''}
  box-shadow: 0px 3px 8px 0px #00000020;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0px 3px 8px 0px #0006;
  }

`;

const ListItem = ({children, pointer = true, ...props}) => (
    <StyledLi pointer={pointer} {...props}>
        {children}
    </StyledLi>
);

export default ListItem;
