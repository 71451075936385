import React, { useState, Component } from "react";
import UploadService from "./upload-files.service";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography, Button, ListItem, withStyles } from '@material-ui/core';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 15,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: "#EEEEEE",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const UploadImages = () => {
    const [currentFiles, setCurrentFiles] = useState();
    const [previewImage] = useState();
    const [progress, setProgress] = useState(0);


    const [message] = useState('');
    const [isError] = useState(false);
    const [imageInfos] = useState([]);

    const selectFile = (event) => {
        setCurrentFiles(prev => {
            console.log(prev);
            console.log(event.target.files);
            if (prev) {
                return [...prev, ...Object.values(event.target.files)]
            } else {
                return [...Object.values(event.target.files)]
            }
        })
        // this.setState(prev => {
        //     console.log(prev.currentFiles);
        //     console.log(event.target.files);
        //     let currentFiles;
        //     if (prev.currentFiles) {

        //         currentFiles = [...prev.currentFiles, ...Object.values(event.target.files)]
        //     } else {
        //         currentFiles = [...Object.values(event.target.files)]
        //     }

        //     return {
        //         currentFiles: currentFiles,
        //         previewImage: URL.createObjectURL(event.target.files[0]),
        //         progress: 0,
        //         message: ""
        //     }
        // });
    }
    const upload = () => {
        setProgress(0)
        // this.setState({
        //     progress: 0
        // });

        UploadService.upload(currentFiles, (event) => {
            // this.setState({
            //     progress: 
            setProgress(Math.round((100 * event.loaded) / event.total))
            // });
        })
            .then((response) => {
                // this.setState({
                //     message: response.data.message,
                //     isError: false
                // });
                // return UploadService.getFiles();
            })
            .then((files) => {
                // this.setState({
                //     imageInfos: files.data,
                // });
            })
            .catch((err) => {
                // this.setState({
                //     progress: 0,
                //     message: "Could not upload the image!",
                //     currentFiles: undefined,
                //     isError: true
                // });
            });
    }

    console.log(currentFiles)
    return (
        <div className="mg20">
            <div className="file-name">
                {currentFiles ? Object.values(currentFiles)?.map(({ name }) => {
                    return <p>{name} </p>
                }) : ''}
            </div>
            <label htmlFor="btn-upload">
                <input
                    id="btn-upload"
                    name="btn-upload"
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    onChange={selectFile} />
                <Button
                    className="btn-choose"
                    variant="outlined"
                    component="span" >
                    Choose Image
                </Button>
            </label>
            
            <Button
                className="btn-upload"
                color="primary"
                variant="contained"
                component="span"
                disabled={!currentFiles}
                onClick={upload}>
                Upload
            </Button>

            {currentFiles && (
                <Box className="my20" display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <BorderLinearProgress variant="determinate" value={progress} />
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
                    </Box>
                </Box>)
            }

            {previewImage && (
                <div>
                    <img className="preview my20" src={previewImage} alt="" />
                </div>
            )}

            {message && (
                <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                    {message}
                </Typography>
            )}

            <Typography variant="h6" className="list-header">
                List of Images
            </Typography>
            <ul className="list-group">
                {imageInfos &&
                    imageInfos.map((image, index) => (
                        <ListItem
                            divider
                            key={index}>
                            <img src={image.url} alt={image.name} height="80px" className="mr20" />
                            <a href={image.url}>{image.name}</a>
                        </ListItem>
                    ))}
            </ul>
        </div >
    );
}

export default UploadImages