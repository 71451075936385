import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  postStudyMaterial: (formData, onUploadProgress) => request.post(`/study`, formData, {
    headers:
      { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  }),
  putStudyMaterial: (formData, id, onUploadProgress) => request.post(`/study/edit/${id}`, formData, {
    headers:
      { "Content-Type": "multipart/form-data" },
    onUploadProgress,
  }),
  getStudyMaterials: async () => request.get(`/study`),
  getStudyMaterial: async (payload) => request.get(`/study/${payload.id}`),
  deleteStudyMaterial: async (payload) => request.delete(`/study/${payload.id}`),
  downloadStudyMaterial: (serverFileName, clientFileName) => {
    serverFileName = serverFileName.split('.');
    return request.get('/study/download/' + serverFileName[0] + '/' + serverFileName[1], { responseType: 'blob' })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', clientFileName)
        document.body.appendChild(link);
        link.click();
      })
  },
};

export default apiWrapper(api);
