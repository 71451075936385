import { useEffect } from "react";
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authActions from 'modules/auth/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, ReactMultiSelect, Footer, CheckBoxItem, MaskedOutlinedInputField, OutlinedSelectField } from 'shared/Molecules';
import Loader from 'shared/Molecules/Loader';
import usersActions from 'modules/users/data/actions';
import { intersection } from 'lodash';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

`;

const UserItem = styled.li`
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #fff;
  color: #3A405F;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  list-style-type: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 8px 0px #0003;
  }
`;

const useStyles = makeStyles({
  outerPositioning: {
    // marginBottom: '2em',
  },
  headerButtonLinkOuterPositioning: {
    marginLeft: '1em',
  },
  formControl: {
    display: 'block',
    width: '400px',
  },
});


const User = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useSelector(state => state.router);
  const { handleSubmit, control, setValue, watch } = useForm();


  const { roles, user, userLoading } = useSelector(state => state.usersReducer)

  useEffect(() => {
    dispatch(usersActions.getUser.request({ userId: location.pathname.split('/')[2] }))
    dispatch(usersActions.getRoles.request())
    // setValue('phone', user?.phone)
    // console.log(user?.phone)
    return () => dispatch(usersActions.getUser.success({ data: {} }))
  }, [])



  const onSubmit = data => {
    dispatch(usersActions.putUser.request({ userId: user.id, ...data }));
  };

  const blockUser = () => {
    dispatch(usersActions.blockUser.request({ userId: user.id, active: user?.active }));
  }

  if (!Object.keys(user).length || !Object.keys(roles).length) {
    return null;
  } else {
    if (!watch('phone') && user?.phone) {
      setValue('phone', user?.phone)
    }
  }

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">{user?.surname} {user?.name}. {user?.patronymic}</Typography>
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)}>
        {Object.values(user).length > 0 &&
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <OutlinedInputField
                makedStyles='mb-15'
                type="text"
                label="Фамилия"
                placeholder="Фамилия"
                name="surname"
                control={control}
                defaultValue={user?.surname}
                rules={{ required: 'Поле обязательно к заполнению' }}
              />
              <OutlinedInputField
                makedStyles='mb-15'
                type="text"
                label="Имя"
                placeholder="Имя"
                name="name"
                control={control}
                defaultValue={user?.name}
                rules={{ required: 'Поле обязательно к заполнению' }}
              />
              <OutlinedInputField
                makedStyles='mb-15'
                type="text"
                label="Отчество"
                placeholder="Отчество"
                name="patronymic"
                control={control}
                defaultValue={user?.patronymic}
                rules={{ required: 'Поле обязательно к заполнению' }}
              />
              <MaskedOutlinedInputField
                label="Телефон"
                name="phone"
                placeholder="Телефон"
                defaultValue={user?.phone}
                mask={["+", /[1-9]/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/,]}
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInputField
                makedStyles='mb-15'
                type="email"
                label="E-mail"
                placeholder="E-mail"
                name="email"
                control={control}
                defaultValue={user?.email}
                rules={{
                  required: 'E-mail обязателен',
                  validate: value => validateEmail(value),
                }}
              />
              <OutlinedInputField
                makedStyles='mb-15'
                type="password"
                label="Пароль (Оставьте поле пустым, если не требуется менять пароль)"
                placeholder="Пароль"
                name="password"
                control={control}
                defaultValue={''}
              />
              <ReactMultiSelect
                label="Роль"
                placeholder="Роль"
                name="role"
                control={control}
                isMulti
                defaultValue={roles.filter(roledef => user?.roles?.find(role => role.value === roledef.value) ? true : false)}
                rules={{ required: 'Поле обязательно к заполнению' }}
                options={roles}
              />
               <OutlinedInputField
                makedStyles='mt-15'
                type="text"
                label="Телеграм"
                placeholder="Телеграм"
                name="telegram"
                control={control}
                defaultValue={user?.telegram}
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonMainAccent className="mt-10" fullWidth={true} type="submit" variant="contained">Сохранить изменения</ButtonMainAccent>
            </Grid>
            <Grid item xs={6}>
              <ButtonMainAccent
                className='bg-danger mt-10'
                onClick={() => dispatch(usersActions.deleteUser.request({ userId: user?.id }))}
                fullWidth={true}
                type="button"
                variant="contained"
              >
                Удалить пользователя
              </ButtonMainAccent>
            </Grid>
            <Grid item xs={6}>
              <ButtonMainAccent className='bg-danger mt-10' onClick={blockUser} fullWidth={true} type="button" variant="contained">
                {user?.active === 1 ? 'Заблокировать пользователя' : 'Разблокировать пользователя'}
              </ButtonMainAccent>
            </Grid>
          </Grid>
        }
      </FormControl>
    </ContentWrapperBlock >
  );

}

export default User
