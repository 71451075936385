import MTypography from "@material-ui/core/Typography";
import styled from 'styled-components';

const StyledMTypography = styled(MTypography)`
    ${({ fontSize }) => fontSize ? 'font-size: ' + fontSize + 'em' : ''};
`;

const Typography = ({fontSize, ...restProps}) => <StyledMTypography fontSize={fontSize} {...restProps} />;

export default Typography;
