import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.request.use(requestInterceptor);
request.interceptors.response.use(null, errorInterceptor);

const api = {
  getProjects: async (payload) => request.get(`/projects?page=${payload.page}&perPage=${payload.perPage}&order=${payload.order}&orderBy=${payload.orderBy}&filterStatuses=${payload.filterStatuses}&filterSalesman=${payload.filterSalesman}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}&search=${payload.search}`),
  getProject: async (payload) => request.get(`/projects/${payload.id}`),
  getProjectsStatuses: async () => request.get(`/projects/statuses`),
  setProjectStatus: async (payload) => request.put(`/projects/${payload.id}/status`, {statusId: payload.statusId} ),
  uploadFiles: (formData, onUploadProgress) => request.post("/projects/upload", formData, { headers: 
    { "Content-Type": "multipart/form-data"},
    onUploadProgress,
  }),
};

export default apiWrapper(api);
