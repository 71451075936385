import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { PublicRoute, PrivateRoute } from '../../modules/common/routes';
import { getUserId, getUser } from '../../modules/common/utils';

const RouteWithLayout = (props) => {
  const { isLoggedIn } = useSelector(state => state.authReducer)
   const isAuthenticated = isLoggedIn;

  const { layout: Layout, component: Component, protectedRoute, exact, ...rest } = props;

  return protectedRoute ? (
    <PrivateRoute
      isAuthenticated={isAuthenticated}
      exact={exact}
      component={(matchProps) => (
        <Layout {...rest}>
          <Component {...matchProps} />
        </Layout>
      )}
      {...rest}
    />
  ) : (
    <PublicRoute
      isAuthenticated={isAuthenticated}
      exact={exact}
      component={(matchProps) => (
        <Layout {...rest}>
          <Component {...matchProps} />
        </Layout>
      )}
      {...rest}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  protectedRoute: PropTypes.bool,
};

export default RouteWithLayout;
