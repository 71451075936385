import React from "react";

import { Box } from "@material-ui/core";

import styled from "styled-components";

import { CircularProgress } from "shared/Atoms";

const StyledStrokeLabel = styled.span`
    border-radius: 5px;
    background: red;
    padding: 5px;
    color: #fff;
    font-size: 9px;
    margin-left: 15px;
`;

const StrokeLabel = ({ children, ...props }) => (
    <StyledStrokeLabel {...props}>
        {children}
    </StyledStrokeLabel>
);

export default StrokeLabel;
