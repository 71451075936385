import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import CompanyLogo from './logo.png';

import './minimal.css';

const MainWrapper = styled(Grid)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const splitTextIntoLines = (text) => {
  return text.split('\n').map(str => <p className="heading" style={{ margin: '0' }}>{str}</p>);
}

const Minimal = (props) => {
  const { children, title, subTitle } = props;

  return (
    <MainWrapper container>
        {children}
    </MainWrapper>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
