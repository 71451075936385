import ReactSelect from 'react-select';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { InputLabel, OutlinedInput } from 'shared/Atoms';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    zIndex: 1000
  }),
  control: (provided, state) => ({
    ...provided,
    // color: state.selectProps.menuColor,
    minHeight: '40px',
  }),

  singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    return { ...provided, };
  }
}


const ReactMultiSelect = (props) => {


  const { label, name, makedStyles, rules, required, options, ...rest } = props;
  const {
    field: { ref,  onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control: props.control,
    rules,
    defaultValue: props.defaultValue || '',
  });
  // console.log(defaultValue)


  const onChangem = (value) => console.log(value);
  return (
    <FormControl className={clsx('w-100', makedStyles)}>
      {label && (
        <InputLabel shrink htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
      <ReactSelect
        isClearable={true}
        styles={customStyles}
        placeholder='Введите ...'
        // value={value}
        onChange={val => onChange(val)}
        options={options}
        {...inputProps}
        {...rest} />
      {/* <OutlinedInput
        id={name}
        className={styleClasses.input}
        inputRef={ref}
        {...inputProps}
        {...rest}
      /> */}

      {/* <Select
        isClearable={true}
        inputRef={ref}
        classNamePrefix="addl-class"
        value={options.find(c => c.value === value)}
        onChange={val => onChange(val?.value || null)}
        options={options} />
      ) */}

      {errors?.[name] && (
        <FormHelperText error id={name}>
          {errors?.[name]?.message}
          {errors?.[name]?.type === "minLength" && `Must be at least ${rules.minLength} characters`}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ReactMultiSelect;