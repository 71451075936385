import { Grid, IconButton, Divider } from '@material-ui/core';
import { Icon, Typography } from 'shared/Atoms';
import { ListItem } from 'shared/Molecules';
import commonServices from 'services/common';

const StepThree = ({ taskId, statusId,  executorName, controllerName, clientFileName, serverFileName, fileTypeName, pages, dateTimeReady, ...props }) => {
	return (
		<><ListItem className='mt-15 px-15 py-10' pointer={false}>
			<Typography className='text-bold' fontSize='2'>
				Готово к сдаче
			</Typography>
			<Divider className='my-15' />
			<ListItem className='mt-15 px-15 py-10' pointer={false}>
				<Grid container spacing={2} alignItems='center' >
					<Grid item xs={5}>
						<div className="d-flex-column  justify-content-center h-100">
							<span className="semi-bold">{clientFileName}</span>
							<div className="mt-5">
								{fileTypeName} {' '} {pages} стр.
							</div>
						</div>
					</Grid>
					<Grid item xs={1}>
						<IconButton title='Скачать готовый файл' onClick={() => commonServices.downloadFile(serverFileName, clientFileName)}>
							<Icon name='cloudDownload' className='text-primary' fontSize='medium' />
						</IconButton>
					</Grid>
					<Grid item xs={3}>
						<div className="">
							<Typography className="text-gray" fontSize="1">Исполнитель</Typography>
							<Typography className="text-bold" fontSize="1.1">{executorName}</Typography>
						</div>
					</Grid>
					<Grid item xs={3}>
						<div className="">
							<Typography className="text-gray" fontSize="1">Выполненно</Typography>
							<Typography className="text-bold" fontSize="1.1">{dateTimeReady}</Typography>
						</div>
					</Grid>
				</Grid>
			</ListItem>
		</ListItem>
		</>
	)
}

export default StepThree
