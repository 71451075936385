import { createRequestTypes, action } from '../../common/actions';

export const GET_USERS = createRequestTypes('GET_USERS');
export const GET_USER = createRequestTypes('GET_USER');
export const PUT_USER = createRequestTypes('PUT_USER');
export const BLOCK_USER = createRequestTypes('BLOCK_USER');
export const DELETE_USER = createRequestTypes('DELETE_USER');
export const POST_USER = createRequestTypes('POST_USER');
export const GET_ROLES = createRequestTypes('GET_ROLES');

export const GET_SALESMANS = createRequestTypes('GET_SALESMANS');
export const GET_EXECUTORS = createRequestTypes('GET_EXECUTORS');
export const GET_CONTROLLERS = createRequestTypes('GET_CONTROLLERS');

const usersActions = {
  getUsers: {
    request: (data) => action(GET_USERS.REQUEST, { payload: data }),
    success: (data) => action(GET_USERS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_USERS.FAILURE, { payload: error }),
  },

  postUser: {
    request: (data) => action(POST_USER.REQUEST, { payload: data }),
    success: (data) => action(POST_USER.SUCCESS, { payload: data }),
    failure: (error) => action(POST_USER.FAILURE, { payload: error }),
  },

  putUser: {
    request: (data) => action(PUT_USER.REQUEST, { payload: data }),
    success: (data) => action(PUT_USER.SUCCESS, { payload: data }),
    failure: (error) => action(PUT_USER.FAILURE, { payload: error }),
  },

  blockUser: {
    request: (data) => action(BLOCK_USER.REQUEST, { payload: data }),
    success: (data) => action(BLOCK_USER.SUCCESS, { payload: data }),
    failure: (error) => action(BLOCK_USER.FAILURE, { payload: error }),
  },

  deleteUser: {
    request: (data) => action(DELETE_USER.REQUEST, { payload: data }),
    success: (data) => action(DELETE_USER.SUCCESS, { payload: data }),
    failure: (error) => action(DELETE_USER.FAILURE, { payload: error }),
  },

  getUser: {
    request: (data) => action(GET_USER.REQUEST, { payload: data }),
    success: (data) => action(GET_USER.SUCCESS, { payload: data }),
    failure: (error) => action(GET_USER.FAILURE, { payload: error }),
  },

  getRoles: {
    request: () => action(GET_ROLES.REQUEST),
    success: (data) => action(GET_ROLES.SUCCESS, { payload: data }),
    failure: (error) => action(GET_ROLES.FAILURE, { payload: error }),
  },

  getSalesmans: {
    request: () => action(GET_SALESMANS.REQUEST),
    success: (data) => action(GET_SALESMANS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_SALESMANS.FAILURE, { payload: error }),
  },

  getExecutors: {
    request: () => action(GET_EXECUTORS.REQUEST),
    success: (data) => action(GET_EXECUTORS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_EXECUTORS.FAILURE, { payload: error }),
  },

  getControllers: {
    request: () => action(GET_CONTROLLERS.REQUEST),
    success: (data) => action(GET_CONTROLLERS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_CONTROLLERS.FAILURE, { payload: error }),
  },
};

export default usersActions;
