import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Grid, TextField, IconButton, Divider, InputBase } from '@material-ui/core';
import {FormHelperText} from 'shared/Atoms'

const InputBaseFile = ({id, disabled = false, ...props}) => {
	return (
		<>
			<Controller
				{...props}
				render={({
					field: { onChange, onBlur, value, name, ref },
					fieldState: { invalid, isTouched, isDirty, error },
					formState : {errors},
				}) => {
					return (
						<>
							<InputBase
							id={id}
							disabled={disabled}
								type='file'
								onChange={(event) => onChange(event.target.files[0])}
								inputRef={ref}
							/>
							{errors?.[props.name] && (
								<FormHelperText error >
									{errors?.[props.name]?.message}
								</FormHelperText>
							)}
						</>)
			}}
		/>
		

		</>
	)
}

export default InputBaseFile