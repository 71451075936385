import MomentUtils from "@date-io/moment";
import { withStyles } from "@material-ui/core";
import {
  KeyboardDateTimePicker as MKeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const StyledKeyboardDatePicker = withStyles({
  root: {
    padding: 0,
    width: "100%",
    borderRadius: "0.2em",
    boxSizing: "border-box",
    fontSize: "1.1em",
    position: "relative",
    overflow: "hidden",
    height: "2.78em",
    "label + &": {
      marginTop: "1.5em",
    },
    "& fieldset ": {
      borderColor: "#e4e9f2",
    },
  },
})(MKeyboardDateTimePicker);

const KeyboardDatePicker = (props) => (
  <MuiPickersUtilsProvider
    // className={styleClasses.muiPickersUtilsProvider}
    utils={MomentUtils}
  >
    <StyledKeyboardDatePicker
      margin="normal"
      disableFuture={true}
      id="date-picker-inline"
      valueName="selected"
      allowKeyboardControl={false}
      className={"date-picker-field"}
      variant="inline"
      inputVariant="outlined"
      format="DD MMM, yyyy"
      {...props}
    />
  </MuiPickersUtilsProvider>
);
export default KeyboardDatePicker;
