import { SIGNUP, SIGNIN, CURRENT_USER, SIGNOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from './actions';

const initialState = {
  isLoggedIn: false,
  user: {},
  loading: false,
  error: false,
};

function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'CLEAR_ALL':
      return initialState;


    case CURRENT_USER.SUCCESS:
      return {
        ...state,
        user: payload.data,
      };

    case SIGNUP.REQUEST:
      return { ...state, loading: true, error: false, isLoggedIn: false };

    case SIGNUP.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        // user: payload.data,
        // username: payload.data.user.username,
      };

    case SIGNUP.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.message,
        isLoggedIn: false,
      };

    case SIGNIN.REQUEST:
      return {
        ...state,
        username: payload,
        loading: true,
        error: false,
        isLoggedIn: false,
      };

    case SIGNIN.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: payload.user,
        isLoggedIn: true,
      };

    case SIGNIN.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.message,
        isLoggedIn: false,
        user: payload.user,
        // mfaFormType: payload.mfaFormType ? 'signin' : ''
      };

    case SIGNOUT.REQUEST:
      return { ...state, loading: true, error: false };

    case SIGNOUT.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isLoggedIn: false,
      };

    case SIGNOUT.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: payload.message,
      };

    case FORGOT_PASSWORD.REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        username: payload.username,
      };

    case FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        changePasswordVisible: true,
        forgotPasswordVisible: false,
        isForgotForm: true,
      };

    case FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.message,
      };

    case CHANGE_PASSWORD.REQUEST:
      return { ...state, loading: true, error: false };

    case CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        changePasswordVisible: false,
      };

    case CHANGE_PASSWORD.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.message,
      };

    default:
      return state;
  }
}

export default authReducer;
