import OpenSansRegular from 'assets/fonts/openSans/OpenSans-Regular.ttf';
import OpenSansSemiBold from 'assets/fonts/openSans/OpenSans-SemiBold.ttf';
import OpenSansBold from 'assets/fonts/openSans/OpenSans-Bold.ttf';
import ManropeExtraBold from 'assets/fonts/Manrope/Manrope-ExtraBold.ttf';

// import placeholderImage from 'assets/images/placeholderImage.svg';

export const taskStatusColorMapper = {
  1: '#ffed4a',
  2: '#6cb2eb',
  3: '#ffed4a',
  4: '#6cb2eb',
  5: '#6cb2eb',
  6: '#ffed4a',
  7: '#6c757d',
  8: '#3A405F',
  9: 'green',
  10: '#6cb2eb',
}

export const projectStatusColorMapper = {
  1: '#ffed4a',
  2: '#6cb2eb',
  3: 'green',
  4: 'gray',
  5: 'black',
  6: 'gray',
}

export const organizationAllRightReserved = 'ACME';

export const API_URLS = {};

export const BUTTON_TEXT_ONLY_COLOR = '#8f9bb3';

// export const PLACE_HOLDER_IMAGE = placeholderImage;

export const DEBOUNCE_CONSTANT = 700;

export const DATE_FORMAT = 'YYYY-MM-DD';
export const INPUT_DATE_FORMAT = 'MM/DD/YYYY';

export const PASSWORD_MIN_LENGTH = 6;

// to apply font on certain element, simply add a selector here
export const fontsMapper = {
  OpenSansRegular: {
    font: OpenSansRegular,
    selectors: ['*'],
  },
  OpenSansSemiBold: {
    font: OpenSansSemiBold,
    selectors: ['.sub-tilte', '.MuiFormLabel-root', '.semi-bold', 'label'],
  },
  OpenSansBold: {
    font: OpenSansBold,
    selectors: ['.MuiButton-label', '.text-bold'],
  },
  ManropeExtraBold: {
    font: ManropeExtraBold,
    selectors: ['h1', 'h2', 'h3', 'h4', '.heading'],
  },
};
