import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import DoneIcon from '@material-ui/icons/Done';
import RedoIcon from '@material-ui/icons/Redo';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SchoolIcon from '@material-ui/icons/School';

const iconsMapper = {
  add: AddIcon,
  close: CloseIcon,
  exclamation: ReportProblemIcon,
  edit: EditIcon,
  checked: CheckCircleIcon,
  calendar: InsertInvitationIcon,
  delete: DeleteOutlineIcon,
  check: CheckCircleOutlineIcon,
  reDelete: RestoreFromTrashIcon,
  file: InsertDriveFileOutlinedIcon,
  settings: SettingsOutlinedIcon,
  verticalDots: MoreVertOutlinedIcon,
  cloudDownload: CloudDownloadIcon,
  doubleArrow: DoubleArrowIcon,
  errorOutline: ErrorOutlineOutlinedIcon,
  done: DoneIcon,
  redo: RedoIcon,
  folder: FolderOpenIcon,
  file: DescriptionOutlinedIcon,
  user: PermIdentityOutlinedIcon,
  clients: AccountBalanceOutlinedIcon,
  exit: ExitToAppIcon,
  assessment: AssessmentIcon,
  school: SchoolIcon,
};

const Icon = ({ name, ...props }) => {
  const Comp = iconsMapper[name];

  return <Comp cursor={'pointer'} fontSize={'small'} {...props} />;
};

export default Icon;
