import { useState } from 'react';

import styled, { css } from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { Select as MSelect } from '@material-ui/core';

import { OutlinedInput } from '../../Atoms';

const Container = styled.div`
  .fake-placeholder {
    position: absolute;
    top: 2.3rem;
    left: 16px;
    font-size: 1.1em;
    color: #8f9bb3;
  }

  .MuiOutlinedInput-root {
    width: 100%;
    border-radius: 0.2em;
    box-sizing: border-box;
    font-size: 1.1em;
    position: relative;
    overflow: hidden;
    height: 3.1em;

    fieldset {
      border-color: #e4e9f2;
      ${({ isTouched }) =>
        css`
          background-color: ${isTouched ? 'transparent' : '#cccccc18'};
        `}
    }
  }
`;

const Select = ({ options, placeholder, value, isTouched, ...props }) => {
  const [openFakePlaceholder, setOpenFakePlaceholder] = useState(!value);
console.log(options)
  return (
    <Container>
      {!value && openFakePlaceholder && <span className={'fake-placeholder'}>{placeholder}</span>}
      <MSelect
        {...props}
        id={props.name}
        onOpen={() => setOpenFakePlaceholder(false)}
        onClose={() => setOpenFakePlaceholder(true)}
        input={
          <OutlinedInput
            isTouched={isTouched}
            {...props}
            inputProps={{
              onFocus: () => setOpenFakePlaceholder(false),
              onBlur: () => setOpenFakePlaceholder(true),
            }}
          />
        }
      >
        {options.map(({ value: _value, label, disabled  = false}, index) => (
          <MenuItem key={index} value={_value} disabled ={disabled }>
            {label}
          </MenuItem>
        ))}
      </MSelect>
    </Container>
  );
};

export default Select;
