import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.request.use(requestInterceptor);
request.interceptors.response.use(null, errorInterceptor);

const api = {
  getTasks: async (payload) => request.get(`/tasks?page=${payload.page}&perPage=${payload.perPage}&order=${payload.order}&orderBy=${payload.orderBy}&filterStatuses=${payload.filterStatuses}&dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}&search=${payload.search}`),
  getTask: async (payload) => request.get(`/tasks/${payload.id}`),
  getTasksStatuses: async () => request.get(`/tasks/statuses`),
  setExecutor: async (payload) => request.put(`/tasks/${payload.taskId}/executor`, payload),
  devideFile: (formData, taskId, onUploadProgress) => request.post(`/tasks/${taskId}/devide`, formData, { headers: 
    { "Content-Type": "multipart/form-data"},
    onUploadProgress,
  }),
  uloadReadyFile: (formData, taskId, onUploadProgress) => request.post(`/tasks/${taskId}/ready-file`, formData, { headers: 
    { "Content-Type": "multipart/form-data"},
    onUploadProgress,
  }),
  uploadErrorFile: (formData, taskId, onUploadProgress) => request.post(`/tasks/${taskId}/error-file`, formData, { headers: 
    { "Content-Type": "multipart/form-data"},
    onUploadProgress,
  }),
  uploadSuccessFile: (formData, taskId, onUploadProgress) => request.post(`/tasks/${taskId}/success-file`, formData, { headers: 
    { "Content-Type": "multipart/form-data"},
    onUploadProgress,
  }),

  changeUser: async (payload) => request.put(`/tasks/${payload.taskId}/user`, payload),
  changePass: async (payload) => request.put(`/tasks/${payload.taskId}/pass`, payload),
  clearTask: async (payload) => request.delete(`/tasks/${payload.taskId}`, payload),


  uploadFiles: (formData, onUploadProgress) => request.post("/projects/upload", formData, { headers: 
    { "Content-Type": "multipart/form-data"},
    onUploadProgress,
  }),
};

export default apiWrapper(api);
