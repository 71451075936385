import React from 'react';
import { useController } from 'react-hook-form';
import { TextField, FormHelperText } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { InputLabel } from 'shared/Atoms';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  input: {
    '& input': {
      height: '40px',
      paddingTop: '0',
      paddingBottom: '0',
    },
   
  },
});

const NativeDatePicker = props => {
  const { label, name, makedStyles, rules, control, labelProps = {}, required = false, ...rest } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue || '',
  });

  const styleClasses = useStyles({ isTouched });

  return (
    <FormControl className={clsx(styleClasses.formControl, makedStyles)}>
      {label && (
        <InputLabel shrink htmlFor={name} {...labelProps} required={required}>
          {label}
        </InputLabel>
      )}
      <TextField
        variant="outlined"
        type="date"

        className={styleClasses.input}
        inputRef={ref}
        {...inputProps}
        {...rest}
        inputProps={{
          max: props.max,
          min: props.min,
        }}
      />
      {errors?.[name] && (
        <FormHelperText error id={name}>
          {errors?.[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default NativeDatePicker;
