import { useEffect } from 'react'
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import notifsActions from 'modules/notifications/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, CheckBoxItem, ListItem } from 'shared/Molecules';
import { checkForbiddenAccess, checkAvailableAccess } from 'utils/helperFunctions';
import { useHistory } from 'react-router-dom';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
`;


const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(notifsActions.getNotifs.request())
  }, [])

  const { notifs } = useSelector(state => state.notifsReducer)

  const onItemClick = (id, status, type, targetId) => {
    if (status === 1) {
      dispatch(notifsActions.readOne.request({ id }))
    }
    // if( checkAvailableAccess([7])){
    history.push(`${type === 'task' ? 'tasks' : 'projects'}/${targetId}`)
    // }

  }
  return (
    <ContentWrapperBlock>
      <Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1">Уведомления</Typography>
      {notifs?.length > 0 &&
        <Grid container spacing={2} className="my-20">
          <Grid item xs={6}>
            <ButtonMainAccent fullWidth={true} onClick={() => dispatch(notifsActions.readAll.request())} variant="contained">
              Пометить все прочитанными
            </ButtonMainAccent>
          </Grid>
          <Grid item xs={6}>
            <ButtonMainAccent fullWidth={true} onClick={() => dispatch(notifsActions.deleteAllNotifs.request())} variant="contained">
              Удалить все уведомления
            </ButtonMainAccent>
          </Grid>
        </Grid>
      }
      {notifs?.length === 0 && <ListItem ><div className='text-center p-15'>Уведомления не найдены</div></ListItem>}
      {notifs?.map(({ id, msg, type, targetId, status, dateTime }) => {
        return (
          <ListItem
            key={id}
            className='p-15'
            onClick={() => onItemClick(id, status, type, targetId)}
          >
            <Grid container spacing={2} className={status ? 'text-bold' : ''}>
              <Grid item xs={9} >{msg}</Grid>
              <Grid item xs={3} >{dateTime}</Grid>
            </Grid>
          </ListItem>
        )
      })}
    </ContentWrapperBlock>
  );

}

export default Notifications