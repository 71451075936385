import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import clientsActions, { GET_CLIENTS, GET_CLIENT, PUT_CLIENT, POST_CLIENT } from 'modules/clients/data/actions';
import { REQUEST } from 'modules/common/actions';
import clientsServices from 'services/clients';

import { toast } from 'react-toastify'

export function* handleGetClients({ payload }) {
    try {
        payload.order = payload.order ? payload.order : 'desc';
        payload.orderBy = payload.orderBy ? payload.orderBy : 'id';
        payload.search = payload.search ? payload.search : '';

        const data = yield call(clientsServices.getClients, payload);
        yield put(clientsActions.getClients.success(data));
    } catch (error) {
        yield put(clientsActions.getClients.failure(error));
        yield call(toast.error, 'Ошибка получения клиентов!')
    }
}

export function* handleGetClient({ payload }) {
    try {
        const data = yield call(clientsServices.getClient, payload);
        yield put(clientsActions.getClient.success(data));
    } catch (error) {
        yield put(clientsActions.getClient.failure(error));
        yield call(toast.error, 'Ошибка получения клиентa!')
    }
}

export function* handlePostClient({ payload, callback = () => {} }) {
    try {
        const data = yield call(clientsServices.postClient, payload);
        yield put(clientsActions.postClient.success(data));
        yield call(toast.success, 'Клиент успешно добавлен!')
        yield put(push('/clients'))
    } catch (error) {
        yield put(clientsActions.postClient.failure(error));
        yield call(toast.error, 'Ошибка добавления клиента!')
    }
}

export function* handlePutClient({ payload, callback = () => {} }) {
    try {
        const data = yield call(clientsServices.putClient, payload);
        yield put(clientsActions.putClient.success(data));
        yield call(toast.success, 'Клиент успешно изменен!')
        yield put(push('/clients'))
    } catch (error) {
        yield put(clientsActions.putClient.failure(error));
        yield call(toast.error, 'Ошибка изменения клиента!')
    }
}

export default function* clientsWatcher() {
    yield takeLatest(GET_CLIENTS[REQUEST], handleGetClients);
    yield takeLatest(GET_CLIENT[REQUEST], handleGetClient);
    yield takeLatest(PUT_CLIENT[REQUEST], handlePutClient);
    yield takeLatest(POST_CLIENT[REQUEST], handlePostClient);
}