import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.request.use(requestInterceptor);
request.interceptors.response.use(null, errorInterceptor);

const api = {
  getNotifs: async (payload) => request.get(`/notifs`),
  readOne: async (payload) => request.put(`/notifs/${payload.id}`),
  readAll: async (payload) => request.put(`/notifs`),
  deleteAllNotifs: async () => request.delete(`/notifs`),
  // setProjectStatus: async (payload) => request.put(`/projects/${payload.id}/status`, {statusId: payload.statusId} ),
  // uploadFiles: (formData, onUploadProgress) => request.post("/projects/upload", formData, { headers: 
  //   { "Content-Type": "multipart/form-data"},
  //   onUploadProgress,
  // }),
};

export default apiWrapper(api);
