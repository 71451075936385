import { createRequestTypes, action } from '../../common/actions';

export const GET_TASKS = createRequestTypes('GET_TASKS');
export const GET_TASK = createRequestTypes('GET_TASK');
export const GET_TASKS_STATUSES = createRequestTypes('GET_TASKS_STATUSES');

export const SET_EXECUTOR = createRequestTypes('SET_EXECUTOR');
export const DEVIDE_FILE = createRequestTypes('DEVIDE_FILE');

export const UPLOAD_READY_FILE = createRequestTypes('UPLOAD_READY_FILE');
export const UPLOAD_ERROR_FILE = createRequestTypes('UPLOAD_ERROR_FILE');
export const UPLOAD_SUCCESS_FILE = createRequestTypes('UPLOAD_SUCCESS_FILE');

export const CHANGE_USER = createRequestTypes('CHANGE_USER');
export const CHANGE_PASS = createRequestTypes('CHANGE_PASS');

export const CLAER_TASK = createRequestTypes('CLAER_TASK');

const tasksActions = {
  getTasks: {
    request: (data) => action(GET_TASKS.REQUEST, { payload: data }),
    success: (data) => action(GET_TASKS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_TASKS.FAILURE, { payload: error }),
  },

  getTask: {
    request: (data) => action(GET_TASK.REQUEST, { payload: data }),
    success: (data) => action(GET_TASK.SUCCESS, { payload: data }),
    failure: (error) => action(GET_TASK.FAILURE, { payload: error }),
  },

  getTasksStatuses: {
    request: () => action(GET_TASKS_STATUSES.REQUEST),
    success: (data) => action(GET_TASKS_STATUSES.SUCCESS, { payload: data }),
    failure: (error) => action(GET_TASKS_STATUSES.FAILURE, { payload: error }),
  },

  setExecutor: {
    request: (data) => action(SET_EXECUTOR.REQUEST, { payload: data }),
    success: (data) => action(SET_EXECUTOR.SUCCESS, { payload: data }),
    failure: (error) => action(SET_EXECUTOR.FAILURE, { payload: error }),
  },

  devideFile: {
    request: (data, callback) => action(DEVIDE_FILE.REQUEST, { payload: data, callback }),
    success: (data) => action(DEVIDE_FILE.SUCCESS, { payload: data }),
    failure: (error) => action(DEVIDE_FILE.FAILURE, { payload: error }),
  },

  uloadReadyFile: {
    request: (data, callback) => action(UPLOAD_READY_FILE.REQUEST, { payload: data, callback }),
    success: (data) => action(UPLOAD_READY_FILE.SUCCESS, { payload: data }),
    failure: (error) => action(UPLOAD_READY_FILE.FAILURE, { payload: error }),
  },

  uploadErrorFile: {
    request: (data, callback) => action(UPLOAD_ERROR_FILE.REQUEST, { payload: data, callback }),
    success: (data) => action(UPLOAD_ERROR_FILE.SUCCESS, { payload: data }),
    failure: (error) => action(UPLOAD_ERROR_FILE.FAILURE, { payload: error }),
  },

  uploadSuccessFile: {
    request: (data, callback) => action(UPLOAD_SUCCESS_FILE.REQUEST, { payload: data, callback }),
    success: (data) => action(UPLOAD_SUCCESS_FILE.SUCCESS, { payload: data }),
    failure: (error) => action(UPLOAD_SUCCESS_FILE.FAILURE, { payload: error }),
  },

  changeUser: {
    request: (data) => action(CHANGE_USER.REQUEST, { payload: data }),
    success: (data) => action(CHANGE_USER.SUCCESS, { payload: data }),
    failure: (error) => action(CHANGE_USER.FAILURE, { payload: error }),
  },

  changePass: {
    request: (data) => action(CHANGE_PASS.REQUEST, { payload: data }),
    success: (data) => action(CHANGE_PASS.SUCCESS, { payload: data }),
    failure: (error) => action(CHANGE_PASS.FAILURE, { payload: error }),
  },

  clearTask: {
    request: (data) => action(CLAER_TASK.REQUEST, { payload: data }),
    success: (data) => action(CLAER_TASK.SUCCESS, { payload: data }),
    failure: (error) => action(CLAER_TASK.FAILURE, { payload: error }),
  },
};

export default tasksActions;
