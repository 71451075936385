/* global window */

// const host = window.location.origin;

// export const getUserId = () => window.localStorage[`${host}_uid`] ?? '';
// export const getUser = () => JSON.parse(window.localStorage?.[`${host}_user`] ?? '{}');

// export const setSessionCookies = (user) => {
//   window.localStorage[`${host}_user`] = JSON.stringify(user.user);
//   window.localStorage[`${host}_uid`] = user.user.id;
//   window.localStorage[`${host}_accessToken`] = user.accessToken;
// };

// export const unSetSessionCookies = () => {
//   window.localStorage.removeItem(`${host}_user`);
//   window.localStorage.removeItem(`${host}_uid`);
//   window.localStorage.removeItem(`${host}_tokens`);
// };

export const getAccessToken = () => {
  return window.localStorage[`accessToken`];
};

// export const setTokens = (tokens) => {
//   window.localStorage[`${host}_tokens`] = JSON.stringify(tokens);
// };

// export const getLastPath = (url) => {
//   const parts = url.split('/');
//   if (parts[parts.length - 1].length === 0) {
//     return parts[parts.length - 2];
//   }
//   return parts[parts.length - 1];
// };

// export const getQueryParam = (name, query) => {
//   const regx = new RegExp(`${name}=([^&]*)`);
//   const tokens = query.match(regx);
//   return tokens ? tokens[1] : undefined;
// };

export const clearLocalStorage = () => {
  window.localStorage.clear();
};
