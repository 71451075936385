import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import settingsActions, { GET_FILE_TYPES, PUT_FILE_TYPE, POST_FILE_TYPE, SIGNOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from 'modules/settings/data/actions';
import { REQUEST } from 'modules/common/actions';
import { signup, signin, signout, forgotPassword, changePassword, getNextAuthRouteAfterSignIn } from 'services/auth';
import { unSetSessionCookies, setSessionCookies } from 'modules/common/utils';

// import { routes } from 'modules/auth/constants';

// import usersActions from 'modules/user/data/actions';
// import providerActions from 'modules/provider/data/actions';
import settingsServices from 'services/settings';
// import { MEDICAL_RECORD_REQUEST_STATUS } from 'modules/dashboard/constants';

import { toast } from 'react-toastify'

export function* handleGetFileTypes({ payload }) {
    try {
        const data = yield call(settingsServices.getFileTypes, payload);
        const { location } = yield select(state => state.router);
        console.log(location.pathname)
        if(location.pathname !== '/settings/filetypes'){
            data.data = data.data.filter(({active}) => active === 1)
        }
        // yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
        yield put(settingsActions.getFileTypes.success(data));
    } catch (error) {
        yield put(settingsActions.getFileTypes.failure(error));
        yield call(toast.error, 'Ошибка получения типов файлов!')
    }
}

export function* handlePutFileType({ payload, callback }) {
    try {
        const data = yield call(settingsServices.putFileType, payload);
        yield put(settingsActions.putFileType.success(data));
        yield put(settingsActions.getFileTypes.request());
        yield call(callback)
        yield call(toast.success, 'Редактирования типа файла успешно!')
    } catch (error) {
        yield put(settingsActions.putFileType.failure(error));
        yield call(toast.error, 'Ошибка редактирования типа файла!')
    }
}

export function* handlePostFileTypes({ payload, callback }) {
    try {
        const data = yield call(settingsServices.postFileTypes, payload);
        yield put(settingsActions.postFileTypes.success(data));
        yield put(settingsActions.getFileTypes.request());
        yield call(callback)
        yield call(toast.success, 'Редактирования типа файла успешно!')
    } catch (error) {
        yield put(settingsActions.postFileTypes.failure(error));
        yield call(toast.error, 'Ошибка редактирования типа файла!')
    }
}


export default function* settingsWatcher() {
    yield takeLatest(GET_FILE_TYPES[REQUEST], handleGetFileTypes);
    yield takeLatest(PUT_FILE_TYPE[REQUEST], handlePutFileType);
    yield takeLatest(POST_FILE_TYPE[REQUEST], handlePostFileTypes);
    

    
    // yield takeLatest(GET_ROLES[REQUEST], handleGetRoles);
    // yield takeLatest(POST_USER[REQUEST], handlePostUser);
    // yield takeLatest(GET_USER[REQUEST], handleGetUser);
    // yield takeLatest(PUT_USER[REQUEST], handlePutUser);
    // yield takeLatest(BLOCK_USER[REQUEST], handleBlockUser);
}

// export function* handleSigninRequest(action) {
//   try {
//     const { data } = yield call(signin, action.payload);
//     // setSessionCookies({ user: data.user, accessToken: data.accessToken });
//     yield put(authActions.signin.success(data));

//     // const userData = yield call(userServices.getUser, '', true)
//     // yield put(usersActions.getUser.success(userData))

//     // if (userData.data.medicalRecordRequests.length > 0) {
//     //   yield put(providerActions.generateRecord.success({ data: userData.data.medicalRecordRequests }));
//     // }


//     // yield put(push(getNextAuthRouteAfterSignIn(userData?.data, userData.data.medicalRecordRequests, routes, MEDICAL_RECORD_REQUEST_STATUS)));
//   } catch (error) {
//     yield put(authActions.signin.failure(error));
//     yield call(ToasterService.notifyOneUpdaptebleToastRequestError, error, ToasterService.containers.AUTH_CONTAINER)
//   }
// }

// export function* handleSigninSubmit() {
//   yield takeLatest(SIGNIN.REQUEST, handleSigninRequest);
// }

// export function* handleSignout() {
//   while (true) {
//     try {
//       const { payload } = yield take(SIGNOUT[REQUEST]);
//       yield call(signout, payload);

//       unSetSessionCookies();
//       yield put(authActions.signout.success());
//       window.location.href = '/';
//     } catch (error) {
//       const { code, message } = error;
//       yield put(authActions.signout.success({ code, message }));
//     }
//   }
// }

// export function* handleForgotPassword() {
//   while (true) {
//     try {
//       const { payload } = yield take(FORGOT_PASSWORD[REQUEST]);
//       const data = yield call(forgotPassword, payload);
//       yield put(authActions.forgotPassword.success(data));
//     } catch (error) {
//       const { code, message } = error;
//       yield put(authActions.forgotPassword.failure({ code, message }));
//       // antMessage.error(message, 5);
//     }
//   }
// }

// export function* handleChangePassword() {
//   while (true) {
//     try {
//       const { payload } = yield take(CHANGE_PASSWORD[REQUEST]);
//       const data = yield call(changePassword, payload);
//       yield put(authActions.changePassword.success(data));
//       // setTimeout(() => (window.location.href = '/dashboard'), 1000);
//     } catch (error) {
//       const { code, message } = error;
//       yield put(authActions.changePassword.failure({ code, message }));
//     }
//   }
// }