import _ from "lodash";
import axios from "axios";
import { USERS_BASE_URL } from "constants/config";
import {
  getAccessToken,
  setTokens,
  unSetSessionCookies,
} from "modules/common/utils";

// TODO : TO BE DELETED

const getTokens = () => Promise.resolve();
export const requestInterceptor = async (config) => {
  try {
    config.headers.common.Authorization = `Bearer ${getAccessToken()}`;
    // config.headers.common.AccessToken = accessToken.jwtToken;
  } catch (e) {
    console.error(e);
  }

  return config;
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const errorInterceptor = (err) => {
  // const originalRequest = err.config;

  if (
    err?.response?.status === 401
  ) {
    // unSetSessionCookies();
    localStorage.clear();
    window.location.href = "/";
    // return Promise.reject(err);
  }

  // if (err.response.status === 401 && !originalRequest._retry) {
  //   if (isRefreshing) {
  //     return new Promise(function (resolve, reject) {
  //       failedQueue.push({ resolve, reject });
  //     })
  //       .then((token) => {
  //         originalRequest.headers["Authorization"] = "Bearer " + token;
  //         return axios(originalRequest);
  //       })
  //       .catch((err) => {
  //         return Promise.reject(err);
  //       });
  //   }

  //   originalRequest._retry = true;
  //   isRefreshing = true;

  //   const refreshToken = getTokens().refresh.token;
  //   return new Promise(function (resolve, reject) {
  //     axios
  //       .post(`${USERS_BASE_URL}/auth/refresh-tokens`, { refreshToken })
  //       .then(({ data }) => {
  //         setTokens(data);
  //         axios.defaults.headers.common["Authorization"] =
  //           "Bearer " + data.access.token;
  //         originalRequest.headers["Authorization"] =
  //           "Bearer " + data.access.token;
  //         processQueue(null, data.access.token);
  //         resolve(axios(originalRequest));
  //       })
  //       .catch((err) => {
  //         processQueue(err, null);
  //         unSetSessionCookies();
  //         window.location.href = "/login";
  //         reject(err);
  //       })
  //       .then(() => {
  //         isRefreshing = false;
  //       });
  //   });
  // }

  // return Promise.reject(err);
};

/**
 * Custom Api Wrapper to handle unhandled exceptions/error
 * @param {object} api - api object containing all api functions e.g. { login: (data) => {}, logout: () => {}}
 * @param {array} exclude - Array of function names in api which we don't want to add this error handling
 */
export const apiWrapper = (api, exclude = []) => {
  const newApi = {};
  _.keys(api).forEach((func) => {
    if (_.includes(exclude, func)) {
      newApi[func] = api[func];
      return;
    }
    newApi[func] = (...args) => {
      return new Promise((resolve, reject) => {
        api[func](...args)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              return reject({ message: error.response.data.message });
            }
            if (error && error.message) {
              return reject(error);
            }
            return reject({
              message: "Something went wrong, we are looking into that.",
              code: 500,
            });
          });
      });
    };
  });
  return newApi;
};
