import { Button, Icon } from 'shared/Atoms';

import styled from 'styled-components';

const StyledButton = styled(Button)`
  font-size: 1.38vh;
`;

const IconButton = ({ iconName, iconSize, children, ...props }) => (
  <StyledButton {...props}>
    <Icon name={iconName} fontSize={iconSize} />
    {children}
  </StyledButton>
);

export default IconButton;
