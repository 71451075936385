import { Link as RouterLink } from "react-router-dom";

import clsx from "clsx";
import styled from "styled-components";

import { Button } from "../../Atoms";
// import { BUTTON_TEXT_ONLY_COLOR } from "shared/constants";

const StyledButton = styled(Button)`
  padding: 0.8em 0.9em;
  // font-size: 1.07em;
`;

const ButtonLink = ({ children, makedStyles, ...props }) => (
  <StyledButton
    // textColor={BUTTON_TEXT_ONLY_COLOR}
    color="primary"
    component={RouterLink}
    className={clsx(makedStyles)}
    {...props}
  >
    {children}
  </StyledButton>
);

export default ButtonLink;
