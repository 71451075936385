import { createRequestTypes, action } from '../../common/actions';

export const GET_FILE_TYPES = createRequestTypes('GET_FILE_TYPES');

export const PUT_FILE_TYPE = createRequestTypes('PUT_FILE_TYPE');
export const POST_FILE_TYPE = createRequestTypes('POST_FILE_TYPE');



export const CONFIRM_SIGNIN = createRequestTypes('CONFIRM_SIGNIN');
export const SIGNOUT = createRequestTypes('SIGNOUT');

export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD');

export const CHANGE_PASSWORD = createRequestTypes('CHANGE_PASSWORD');

const settingsActions = {
  getFileTypes: {
    request: (data) => action(GET_FILE_TYPES.REQUEST, { payload: data }),
    success: (data) => action(GET_FILE_TYPES.SUCCESS, { payload: data }),
    failure: (error) => action(GET_FILE_TYPES.FAILURE, { payload: error }),
  },

  postFileTypes: {
    request: (data, callback = () => {}) => action(POST_FILE_TYPE.REQUEST, { payload: data, callback }),
    success: (data) => action(POST_FILE_TYPE.SUCCESS, { payload: data }),
    failure: (error) => action(POST_FILE_TYPE.FAILURE, { payload: error }),
  },

  putFileType: {
    request: (data, callback = () => {}) => action(PUT_FILE_TYPE.REQUEST, { payload: data, callback }),
    success: (data) => action(PUT_FILE_TYPE.SUCCESS, { payload: data }),
    failure: (error) => action(PUT_FILE_TYPE.FAILURE, { payload: error }),
  },

  // signin: {
  //   request: (data, history) => action(SIGNIN.REQUEST, { payload: data, history }),
  //   success: (data) => {
  //     return action(SIGNIN.SUCCESS, { payload: data });
  //   },
  //   failure: (error) => {
  //     return action(SIGNIN.FAILURE, { payload: error });
  //   },
  // },

  // signout: {
  //   request: (data) => {
  //     return action(SIGNOUT.REQUEST, { payload: data });
  //   },
  //   success: (data) => {
  //     return action(SIGNOUT.SUCCESS, { payload: data });
  //   },
  //   failure: (error) => {
  //     return action(SIGNOUT.FAILURE, { payload: error });
  //   },
  // },

  // forgotPassword: {
  //   request: (data) => action(FORGOT_PASSWORD.REQUEST, { payload: data }),
  //   success: (data) => {
  //     return action(FORGOT_PASSWORD.SUCCESS, { payload: data });
  //   },
  //   failure: (error) => {
  //     return action(FORGOT_PASSWORD.FAILURE, { payload: error });
  //   },
  // },

  // changePassword: {
  //   request: (data) => action(CHANGE_PASSWORD.REQUEST, { payload: data }),
  //   success: (data) => {
  //     return action(CHANGE_PASSWORD.SUCCESS, { payload: data });
  //   },
  //   failure: (error) => {
  //     return action(CHANGE_PASSWORD.FAILURE, { payload: error });
  //   },
  // },
};

export default settingsActions;
