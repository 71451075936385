import { Popover as MPopover } from '@material-ui/core';
import styled from 'styled-components';

const StyledMPopover = styled(MPopover)`
	.MuiPopover-paper {
		overflow-x: unset;
		overflow-y: unset;
	}
`;

const Popover = ({ handleClick, handleClose, anchorEl, children, ...props }) => {
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
			<StyledMPopover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				anchorPosition={{
					top: '150px',
					// left: '50px',
				}}
				className='mt-5'
				{...props }
			>
				{children}
			</StyledMPopover>
	);
}

export default Popover;
