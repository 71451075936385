import { useEffect, useState } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Typography, Grid, IconButton, TableSortLabel, List, ListItem as MListItem, ListItemIcon, Checkbox, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Icon } from 'shared/Atoms';
import { ButtonMainAccent, OutlinedInputField, NativeDatePicker, ListItem, Popover, ReactSelect, OutlinedSelectField, } from 'shared/Molecules';
import projectsActions from 'modules/projects/data/actions';
import clientsActions from 'modules/clients/data/actions';
import settingsActions from 'modules/settings/data/actions';
import { projectStatusColorMapper } from 'shared/constants';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }
  
  .MuiTableSortLabel-icon {
    opacity: 0.2 ;
    color: #8f9bb3 !important;
  }
`;

const Client = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setValue, reset } = useForm();
  const { location } = useSelector(state => state.router);
  const { client } = useSelector(state => state.clientsReducer);

  useEffect(() => {
    dispatch(settingsActions.getFileTypes.request())
    dispatch(projectsActions.getProjectsStatuses.request())
    return () => dispatch(clientsActions.getClient.success({ data: {} }))
  }, [])

  const { projects, projectsStatuses } = useSelector(state => state.projectsReducer);
  const { fileTypes } = useSelector(state => state.settingsReducer);
  const [prices, setPrice] = useState([]);
  const addToPrice = () => {
    if (watch('fileType') && watch('price')) {
      setPrice(prev => [...prev.filter(({ fileType }) => fileType.id !== watch('fileType')),
      { fileType: fileTypes.filter((value) => value.id === watch('fileType'))[0], price: watch('price') }])
    }
  }

  const removeFromPrice = (id) => {
    setPrice(prev => [...prev.filter(({ fileType }) => fileType.id !== id)])
  }

  const onSubmit = data => {
    if (prices.length) {
      dispatch(clientsActions.putClient.request({ ...data, priceList: prices, id: client.id }));
    } else {
      toast.error('Необходимо заполнить хотябы одну позицию из прайса!')
    }
  };

  useEffect(() => {
    if (Object.keys(client).length) {
      setPrice(client.clientPrices)
    }
  }, [client])

  const [filterStatuses, setFilterStatuses] = useState([1, 2, 3]);
  const headCells = [
    { id: 'id', label: '№', points: 1, sort: true, filter: false },
    { id: 'status', label: 'Статус', points: 2, sort: false, filter: true },
    { id: 'totalPages', label: 'Стр.', points: 1, sort: true, filter: false },
    { id: 'dateTimeCreated', label: 'Дата создания', points: 3, sort: true, filter: true },
    { id: 'dateTimeEnd', label: 'Срок сдачи', points: 3, sort: true, filter: true },
    { id: 'totalSum', label: 'Сумма заказа', points: 2, sort: true, filter: false },
  ];

  let page = 1, perPage = 1000;
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const createSortHandler = (property) => {
    if (orderBy === property) {
      setOrder(prev => prev === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(property);
      setOrder('desc')
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(undefined);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverId(undefined)
  };

  const showFilter = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id)
  }

  const handleToggleFilterStatuses = (value) => () => {
    const currentIndex = filterStatuses.indexOf(value);
    const newChecked = [...filterStatuses];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilterStatuses(newChecked);
    handleClosePopover()

  };

  const resetDatesEnd = () => {
    handleClosePopover()
    setValue('dateFromEnd', 0);
    setValue('dateToEnd', 0);
  }

  const resetDatesCreated = () => {
    handleClosePopover()
    setValue('dateFromCreated', 0);
    setValue('dateToCreated', 0);
  }

  useEffect(() => {
    dispatch(clientsActions.getClient.request({
      id: location.pathname.split('/')[2],
      page, perPage, order, orderBy,
      filterStatuses: JSON.stringify(filterStatuses),
      dateFromEnd: watch('dateFromEnd') || 0,
      dateToEnd: watch('dateToEnd') || 0,
      dateFromCreated: watch('dateFromCreated') || 0,
      dateToCreated: watch('dateToCreated') || 0,
    }))
  }, [
    page,
    perPage,
    order,
    orderBy,
    filterStatuses,
    watch('dateFromEnd'),
    watch('dateToEnd'),
    watch('dateFromCreated'),
    watch('dateToCreated')
  ])



  if (!Object.keys(client).length) {
    return null;
  }

  return (
    <ContentWrapperBlock>
      <Popover
        handleClick={handleOpenPopover}
        handleClose={handleClosePopover} anchorEl={anchorEl} id={popoverId}
      >
        {popoverId === 'status' &&
          <List dense={true}>
            {projectsStatuses?.map(({ id, name }) => {
              const labelId = `checkbox-list-label-${id}`;

              return (
                <MListItem key={id} button onClick={handleToggleFilterStatuses(id)} className='py-0'>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={filterStatuses.indexOf(id) !== -1}
                      tabIndex={-1}
                      color="primary"
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={name} />
                </MListItem>
              );
            })}
          </List>}
        {popoverId === 'dateTimeCreated' &&
          <div className='p-10' style={{ width: '250px' }}>
            <NativeDatePicker
              fullWidth={true}
              label="От"
              placeholder="От"
              name="dateFromCreated"
              control={control}
              className='mb-15'
            />
            <NativeDatePicker
              fullWidth={true}
              label="До"
              placeholder="До"
              name="dateToCreated"
              control={control}
              className='mb-15'
            />
            <ButtonMainAccent fullWidth={true} onClick={resetDatesCreated}>Сбросить</ButtonMainAccent>
          </div>
        }
        {popoverId === 'dateTimeEnd' &&
          <div className='p-10' style={{ width: '250px' }}>
            <NativeDatePicker
              fullWidth={true}
              label="От"
              placeholder="От"
              name="dateFromEnd"
              control={control}
              className='mb-15'
            />
            <NativeDatePicker
              fullWidth={true}
              label="До"
              placeholder="До"
              name="dateToEnd"
              control={control}
              className='mb-15'
            />
            <ButtonMainAccent fullWidth={true} onClick={resetDatesEnd}>Сбросить</ButtonMainAccent>
          </div>
        }
      </Popover>
      <Typography className=" mb-30" variant="h1">Клиенты</Typography>
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)} className="w-100">
        <Grid container spacing={2} xs={12}>
          <Grid item xs={6}>
            <OutlinedInputField
              makedStyles='mb-15'
              type="text"
              label="Имя"
              placeholder="Имя"
              name="name"
              control={control}
              defaultValue={client.name}
              rules={{
                required: 'Поле обязательно к заполнению',
              }}
            />
            <Grid container spacing={2} >
              <Grid item xs={12}>
                <Typography className=" mb-30 semi-bold" variant="subtitle"> Прайс - лист (Увидит продажник) </Typography>
              </Grid>
              <Grid item xs={6}>
                <ReactSelect
                  makedStyles='mb-15'
                  label="Тип файла"
                  placeholder="Тип файла"
                  name="fileType"
                  control={control}
                  options={
                    fileTypes.map(({ id, name }) => { return { value: id, label: name } })
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <OutlinedInputField
                  makedStyles='mb-15'
                  type="text"
                  label="Цена"
                  placeholder="Цена"
                  name="price"
                  control={control}
                />
              </Grid>
              <Grid item xs={2} className="center-content pb-15">
                <IconButton onClick={addToPrice}>
                  <Icon name="add" fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>

            <OutlinedInputField
              // makedStyles='mb-15'
              type="text"
              label="Основные требования (Увидят верстальщики и контролёры) "
              placeholder="Основные требования"
              name="requirements"
              control={control}
              multiline
              minRows={5}
              defaultValue={client.requirements || ''}
            />
          </Grid>
          <Grid item xs={6} className="mt-20">
            {prices?.map(({ fileType, price }, i) => {
              console.log(prices)
              return (
                <ListItem className="d-flex  align-items-center justify-content-space-between px-15" key={i}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} className="d-flex  align-items-center justify-content-space-between">{fileType.name}</Grid>
                    <Grid item xs={2} className="d-flex  align-items-center justify-content-space-between">{price}</Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeFromPrice(fileType.id)}>
                        <Icon name="delete" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </Grid>

          <Grid item xs={12}>
            <ButtonMainAccent className="mt-10" fullWidth={true} type="submit" variant="contained">Сохранить изменения</ButtonMainAccent>
          </Grid>
        </Grid>
      </FormControl>
      <Grid container spacing={2} className='mt-20'>
        <Grid item xs={12} component="ul">
          <Grid container spacing={2} className="p-10 mb-5" >
            {headCells.map(({ id, label, points, sort, filter }) => {
              return (
                <Grid item xs={points} key={id} className='d-flex'>
                  {sort ?
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'desc'}
                      onClick={() => createSortHandler(id)}
                    >
                      {label}
                    </TableSortLabel>
                    :
                    label
                  }
                  {filter &&
                    <Icon
                      aria-describedby={id}
                      name='settings'
                      className={`text-gray ${!sort ? 'ml-5' : ''}`}
                      onClick={(event) => showFilter(event, id)}
                    />}
                </Grid>
              )
            })}

          </Grid>
          {client?.projects?.totalItems === 0 && <ListItem ><div className='text-center p-15'>Проекты не найдены</div></ListItem>}
          {client?.projects?.items?.map(({ id, clientName, dateTimeCreated, totalSum, totalPages, numberInClient, statusId, statusName, userNameCreate, dateTimeEnd }) => {
            return (
              <ListItem>
                <Link className="link" to={`/projects/${id}`}>
                  <Grid container spacing={2}>
                    <Grid item xs={1} className="text-bold">{id}</Grid>
                    <Grid item xs={2} className='d-flex align-items-center'>
                      <div className='mr-5 ' style={{ borderRadius: '50%', width: '10px', height: '10px', backgroundColor: projectStatusColorMapper[statusId] || 'black' }}></div>
                      {statusName}
                    </Grid>
                    <Grid item xs={1}>{totalPages}</Grid>
                    <Grid item xs={3}>{dateTimeCreated}</Grid>
                    <Grid item xs={3}>{dateTimeEnd}</Grid>
                    <Grid item xs={2}>{totalSum}</Grid>
                  </Grid>
                </Link>
              </ListItem>
            )
          })}
          <Grid container spacing={2} className='px-15'>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>Итого:</Grid>
            <Grid item xs={1}>{client?.projects?.items?.reduce((totalPages, project) => totalPages + project.totalPages, 0)}</Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2}>{client?.projects?.items?.reduce((totalSum, project) => totalSum + project.totalSum, 0)}</Grid>
          </Grid>
        </Grid>
      </Grid>

    </ContentWrapperBlock>
  );
}

export default Client
