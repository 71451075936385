import { createRequestTypes, action } from '../../common/actions';

export const POST_STUDY_MATERIAL = createRequestTypes('POST_STUDY_MATERIAL');

export const GET_STUDY_MATERIALS = createRequestTypes('GET_STUDY_MATERIALS');
export const GET_STUDY_MATERIAL = createRequestTypes('GET_STUDY_MATERIAL');

export const DELETE_STUDY_MATERIAL = createRequestTypes('DELETE_STUDY_MATERIAL');

export const PUT_STUDY_MATERIAL = createRequestTypes('PUT_STUDY_MATERIAL');


const studyActions = {
  getStudyMaterials: {
    request: (data) => action(GET_STUDY_MATERIALS.REQUEST, { payload: data }),
    success: (data) => action(GET_STUDY_MATERIALS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_STUDY_MATERIALS.FAILURE, { payload: error }),
  },

  getStudyMaterial: {
    request: (data) => action(GET_STUDY_MATERIAL.REQUEST, { payload: data }),
    success: (data) => action(GET_STUDY_MATERIAL.SUCCESS, { payload: data }),
    failure: (error) => action(GET_STUDY_MATERIAL.FAILURE, { payload: error }),
  },

  deleteStudyMaterial: {
    request: (data) => action(DELETE_STUDY_MATERIAL.REQUEST, { payload: data }),
    success: (data) => action(DELETE_STUDY_MATERIAL.SUCCESS, { payload: data }),
    failure: (error) => action(DELETE_STUDY_MATERIAL.FAILURE, { payload: error }),
  },

  putStudyMaterial: {
    request: (data) => action(PUT_STUDY_MATERIAL.REQUEST, { payload: data }),
    success: (data) => action(PUT_STUDY_MATERIAL.SUCCESS, { payload: data }),
    failure: (error) => action(PUT_STUDY_MATERIAL.FAILURE, { payload: error }),
  },

  postStudyMaterial: {
    request: (data, callback) => action(POST_STUDY_MATERIAL.REQUEST, { payload: data, callback }),
    success: (data) => action(POST_STUDY_MATERIAL.SUCCESS, { payload: data }),
    failure: (error) => action(POST_STUDY_MATERIAL.FAILURE, { payload: error }),
  },
};

export default studyActions;
