import { useState, useEffect } from "react"
import styled from "styled-components";
import { Icon } from 'shared/Atoms'
import { ButtonMainAccent, OutlinedInputField, ReactSelect } from "shared/Molecules";
import { IconButton, Grid, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

const StyledLi = styled.li`
        list-style-type: none;
        border: 1px dashed black;
        border-radius: 8px;

        
`;

const useStyles = makeStyles({
	outerPositioning: {
		marginBottom: '2em',
	},
	headerButtonLinkOuterPositioning: {
		marginLeft: '1em',
	},
	formControl: {
		display: 'block',
		width: '400px',
	},
});

const FileItem = ({ fileId, children, control, fileTypes, watch, removeFile, disabled, setValue, touchedFields, prices, ...props }) => {
	const [open, setOpen] = useState(false);

	const [countPagesTypes, setCountPagesTypes] = useState([0]);

	useEffect(() => {
		setValue('countPagesTypes' + fileId, countPagesTypes.length)
	}, [countPagesTypes])

	const classes = useStyles();
	
	// console.log(prices)
	// useEffect(() => {
	// 	if (prices?.filter(({ fileTypeId }) => fileTypeId === watch('fileType' + fileId)).length !== 0) {
	// 		setValue('price' + fileId, prices?.filter(({ fileTypeId }) => fileTypeId === watch('fileType' + fileId))[0].price)
	// 	} else {
	// 		setValue('price' + fileId, null)
	// 	}
	// }, [watch('fileType' + fileId), prices])

	// for (let i = 0; i < countPagesTypes.length; i++) {
	// 	// console.log(watch('fileType' + fileId + '_' + i))

	// }
	const returnWatchedArr = (type) => {
		return countPagesTypes.map((value, index) => +watch(type + fileId + '_' + index))
	}
	const [watchedArr, setWatchedArr] = useState(returnWatchedArr('fileType'));
	// const [watchedArr, setWatchedArr] = useState(returnWatchedArr('fileType'));

	const [totalPages, setTotalPages] = useState(0);
	const [totalSum, setTotalSum] = useState(0);

	useEffect(() => {
		// console.log(watchedArr, returnWatchedArr('fileType'), watchedArr == returnWatchedArr('fileType'))
		let newa = returnWatchedArr('fileType');
		let allowRoles = newa?.filter(value => {
			// console.log(forbiddenFor.includes(value))
			return !watchedArr.includes(value)
		})
		if (allowRoles.length > 0) {
			setWatchedArr(newa)
		}

		//set total pages
		let totalPages = 0;
		let totalSum = 0;
		for (let i = 0; i < countPagesTypes.length; i++) {
			totalPages += +watch('pages' + fileId + '_' + i);
			if (watch('pages' + fileId + '_' + i) && watch('price' + fileId + '_' + i)) {
				totalSum += +watch('pages' + fileId + '_' + i) * +watch('price' + fileId + '_' + i)
			}
		}
		totalSum += +watch('extraPrice' + fileId);
		// console.log(totalPages)
		setTotalPages(prev => prev === totalPages ? prev : totalPages);
		setTotalSum(prev => prev === totalSum ? prev : totalSum);
	})

	useEffect(() => {
		for (let i = 0; i < countPagesTypes.length; i++) {
			// let i = countPagesTypes.length - 1;
			console.log(watch('price' + fileId + '_' + i))
			console.log(touchedFields['price' + fileId + '_' + i])
			if (prices?.filter(({ fileTypeId }) => fileTypeId === watch('fileType' + fileId + '_' + i)).length !== 0
				// && !!!watch('price' + fileId + '_' + i)
&& !!!touchedFields['price' + fileId + '_' + i]
			) {
				setValue('price' + fileId + '_' + i, prices?.filter(({ fileTypeId }) => fileTypeId === watch('fileType' + fileId + '_' + i))[0].price)
			} else {
				// setValue('price' + fileId + '_' + i, null)
			}
		}
	}, [watchedArr, prices])


	// useEffect(() => {
	// 		let totalPages = 0;
	// 		let totalSum = 0;

	// 		// for (let i = 0; i < countPagesTypes.length; i++) {
	// 		// 	totalPages += +watch('pages' + fileId + '_' + i);
	// 		// 	if (watch('pages' + fileId + '_' + i) && watch('price' + fileId + '_' + i)) {
	// 		// 		totalSum += +watch('pages' + fileId + '_' + i) * +watch('price' + fileId + '_' + i) + +watch('extraPrice' + fileId);
	// 		// 	}
	// 		// }
	// console.log(totalPages)
	// 		// setTotalPages(totalPages);
	// 		// setTotalSum(totalSum);
	// 	})

	const getAttachButtonDisablet = () => {
		if (disabled) return true;
		let status = false;
		for (let i = 0; i < countPagesTypes.length; i++) {
			if (!!!+watch('fileType' + fileId + '_' + i) || !!!+watch('pages' + fileId + '_' + i) || !!!+watch('price' + fileId + '_' + i)) {
				status = true
			}
		}
		return status
	}

	// console.log(watch("countPagesTypes" + fileId))
	// console.log(!!!+watch('fileType' + fileId), !!!+watch('pages' + fileId), !!!+watch('price' + fileId))
	return (
		<StyledLi className="p-5 mb-15">
			<Grid container spacing={2} className="w-100 m-0">
				<Grid item xs={12} className="d-flex  align-items-center justify-content-space-between">
					<div className="d-flex align-items-center">
						{getAttachButtonDisablet() ?
							<span title='Незаполнены все обязательные поля'>
								<Icon name='errorOutline' fontSize="large" className="mr-15 text-danger" />
							</span> :
							<Icon name="file" fontSize="large" className="mr-15 text-success" />
						}
						<div className="">
							<span className="semi-bold">{children}</span>
							{countPagesTypes.map((value, index) => {
								return (
									<div className="">
										<span className="mr-20">
											{watch('fileType' + fileId + '_' + index) ? fileTypes.filter(({ id }) => id === watch('fileType' + fileId  + '_' + index))[0].name : ''}
										</span>
										{watch('pages' + fileId  + '_' + index) ? watch('pages' + fileId  + '_' + index) + ' стр' : ''}
									</div>
								)
							})}

						</div>
					</div>
					<div className="" style={open ? { display: 'none' } : {}}>
						<IconButton disabled={disabled} onClick={() => setOpen(true)}><Icon name="edit" fontSize="medium" /></IconButton>
						<IconButton disabled={disabled} onClick={() => removeFile(fileId)}><Icon name="delete" fontSize="medium" /></IconButton>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={2} className="w-100 m-0" style={open ? {} : { display: 'none' }}>
				<Grid item xs={12} className='pt-0'><Divider variant="fullWidth" /></Grid>
				<OutlinedInputField
					required
					type="number"
					placeholder="Стоимость одной страницы (руб.)"
					name={"countPagesTypes" + fileId}
					control={control}
					rules={{ required: 'Поле обязательно к заполнению' }}
					style={{ display: 'none' }}
					defaultValue={1}
				/>
				{countPagesTypes.fill(0).map((value, index) => {
					return (
						<>
							<Grid item xs={4}>
								<ReactSelect
									makedStyles={classes.outerPositioning}
									required
									label='Тип файла'
									placeholder="Тип файла"
									control={control}
									name={"fileType" + fileId + '_' + index}
									rules={{ required: 'Поле обязательно к заполнению', }}
									options={fileTypes.filter(({ id }) => {
										let count = 0;

										for (let i = 0; i < countPagesTypes.length; i++) {
											if (+watch('fileType' + fileId + '_' + i) === id) {
												count++;
											}
										}
										return count === 0

									}).map(({ id, name }) => { return { value: id, label: name } })}
								/>
							</Grid>
							<Grid item xs={4}>
								<OutlinedInputField
									required
									makedStyles={classes.outerPositioning}
									type="number"
									label="Объем в страницах (кол-во)"
									placeholder="Объем в страницах (кол-во)"
									name={"pages" + fileId + '_' + index}
									inputProps={{ min: 0 }}
									control={control}
									rules={{ required: 'Поле обязательно к заполнению', }}
								/>
							</Grid>
							<Grid item xs={4}>
								<OutlinedInputField
									required
									makedStyles={classes.outerPositioning}
									type="number"
									label="Стоимость одной страницы (руб.)"
									placeholder="Стоимость одной страницы (руб.)"
									name={"price" + fileId + '_' + index}
									control={control}
									rules={{ required: 'Поле обязательно к заполнению' }}
								/>
							</Grid>
						</>
					)
				})}

				<Grid item xs={6} >
					<ButtonMainAccent
						onClick={() => setCountPagesTypes(prev => Array(prev.length + 1).fill(0))}
						fullWidth={true} variant="contained" className="text-white">
						Добавить еще один тип
					</ButtonMainAccent>
				</Grid>
				<Grid item xs={6} >
					<ButtonMainAccent
						disabled={countPagesTypes < 2}
						onClick={() => {
							setValue("fileType" + fileId + '_' + (countPagesTypes.length - 1), null)
							setCountPagesTypes(prev => prev.length > 1 ? [...prev.slice(0, prev.length - 1)] : prev)
						}}
						fullWidth={true} variant="contained" className={`text-white ${countPagesTypes < 2 ? 'bg-gray' : 'bg-danger'}`}>
						Удалить последний тип
					</ButtonMainAccent>
				</Grid>


				{/* <Grid item xs={6}>
					<ReactSelect
						makedStyles={classes.outerPositioning}
						required
						label='Тип файла'
						placeholder="Тип файла"
						control={control}
						name={"fileType" + fileId}
						rules={{ required: 'Поле обязательно к заполнению', }}
						options={fileTypes.map(({ id, name }) => { return { value: id, label: name } })}
					/>
				</Grid> */}
					<Grid item xs={12} className='pt-10'><Divider variant="fullWidth" /></Grid>
				<Grid item xs={6}>
					<OutlinedInputField
						makedStyles={classes.outerPositioning}
						type="text"
						label="Комментарий"
						placeholder="Комментарий"
						name={"comment" + fileId}
						control={control}
						multiline
						minRows={5}
						defaultValue={''}
					/>
				</Grid>
				{/* <Grid item xs={12}>
					<Typography className="semi-bold" > Стоимость работы</Typography>
					<Typography className="" > Прайс лист:</Typography>
				</Grid>
				<Grid item xs={6}>
					<OutlinedInputField
						required
						makedStyles={classes.outerPositioning}
						type="number"
						label="Объем в страницах (кол-во)"
						placeholder="Объем в страницах (кол-во)"
						name={"pages" + fileId}
						inputProps={{ min: 0 }}
						control={control}
						rules={{ required: 'Поле обязательно к заполнению', }}
					/>
					<OutlinedInputField
						required
						makedStyles={classes.outerPositioning}
						type="number"
						label="Стоимость одной страницы (руб.)"
						placeholder="Стоимость одной страницы (руб.)"
						name={"price" + fileId}
						control={control}
						rules={{ required: 'Поле обязательно к заполнению' }}
					/>
				</Grid> */}

				<Grid item xs={6}>
					<OutlinedInputField
						makedStyles={classes.outerPositioning}
						type="number"
						label="Дополнительная оплата (руб.)"
						placeholder="Дополнительная оплата (руб.)"
						name={"extraPrice" + fileId}
						control={control}
						defaultValue={0}
					/>
					<Typography className=" semi-bold">
						Итого страниц: {totalPages} шт.
					</Typography>
					<Typography className=" semi-bold">
						Итоговая сумма:  {totalSum} руб.
					</Typography>

				</Grid>

				<Grid item xs={12}><Divider variant="middle" /></Grid>

				<Grid item xs={6}>
					<ButtonMainAccent
						disabled={getAttachButtonDisablet()}
						onClick={() => setOpen(false)}
						fullWidth={true} variant="contained">Прикрепить файл</ButtonMainAccent>
				</Grid>
				<Grid item xs={6}>
					<ButtonMainAccent disabled={disabled} onClick={() => removeFile(fileId)} style={{ backgroundColor: 'red' }} fullWidth={true} variant="contained">Удалить файл</ButtonMainAccent>
				</Grid>

			</Grid>

		</StyledLi>
	)
}

export default FileItem
