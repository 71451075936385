import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import RouteWithLayout from '../shared/RouteWithLayout';
import { Minimal as MinimalLayout, Main as MainLayout } from '../shared/Components/Layout';

import { privateRoutes } from 'modules/common/constants';

import { SignIn } from 'modules/auth/view';
import { User, Users, NewUsers } from 'modules/users/view';
import { Projects, Project, NewProject } from 'modules/projects/view';
import { Tasks, Task } from 'modules/tasks/view';
import { Clients, Client, NewClient } from 'modules/clients/view';
import { Settings, Filetypes } from 'modules/settings/view';
import { Reports, ProjectsExcel } from 'modules/reports/view';
import { Notifications } from 'modules/notifications/view';
import { StudyMaterials, NewStudyMaterial, StudyMaterial } from 'modules/study/view';

const Routes = () => {

  return (
    <Switch>
      <Redirect exact from='/' to='/signin' />

      <RouteWithLayout
        component={SignIn}
        exact
        layout={MinimalLayout}
        path='/signin'
        title='Welcome to Patient Data Retrieval'
        subTitle='Access your medical records in just few clicks'
      />

      <RouteWithLayout
        component={Users}
        exact
        layout={MainLayout}
        path={privateRoutes.users.users.route}
        protectedRoute
      />
      <RouteWithLayout
        component={NewUsers}
        exact
        layout={MainLayout}
        path={privateRoutes.users.userNew.route}
        protectedRoute
      />
      <RouteWithLayout
        component={User}
        exact
        layout={MainLayout}
        path={privateRoutes.users.user.route}
        protectedRoute
      />



      <RouteWithLayout
        component={Projects}
        exact
        layout={MainLayout}
        path={privateRoutes.projects.projects.route}
        protectedRoute
      />
      <RouteWithLayout
        component={NewProject}
        exact
        layout={MainLayout}
        path={privateRoutes.projects.projectNew.route}
        protectedRoute
      />
      <RouteWithLayout
        component={Project}
        exact
        layout={MainLayout}
        path={privateRoutes.projects.project.route}
        protectedRoute
      />

      <RouteWithLayout
        component={Tasks}
        exact
        layout={MainLayout}
        path={privateRoutes.tasks.tasks.route}
        protectedRoute
      />
      <RouteWithLayout
        component={Task}
        exact
        layout={MainLayout}
        path={privateRoutes.tasks.task.route}
        protectedRoute
      />

      <RouteWithLayout
        component={Clients}
        exact
        layout={MainLayout}
        path={privateRoutes.clients.clients.route}
        protectedRoute
      />

      <RouteWithLayout
        component={NewClient}
        exact
        layout={MainLayout}
        path={privateRoutes.clients.clientNew.route}
        protectedRoute
      />
      <RouteWithLayout
        component={Client}
        exact
        layout={MainLayout}
        path={privateRoutes.clients.client.route}
        protectedRoute
      />

      <RouteWithLayout
        component={Filetypes}
        exact
        layout={MainLayout}
        path={privateRoutes.settings.filetypes.route}
        protectedRoute
      />
      <RouteWithLayout
        component={Settings}
        exact
        layout={MainLayout}
        path={privateRoutes.settings.settings.route}
        protectedRoute
      />

      <RouteWithLayout
        component={Notifications}
        exact
        layout={MainLayout}
        path='/notifications'
        protectedRoute
      />

      <RouteWithLayout
        component={Reports}
        exact
        layout={MainLayout}
        path={privateRoutes.reports.reports.route}
        protectedRoute
      />
      <RouteWithLayout
        component={ProjectsExcel}
        exact
        layout={MainLayout}
        path={privateRoutes.reports.projects.route}
        protectedRoute
      />
      {/* stydy=========================================== */}
      <RouteWithLayout
        component={NewStudyMaterial}
        exact
        layout={MainLayout}
        path={privateRoutes.study.newStudy.route}
        protectedRoute
      />
       <RouteWithLayout
        component={StudyMaterials}
        exact
        layout={MainLayout}
        path={privateRoutes.study.study.route}
        protectedRoute
      />
      <RouteWithLayout
        component={StudyMaterial}
        exact
        layout={MainLayout}
        path={privateRoutes.study.oneStudy.route}
        protectedRoute
      />
      
     

      <Redirect to='/not-found' />
    </Switch>
  );
};

export default Routes;
