import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMainAccent, OutlinedInputField, BorderLinearProgress, InputBaseFile, ListItem, ReactSelect } from "shared/Molecules";
import { FormControl, Grid } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import tasksActions from 'modules/tasks/data/actions';

const DevideFileItem = ({ indexNewFile,  fileTypes, control, setValue, watch, ...props }) => {
	const [count, setCount] = useState([0, 0]);
	const dispatch = useDispatch();
	// const { handleSubmit, control, setValue, watch } = useForm();
	const [progress, setProgress] = useState(0);
	const { devideFileLoading } = useSelector(state => state.tasksReducer);



	const [countPagesTypes, setCountPagesTypes] = useState([0]);

	useEffect(() => {
		setValue('countPagesTypes' + indexNewFile, countPagesTypes.length)
	}, [countPagesTypes])
	return (
	<>
			<Grid container spacing={2}>

					<Grid item xs={12}>
						<ListItem className='p-10'>
							<Grid container spacing={2}>
								<Grid item xs={1} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
									{indexNewFile + 1}
								</Grid>
								<Grid item xs={11} className='d-flex' alignItems='center'>
									<InputBaseFile
										disabled={devideFileLoading}
										control={control}
										name={"file" + indexNewFile}
										rules={{ required: 'Поле обязательно к заполнению' }}
									/>
								</Grid>

								<OutlinedInputField
									required
									type="number"
									placeholder="Стоимость одной страницы (руб.)"
									name={"countPagesTypes" + indexNewFile}
									control={control}
									rules={{ required: 'Поле обязательно к заполнению' }}
									style={{ display: 'none' }}
									defaultValue={1}
								/>
								{countPagesTypes.fill(0).map((value, index) => {
									return (
										<>
											<Grid item xs={6}>
												<ReactSelect
													makedStyles='mt-15'
													required
													label='Тип страниц'
													placeholder="Тип страниц"
													control={control}
													name={"fileType" + indexNewFile + '_' + index}
													rules={{ required: 'Поле обязательно к заполнению', }}
													options={fileTypes.filter(({ value }) => {
														let count = 0;
				
														for (let i = 0; i < countPagesTypes.length; i++) {
															if (+watch('fileType' + indexNewFile + '_' + i) === value) {
																count++;
															}
														}
														return count === 0
													})}
												/>
											</Grid>
											<Grid item xs={6}>
												<OutlinedInputField
													required
													makedStyles='mt-15'
													type="number"
													label="Объем в страницах (кол-во)"
													placeholder="Объем в страницах (кол-во)"
													name={"pages" + indexNewFile + '_' + index}
													inputProps={{ min: 0 }}
													control={control}
													rules={{ required: 'Поле обязательно к заполнению', }}
												/>
											</Grid>
											{/* <Grid item xs={4}>
												<OutlinedInputField
													required
													makedStyles='mt-15'
													type="number"
													label="Стоимость одной страницы (руб.)"
													placeholder="Стоимость одной страницы (руб.)"
													name={"price" + indexNewFile + '_' + index}
													control={control}
													rules={{ required: 'Поле обязательно к заполнению' }}
												/>
											</Grid> */}
										</>
									)
								})}

								<Grid item xs={6} >
									<ButtonMainAccent
										onClick={() => setCountPagesTypes(prev => Array(prev.length + 1).fill(0))}
										fullWidth={true} variant="contained" className="text-white">
										Добавить еще один тип
									</ButtonMainAccent>
								</Grid>
								<Grid item xs={6} >
									<ButtonMainAccent
										disabled={countPagesTypes < 2}
										onClick={() => {
											setValue("fileType" + indexNewFile + '_' + (countPagesTypes.length - 1), null)
											setCountPagesTypes(prev => prev.length > 1 ? [...prev.slice(0, prev.length - 1)] : prev)
										}}
										fullWidth={true} variant="contained" className={`text-white ${countPagesTypes < 2 ? 'bg-gray' : 'bg-danger'}`}>
										Удалить последний тип
									</ButtonMainAccent>
								</Grid>
							</Grid>
						</ListItem>
					</Grid>
				
					</Grid>
				</>
			)
}

			export default DevideFileItem
