import React from "react";
import { useController } from "react-hook-form";
import styled from "styled-components";
import moment from "moment";
import clsx from "clsx";

import { FormControl, IconButton, InputAdornment } from "@material-ui/core";
import { InputLabel, Icon, DatePicker, FormHelperText } from "shared/Atoms";

import { DATE_FORMAT, INPUT_DATE_FORMAT } from "shared/constants";

const StyledFormControl = styled(FormControl)`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')}; 

  .date-picker-field {
    padding: 0;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '200px')}; 
    padding-right: 0;

    input {
      height: 0.7em;
      font-size: 0.95em;
      &::placeholder {
        color: #8f9bb3;
        opacity: 1;
      }
    }

    fieldset {
      // border-color: #e4e9f2;
      background-color: ${({ isDirty }) => (isDirty ? 'transparent' : '#cccccc18')};
    }
  }

  .form-helper-text {
    color: red;
    font-size: 1em;
    margin: 5px 0 0 0;
  },
`;

const OutlinedDatePicker = (props) => {
  const {
    options,
    label,
    name,
    makedStyles,
    control,
    placeholder,
    format = INPUT_DATE_FORMAT,
    labelProps = {},
    fullWidth = false,
    defaultValue,
    ...rest
  } = props;

  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { isDirty },
    formState: { errors },
  } = useController({
    name,
    control,
    rules: props.rules,
    defaultValue: defaultValue ? moment(defaultValue).format(format) : null
  });

  return (
    <StyledFormControl className={clsx(makedStyles)} isDirty={isDirty} fullWidth={fullWidth}>
      {label && (
        <InputLabel shrink htmlFor={name} {...labelProps}>
          {label}
        </InputLabel>
      )}
        <DatePicker
          format={format}
          {...inputProps}
          {...rest}
          onChange={(value) => onChange(moment(value).format(DATE_FORMAT))}
          InputProps={{
            placeholder: placeholder || label || 'Date',
            className: "date-picker-field",
            endAdornment: (
              <InputAdornment>
                <IconButton>
                  <Icon name="calendar" />
                </IconButton>
              </InputAdornment>
            )
          }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        />
      {errors?.[name] && (
        <FormHelperText className="form-helper-text" id={name}>
          {errors?.[name]?.message}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default OutlinedDatePicker;