import { useEffect, useState } from 'react';
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid, TextField, IconButton, Autocomplete } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clientsActions from 'modules/clients/data/actions';
import studyActions from 'modules/study/data/actions';
import settingsActions from 'modules/settings/data/actions';

import { Icon } from 'shared/Atoms';
import {
  ButtonLink, ButtonMainAccent, OutlinedInputField, ReactSelect, Footer, CheckBoxItem, ListItem, Loader, StrokeLabel, OutlinedSelectField, NativeDatePicker,
  AutocompleteSelectField, InputBaseFile, BorderLinearProgress
} from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import UploadImages from './upload-images.component'
// import UploadService from "./upload-files.service";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Button, withStyles } from '@material-ui/core';
// import FileItem from './FileItem'
import projectsService from 'services/projects';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }
`;

const StudyMaterial = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setValue, reset } = useForm();

  const { loading, studyMaterial } = useSelector(state => state.studyReducer);

  const [progress, setProgress] = useState(0);
  const { location } = useSelector(state => state.router);
 

  useEffect(() => {
    dispatch(studyActions.getStudyMaterial.request({ id: location.pathname.split('/')[2] }))
    return () => dispatch(studyActions.getStudyMaterial.success({data: {}}))
  }, [])

  const onSubmit = (data) => {
    setProgress(0);
    data.id = location.pathname.split('/')[2];
    dispatch(studyActions.putStudyMaterial.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))
  }
  if (!Object.keys(studyMaterial).length) {
    return null;
  }
  const options = [{ value: 'cloud', label: 'Ссылка на облако' }, { value: 'download', label: 'Загрузка на наш сервер' }];
  if(!watch('type')){
    setValue('type', studyMaterial?.type)
  }
  
  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Редактирование обучающего материала</Typography>
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)} className="w-100">
        <Grid container spacing={2} xs={12}>
          <Grid item xs={6}>
            <ReactSelect
              isDisabled={loading}
              makedStyles='mt-15'
              required
              label='Тип'
              placeholder="Тип"
              control={control}
              name='type'
              defaultValue={options.filter(({ value}) => value === studyMaterial?.type) }
              rules={{ required: 'Поле обязательно к заполнению', }}
              options={options}
            />
            <OutlinedInputField
              required
              disabled={loading}
              makedStyles='mt-15'
              type="text"
              label="Наименование материала"
              placeholder="Наименование материала"
              name="name"
              control={control}
              defaultValue={studyMaterial?.name}
              rules={{ required: 'Поле обязательно к заполнению', }}
            />
            {watch('type') === 'cloud' &&
              <OutlinedInputField
                required
                disabled={loading}
                makedStyles='mt-15'
                type="url"
                label="Ссылка"
                placeholder="Ссылка"
                name="link"
                defaultValue={studyMaterial?.link}
                control={control}
                rules={{ required: 'Поле обязательно к заполнению', }}
              />}
            {watch('type') === 'download' &&
              <InputBaseFile
              style={{marginTop: '15px'}}
                className='mt-15'
                required
                disabled={loading}
                control={control}
                name={"file"}
                // rules={{ required: 'Поле обязательно к заполнению' }}
              />
            }
          </Grid>
          <Grid item xs={12}>{loading && <BorderLinearProgress className="mt-15" variant="determinate" value={progress} />}</Grid>
          <Grid item xs={6}>
            <ButtonMainAccent disabled={loading} loading={loading} className="mt-10" fullWidth={true} type="submit" variant="contained">
              Сохранить
            </ButtonMainAccent>
          </Grid>
          <Grid item xs={6}>
            <ButtonMainAccent onClick={() => dispatch(studyActions.deleteStudyMaterial.request({ id: location.pathname.split('/')[2] }))} disabled={loading} loading={loading} className="mt-10 bg-danger" fullWidth={true} variant="contained">
              Удалить
            </ButtonMainAccent>
          </Grid>
        </Grid>
      </FormControl>
    </ContentWrapperBlock >
  );
}

export default StudyMaterial
