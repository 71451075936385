import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import authActions,
{
    GET_USERS,
    GET_USER,
    POST_USER,
    PUT_USER,
    GET_ROLES,
    BLOCK_USER,
    GET_SALESMANS,
    GET_EXECUTORS,
    GET_CONTROLLERS,
    DELETE_USER,
} from 'modules/users/data/actions';
import { REQUEST } from 'modules/common/actions';
import { signup, signin, signout, forgotPassword, changePassword, getNextAuthRouteAfterSignIn } from 'services/auth';
import { unSetSessionCookies, setSessionCookies } from 'modules/common/utils';

// import { routes } from 'modules/auth/constants';

import usersActions from 'modules/users/data/actions';
// import providerActions from 'modules/provider/data/actions';
import usersServices from 'services/users';
// import { MEDICAL_RECORD_REQUEST_STATUS } from 'modules/dashboard/constants';

import { toast } from 'react-toastify'

export function* handleGetUsers({ payload }) {
    try {
        const { data } = yield call(usersServices.getUsers, payload);
        console.log(data);
        yield put(usersActions.getUsers.success({ data }));
    } catch (error) {
        console.log(error);
        yield call(toast.error, 'Ошибка получения пользователей!')
        yield put(usersActions.getUsers.failure(error));
    }
}

export function* handleGetUser({ payload }) {
    try {
        const { data } = yield call(usersServices.getUser, payload);
        console.log(data);
        yield put(usersActions.getUser.success({ data }));
    } catch (error) {
        console.log(error);
        yield call(toast.error, 'Ошибка получения пользователей!')
        yield put(usersActions.getUsers.failure(error));
    }
}

export function* handleGetRoles() {
    try {
        const { data } = yield call(usersServices.getRoles);
        console.log(data);
        yield put(usersActions.getRoles.success({ data }));
    } catch (error) {
        console.log(error);
        yield call(toast.error, 'Ошибка получения ролей пользователей!')
        yield put(usersActions.getRoles.failure(error));
    }
}

export function* handlePostUser({ payload }) {
    try {
        const { data } = yield call(usersServices.postUser, payload);
        console.log(data);
        yield call(toast.success, 'Пользователь успешно создан!')
        yield put(push('/users'))
    } catch (error) {
        if (error?.response?.status === 409) {
            yield call(toast.error, 'Пользователь с такой почтой существует!')
        } else {
            yield call(toast.error, 'Ошибка создания пользователя!')
        }
        yield put(usersActions.postUser.failure(error));
    }
}

export function* handlePutUser({ payload }) {
    try {
        const { data } = yield call(usersServices.putUser, payload);
        console.log(data);
        yield call(toast.success, 'Пользователь успешно изменен!')
        yield put(usersActions.putUser.success());
        yield put(push('/users'))
    } catch (error) {
        yield call(toast.error, 'Ошибка изменения пользователя!')
        yield put(usersActions.putUser.failure(error));
    }
}


export function* handleBlockUser({ payload }) {
    try {
        const { data } = yield call(usersServices.blockUser, payload);
        console.log(data);
        yield call(toast.success, 'Пользователь успешно изменен!')
        yield put(usersActions.blockUser.success());
        yield put(push('/users'))
    } catch (error) {
        yield call(toast.error, 'Ошибка изменения пользователя!')
        yield put(usersActions.blockUser.failure(error));
    }
}

export function* handleDeleteUser({ payload }) {
    try {
        const { data } = yield call(usersServices.deleteUser, payload);
        console.log(data);
        yield call(toast.success, 'Пользователь успешно удален!')
        yield put(usersActions.deleteUser.success());
        yield put(push('/users'))
    } catch (error) {
        yield call(toast.error, 'Ошибка удален пользователя!')
        yield put(usersActions.deleteUser.failure(error));
    }
}

export function* handleGetSalesmans() {
    try {
        const { data } = yield call(usersServices.getSalesmans);
        yield put(usersActions.getSalesmans.success({ data }));
    } catch (error) {
        yield call(toast.error, 'Ошибка получения продажников!')
        yield put(usersActions.getSalesmans.failure(error));
    }
}

export function* handleGetExecutors() {
    try {
        const { data } = yield call(usersServices.getExecutors);
        yield put(usersActions.getExecutors.success({ data }));
    } catch (error) {
        yield call(toast.error, 'Ошибка получения исполнителей!')
        yield put(usersActions.getExecutors.failure(error));
    }
}

export function* handleGetControllers() {
    try {
        const { data } = yield call(usersServices.getControllers);
        yield put(usersActions.getControllers.success({ data }));
    } catch (error) {
        yield call(toast.error, 'Ошибка получения контроллеров!')
        yield put(usersActions.getControllers.failure(error));
    }
}


export default function* usersWatcher() {
    yield takeLatest(GET_USERS[REQUEST], handleGetUsers);
    yield takeLatest(GET_ROLES[REQUEST], handleGetRoles);
    yield takeLatest(POST_USER[REQUEST], handlePostUser);
    yield takeLatest(GET_USER[REQUEST], handleGetUser);
    yield takeLatest(PUT_USER[REQUEST], handlePutUser);
    yield takeLatest(BLOCK_USER[REQUEST], handleBlockUser);
    yield takeLatest(DELETE_USER[REQUEST], handleDeleteUser);
    yield takeLatest(GET_SALESMANS[REQUEST], handleGetSalesmans);
    yield takeLatest(GET_EXECUTORS[REQUEST], handleGetExecutors);
    yield takeLatest(GET_CONTROLLERS[REQUEST], handleGetControllers);
}