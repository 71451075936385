import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import studysActions, {
    POST_STUDY_MATERIAL,
    GET_STUDY_MATERIALS,
    GET_STUDY_MATERIAL,
    DELETE_STUDY_MATERIAL,
    PUT_STUDY_MATERIAL,
} from 'modules/study/data/actions';
import { REQUEST } from 'modules/common/actions';

import studyServices from 'services/study';
import { toast } from 'react-toastify'

export function* handlePostStudyMaterial({ payload, callback }) {
    try {
        let formData = new FormData();

        formData.append("file", payload["file"] || 0);
        formData.append("link", payload["link"] || 0);
        formData.append("type", payload["type"]);
        formData.append("name", payload["name"]);

        console.log(formData)


        console.log(formData)

        const { data } = yield call(studyServices.postStudyMaterial, formData, callback);
        yield put(studysActions.postStudyMaterial.success(data));
        yield call(toast.success, 'Материал успешно добавлен!')
        yield put(push('/study'))
    } catch (error) {
        yield put(studysActions.postStudyMaterial.failure(error));
        yield call(toast.error, 'Ошибка добавления материала!')
    }
}



export function* handlePutStudyMaterial({ payload, callback }) {
    try {
        let formData = new FormData();

        formData.append("file", payload["file"] || 0);
        formData.append("link", payload["link"] || 0);
        formData.append("type", payload["type"]);
        formData.append("name", payload["name"]);

        console.log(formData)

       yield call(studyServices.putStudyMaterial, formData, payload["id"], callback);
        yield put(studysActions.putStudyMaterial.success());
        yield call(toast.success, 'Материал успешно изменен!')
        yield put(push('/study'))
    } catch (error) {
        yield put(studysActions.putStudyMaterial.failure(error));
        yield call(toast.error, 'Ошибка изменения материала!')
    }
}

export function* handleGetStudyMaterials({ payload }) {
    try {
        const data = yield call(studyServices.getStudyMaterials, payload);
        yield put(studysActions.getStudyMaterials.success(data));
    } catch (error) {
        yield put(studysActions.getStudyMaterials.failure(error));
        yield call(toast.error, 'Ошибка получения материалов!')
    }
}

export function* handleGetStudyMaterial({ payload }) {
    try {
        const data = yield call(studyServices.getStudyMaterial, payload);
        yield put(studysActions.getStudyMaterial.success(data));
    } catch (error) {
        yield put(studysActions.getStudyMaterial.failure(error));
        yield call(toast.error, 'Ошибка получения материалa!')
    }
}


export function* handleDeleteStudyMaterial({ payload }) {
    try {
        const data = yield call(studyServices.deleteStudyMaterial, payload);
        yield put(studysActions.deleteStudyMaterial.success(data));
        yield put(push('/study'))
    } catch (error) {
        yield put(studysActions.deleteStudyMaterial.failure(error));
        yield call(toast.error, 'Ошибка удаления материалa!')
    }
}



// export function* handleGetProject({ payload }) {
//     try {
//         const data = yield call(projectsServices.getProject, payload);
//         yield put(projectsActions.getProject.success(data));
//     } catch (error) {
//         yield put(projectsActions.getProject.failure(error));
//         yield call(toast.error, 'Ошибка получения проектов!')
//     }
// }

// export function* handleGetProjectsStatuses() {
//     try {
//         const data = yield call(projectsServices.getProjectsStatuses);
//         yield put(projectsActions.getProjectsStatuses.success(data));
//     } catch (error) {
//         yield put(projectsActions.getProjectsStatuses.failure(error));
//         yield call(toast.error, 'Ошибка получения статусов проектов!')
//     }
// }

// export function* handleSetProjectStatus({ payload }) {
//     try {
//         const data = yield call(projectsServices.setProjectStatus, payload);
//         yield put(projectsActions.setProjectStatus.success());
//         const { location } = yield select(state => state.router);
//         yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
//     } catch (error) {
//         console.log(error)
//         yield put(projectsActions.setProjectStatus.failure());
//         yield call(toast.error, 'Ошибка изменения статуса проекта!')
//     }
// }


export default function* studyWatcher() {
    yield takeLatest(POST_STUDY_MATERIAL[REQUEST], handlePostStudyMaterial);
    yield takeLatest(GET_STUDY_MATERIALS[REQUEST], handleGetStudyMaterials);
    yield takeLatest(GET_STUDY_MATERIAL[REQUEST], handleGetStudyMaterial);
    yield takeLatest(DELETE_STUDY_MATERIAL[REQUEST], handleDeleteStudyMaterial);
    yield takeLatest(PUT_STUDY_MATERIAL[REQUEST], handlePutStudyMaterial);
    
    // yield takeLatest(GET_PROJECTS[REQUEST], handleGetProjects);
    // yield takeLatest(GET_PROJECT[REQUEST], handleGetProject);
    // yield takeLatest(GET_PROJECTS_STATUSES[REQUEST], handleGetProjectsStatuses);
    // yield takeLatest(SET_PROJECT_STATUS[REQUEST], handleSetProjectStatus);
}

