import React from 'react';
import clsx from 'clsx';

import { CircularProgress } from 'shared/Atoms';
import { Button } from '../../Atoms';

const ButtonMainAccent = ({ children, loading, makedStyles, color, ...props }) => (
  <Button color={ color || "primary"} className={clsx(makedStyles)} {...props}>
    {loading && <CircularProgress className="text-white mr-10" size={20} />}
    {children}
  </Button>
);

export default ButtonMainAccent;
