import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  downloadFile: (serverFileName, clientFileName) => {
    serverFileName = serverFileName.split('.');
    return request.get('/files/' + serverFileName[0] + '/' + serverFileName[1], { responseType: 'blob' })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', clientFileName)
        document.body.appendChild(link);
        link.click();
      })
  },
  downloadZipFiles: async (projectId) => request.get(`/files/` + projectId, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', projectId + '.zip')
      document.body.appendChild(link);
      link.click();
    })
};

export default apiWrapper(api);
