import { createMuiTheme } from "@material-ui/core/styles";
import { fontsMapper } from "./constants";

const fontList = Object.keys(fontsMapper).map((fontFamily) => ({
  fontFamily,
  src: `url(${fontsMapper[fontFamily]["font"]})`,
}));

const stlyeObj = Object.keys(fontsMapper).reduce((result, fontFamily) => {
  const selector = fontsMapper[fontFamily].selectors.join(", ");
  result[selector] = { fontFamily: `${fontFamily} !important` };
  return result;
}, {});

// Open Sans
export const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1B53F4",

    },
    secondary: {
      main: "#bda8ff",
    },
    error: {
      main: "#f44336"
    }
  },

  typography: {
    fontFamily: "OpenSansRegular",
    h1: {
      fontSize: '24px'
    }
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": fontList,
        ...stlyeObj,
      },
    },
  },
});
