import { useEffect } from "react";
import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authActions from 'modules/auth/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, ReactMultiSelect, CheckBoxItem, MaskedOutlinedInputField, OutlinedSelectField } from 'shared/Molecules';
import { Select } from 'shared/Molecules/Select';
import usersActions from 'modules/users/data/actions';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

`;

const UserItem = styled.li`
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #fff;
  color: #3A405F;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  list-style-type: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 3px 8px 0px #0003;
  }
`;

const useStyles = makeStyles({
  outerPositioning: {
    // marginBottom: '2em',
  },
  headerButtonLinkOuterPositioning: {
    marginLeft: '1em',
  },
  formControl: {
    display: 'block',
    width: '400px',
  },
});


const NewUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();

  const onSubmit = data => {
    console.log(data)
    dispatch(usersActions.postUser.request(data));
  };

  useEffect(() => {
    dispatch(usersActions.getRoles.request())
  }, [])

  const { roles } = useSelector(state => state.usersReducer)

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Новый пользователь</Typography>
      <FormControl component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <OutlinedInputField
              makedStyles='mb-15'
              type="text"
              label="Фамилия"
              placeholder="Фамилия"
              name="surname"
              control={control}
              rules={{required: 'Поле обязательно к заполнению'}}
            />
            <OutlinedInputField
              makedStyles='mb-15'
              type="text"
              label="Имя"
              placeholder="Имя"
              name="name"
              control={control}
              rules={{required: 'Поле обязательно к заполнению'}}
            />
            <OutlinedInputField
              makedStyles='mb-15'
              type="text"
              label="Отчество"
              placeholder="Отчество"
              name="patronymic"
              control={control}
              rules={{required: 'Поле обязательно к заполнению'}}
            />
            <MaskedOutlinedInputField
              label="Телефон"
              name="phone"
              placeholder="Телефон"
              defaultValue={''}
              mask={["+", /[1-9]/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/,]}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <OutlinedInputField
              makedStyles='mb-15'
              type="email"
              label="E-mail"
              placeholder="E-mail"
              name="email"
              control={control}
              rules={{
                required: 'E-mail обязателен',
                validate: value => validateEmail(value),
              }}
            />
            <OutlinedInputField
              makedStyles='mb-15'
              type="password"
              label="Пароль"
              placeholder="Пароль"
              name="password"
              control={control}
              rules={{required: 'Поле обязательно к заполнению'}}
            />
            <ReactMultiSelect
             label="Роль"
             placeholder="Роль"
              name="role"
              control={control}
              isMulti
              rules={{required: 'Поле обязательно к заполнению'}}
              options={roles}
            />
            <OutlinedInputField
              makedStyles='mt-15'
              type="text"
              label="Телеграм"
              placeholder="Телеграм"
              name="telegram"
              control={control}
              />
          </Grid>
          <Grid item xs={12}>
            <ButtonMainAccent className="mt-10" fullWidth={true} type="submit" variant="contained">+ Создать пользователя</ButtonMainAccent>
          </Grid>
        </Grid>
      </FormControl>
    </ContentWrapperBlock >
  );

}

export default NewUsers
