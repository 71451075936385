import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMainAccent, OutlinedInputField, BorderLinearProgress, InputBaseFile, ListItem, ReactSelect } from "shared/Molecules";
import { FormControl, Grid } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import tasksActions from 'modules/tasks/data/actions';
import DevideFileItem from './DevideFileItem';

const DevideFile = ({ taskId, pagesTypes, ...props }) => {
	const [count, setCount] = useState([0, 0]);
	const dispatch = useDispatch();
	const { handleSubmit, control, setValue, watch } = useForm();
	const [progress, setProgress] = useState(0);
	const { devideFileLoading } = useSelector(state => state.tasksReducer);

	const fileTypes = [];

	for (let i = 0; i < pagesTypes.length; i++) {
		fileTypes.push({value: pagesTypes[i].fileTypeId, label: pagesTypes[i].fileTypeName,})
	}

	const upload = (data) => {
		let total = {};
    let totalSum = 0;
    for (let i = 0; i < count.length; i++) {
      const fileId = i ;//currentFiles[i].id;
      const countPagesTypes = watch("countPagesTypes" + fileId);
      
      for (let q = 0; q < countPagesTypes; q++) {
        let type = +watch('fileType' + fileId + '_' + q);
        let pages = +watch('pages' + fileId + '_' + q);
        // let sum = +watch('price' + fileId + '_' + q);
        total[type] ? total[type].pages += pages : total[type] = { type, pages, name: fileTypes.filter(({ value }) => value === watch('fileType' + fileId + '_' + q))[0].label }

				for (let o = 0; o < pagesTypes.length; o++) {

					if(pagesTypes[o].fileTypeId === type){
						data['price'+fileId + '_' + q] =  pagesTypes[o].pricePerPage;
					}
				}
      }
    }

		for (let i = 0; i < pagesTypes.length; i++) {
			total[pagesTypes[i].fileTypeId].pricePerPage = pagesTypes[i].pricePerPage
			if(total[pagesTypes[i].fileTypeId]?.pages !== pagesTypes[i].pages){
				toast.error('Не совпадает количество страниц по типу ' + pagesTypes[i].fileTypeName);
				return;
			}
		}


    console.log(Object.values(total));
	
		// name={"countPagesTypes" + indexNewFile}
		data.count = count.length;
		data.taskId = taskId;
		console.log(data);
		setProgress(0);
		dispatch(tasksActions.devideFile.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))
	}
	const [countPagesTypes, setCountPagesTypes] = useState([0]);

	useEffect(() => {
		setValue('countPagesTypes' + taskId, countPagesTypes.length)
	}, [countPagesTypes])
	return (
		<FormControl component="form" onSubmit={handleSubmit(upload)} className="w-100 my-15 px-5">
			<Grid container spacing={2}>
				{count.map((value, indexNewFile) => {
					return ( 
					<DevideFileItem 
						indexNewFile={ indexNewFile} 
						control={control} 
						setValue={setValue} 
						watch={watch} 
						fileTypes={fileTypes}/>)
					// 		<Grid item xs={12}>
					// <ListItem className='p-10'>
					// 		<Grid container spacing={2}>
					// 	<Grid item xs={1} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
					// 		{indexNewFile + 1}
					// 	</Grid>
					// 	 <Grid item xs={6} className='d-flex' alignItems='center'>
					// 		<InputBaseFile
					// 			disabled={devideFileLoading}
					// 			control={control}
					// 			name={"file" + indexNewFile}
					// 			rules={{ required: 'Поле обязательно к заполнению' }}
					// 		/>
					// 	</Grid>
						{/*<Grid item xs={5}>
							<OutlinedInputField
								required
								makedStyles='mb-15'
								type="number"
								label="Объем в страницах (кол-во)"
								placeholder="Объем в страницах (кол-во)"
								name={"pages" + index}
								inputProps={{ min: 0 }}
								control={control}
								rules={{ required: 'Поле обязательно к заполнению', }}
								disabled={devideFileLoading}
							/>
						</Grid> */}
				// 		<OutlinedInputField
				// 	required
				// 	type="number"
				// 	placeholder="Стоимость одной страницы (руб.)"
				// 	name={"countPagesTypes" + indexNewFile}
				// 	control={control}
				// 	rules={{ required: 'Поле обязательно к заполнению' }}
				// 	style={{ display: 'none' }}
				// 	defaultValue={1}
				// />
				// {countPagesTypes.fill(0).map((value, index) => {
				// 	return (
				// 		<>
				// 			<Grid item xs={4}>
				// 				<ReactSelect
				// 					makedStyles='mt-15'
				// 					required
				// 					label='Тип файла'
				// 					placeholder="Тип файла"
				// 					control={control}
				// 					name={"fileType" + indexNewFile + '_' + index}
				// 					rules={{ required: 'Поле обязательно к заполнению', }}
				// 					options={fileTypes.filter(({ id }) => {
				// 						let count = 0;

				// 						for (let i = 0; i < countPagesTypes.length; i++) {
				// 							if (+watch('fileType' + indexNewFile + '_' + i) === id) {
				// 								count++;
				// 							}
				// 						}
				// 						return count === 0

				// 					}).map(({ id, name }) => { return { value: id, label: name } })}
				// 				/>
				// 			</Grid>
				// 			<Grid item xs={4}>
				// 				<OutlinedInputField
				// 					required
				// 					makedStyles='mt-15'
				// 					type="number"
				// 					label="Объем в страницах (кол-во)"
				// 					placeholder="Объем в страницах (кол-во)"
				// 					name={"pages" + indexNewFile + '_' + index}
				// 					inputProps={{ min: 0 }}
				// 					control={control}
				// 					rules={{ required: 'Поле обязательно к заполнению', }}
				// 				/>
				// 			</Grid>
				// 			<Grid item xs={4}>
				// 				<OutlinedInputField
				// 					required
				// 					makedStyles='mt-15'
				// 					type="number"
				// 					label="Стоимость одной страницы (руб.)"
				// 					placeholder="Стоимость одной страницы (руб.)"
				// 					name={"price" + indexNewFile + '_' + index}
				// 					control={control}
				// 					rules={{ required: 'Поле обязательно к заполнению' }}
				// 				/>
				// 			</Grid>
				// 		</>
				// 	)
				// })}

				// <Grid item xs={6} >
				// 	<ButtonMainAccent
				// 		onClick={() => setCountPagesTypes(prev => Array(prev.length + 1).fill(0))}
				// 		fullWidth={true} variant="contained" className="text-white">
				// 		Добавить еще один тип
				// 	</ButtonMainAccent>
				// </Grid>
				// <Grid item xs={6} >
				// 	<ButtonMainAccent
				// 		disabled={countPagesTypes < 2}
				// 		onClick={() => {
				// 			setValue("fileType" + indexNewFile + '_' + (countPagesTypes.length - 1), null)
				// 			setCountPagesTypes(prev => prev.length > 1 ? [...prev.slice(0, prev.length - 1)] : prev)
				// 		}}
				// 		fullWidth={true} variant="contained" className={`text-white ${countPagesTypes < 2 ? 'bg-gray' : 'bg-danger'}`}>
				// 		Удалить последний тип
				// 	</ButtonMainAccent>
				// </Grid>
				// 		</Grid>
				// 	</ListItem>
				// 	</Grid>
					
				})}
				{devideFileLoading && <Grid item xs={12} ><BorderLinearProgress className="mt-15" variant="determinate" value={progress} /> </Grid>}
				<Grid item xs={4} >
					<ButtonMainAccent disabled={devideFileLoading} loading={devideFileLoading} type="submit" fullWidth={true} variant="contained" className="text-white bg-darkGray">
						Раздробить
					</ButtonMainAccent>
				</Grid>
				<Grid item xs={4} >
					<ButtonMainAccent disabled={devideFileLoading} onClick={() => setCount(prev => Array(prev.length + 1).fill(0))} fullWidth={true} variant="contained" className="text-white">
						Добавить еще одну часть
					</ButtonMainAccent>
				</Grid>
				<Grid item xs={4} >
					<ButtonMainAccent disabled={devideFileLoading} onClick={() => setCount(prev => prev.length > 2 ? [...prev.slice(0, prev.length - 1)] : prev)} fullWidth={true} variant="contained" className="text-white bg-danger">
						Удалить последнюю часть
					</ButtonMainAccent>
				</Grid>
			</Grid>
		</FormControl>
	)
}

export default DevideFile
