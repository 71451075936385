import { GET_CLIENTS, GET_CLIENT, PUT_CLIENT, POST_CLIENT } from './actions';

const initialState = {
  clients: [],
  loading: false,
  error: false,

  postClientLoading: false,
  postClientError: false,

  putClientLoading: false,
  putClientError: false,

  client: {},
  clientLoading: false,
  clientError: false,

};

function clientsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_CLIENTS.REQUEST: return { ...state, loading: true, error: false };
    case GET_CLIENTS.SUCCESS: return { ...state, loading: false, error: false, clients: payload.data, };
    case GET_CLIENTS.FAILURE: return { ...state, loading: false, error: payload.message, };

    case GET_CLIENT.REQUEST: return { ...state, clientLoading: true, clientError: false };
    case GET_CLIENT.SUCCESS: return { ...state, clientLoading: false, clientError: false, client: payload.data, };
    case GET_CLIENT.FAILURE: return { ...state, clientLoading: false, clientError: payload.message, };

    case PUT_CLIENT.REQUEST: return { ...state, putClientLoading: true, putClientError: false };
    case PUT_CLIENT.SUCCESS: return { ...state, putClientLoading: false, putClientError: false };
    case PUT_CLIENT.FAILURE: return { ...state, putClientLoading: false, putClientError: payload.message };

    case POST_CLIENT.REQUEST: return { ...state, postClientLoading: true, postClientError: false };
    case POST_CLIENT.SUCCESS: return { ...state, postClientLoading: false, postClientError: false };
    case POST_CLIENT.FAILURE: return { ...state, postClientLoading: false, postClientError: payload.message, };

    default:
      return state;
  }
}

export default clientsReducer;
