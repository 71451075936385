import { useState, useEffect } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Checkbox, ListItem as MListItem, List, ListItemIcon, ListItemText, TableSortLabel, IconButton } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import tasksActions from 'modules/tasks/data/actions';
import { ButtonMainAccent, OutlinedInputField, ListItem, NativeDatePicker, Popover } from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { Icon } from 'shared/Atoms';
import { taskStatusColorMapper } from 'shared/constants';
import { checkForbiddenAccess, checkAvailableAccess } from 'utils/helperFunctions';

const TaskItem = ({ id, clientFileName, projectId, statusName, executorName, executorId, controllerId, statusId, controllerName, dateTimeEnd, dateDiff, user, ...props }) => {
    const history = useHistory();
    return (
        <ListItem
            className='p-15'
            onClick={() => (checkAvailableAccess([7]) || (executorId === user?.id) || (controllerId === user?.id)) ? history.push(`tasks/${id}`) : null}
        >
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3} className="d-flex">
                    <Typography title={clientFileName} noWrap={true} fontSize='1' >{projectId}_{clientFileName}</Typography>
                </Grid>
                <Grid item xs={2} className='d-flex align-items-center'>
                    <div className='mr-5 ' style={{ borderRadius: '50%', width: '10px', height: '10px', backgroundColor: taskStatusColorMapper[statusId] || 'black', flexShrink: 0 }}></div>

                    {statusName}
                </Grid>
                <Grid item xs={2}>{executorName}</Grid>
                <Grid item xs={2}>{controllerName}</Grid>
                <Grid item xs={2} style={dateDiff < 0 && statusId !== 8 && statusId !== 7 ? { color: 'red' } : {}}>
                    {dateTimeEnd}
                </Grid>
                <Grid item xs={1} className='text-bold'>
                    {(checkAvailableAccess([7]) || (executorId === user?.id) || (controllerId === user?.id)) &&
                        <IconButton title='Открыть в новой вкладке'  onClick={(event) => { event.stopPropagation();
                            window.open(`tasks/${id}`, "_blank")}}> 
                        <Icon name='doubleArrow' />
                        </IconButton>
                    }
                </Grid>
            </Grid>
        </ListItem>
    )
}

export default TaskItem