import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid } from '@material-ui/core';
import { ButtonMainAccent, ReactSelect, NativeDatePicker } from 'shared/Molecules';
import tasksActions from 'modules/tasks/data/actions';
import {checkForbiddenAccess} from 'utils/helperFunctions';

const Project = ({ id, ...props }) => {
	const dispatch = useDispatch();
	const { handleSubmit, control, watch, setValue, reset, register } = useForm();

	const onSubmit = data => {
		dispatch(tasksActions.setExecutor.request({ ...data, taskId: id }));
		console.log(data)
	};

	const { executors } = useSelector(state => state.usersReducer);

	return (
		<FormControl component="form" onSubmit={handleSubmit(onSubmit)} className="w-100 my-15 px-5">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<ReactSelect
						required
						label='Исполнитель'
						control={control}
						name={"executorId"}
						rules={{ required: 'Поле обязательно к заполнению', }}
						options={executors?.map(({ userId, userName }) => { return { value: userId, label: userName } })}
					/>
				</Grid>
				<Grid item xs={3}>
					<NativeDatePicker
						required
						fullWidth={true}
						makedStyles='mb-15'
						label="Срок сдачи"
						name="date"
						control={control}
						rules={{ required: 'Поле обязательно к заполнению', }}
					/>
				</Grid>
				<Grid item xs={3}>
					<NativeDatePicker
						fullWidth={true}
						makedStyles='mb-15'
						label="Время сдачи "
						name="time"
						type="time"
						control={control}
						rules={{
							required: 'Поле обязательно к заполнению',
						}}
					/>
				</Grid>
				<Grid item xs={6}></Grid>
				<Grid item xs={6}>
					<ButtonMainAccent type='submit' fullWidth={true} variant="contained" className="">
						Отправить в работу
					</ButtonMainAccent>
				</Grid>
			</Grid>
		</FormControl>
	)
}

export default Project
