import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  getFileTypes: async () => request.get(`/file-types`),
  postFileTypes: async (payload) => request.post(`/file-types`, payload),
  putFileType: async (payload) => request.put(`/file-types/${payload.id}`, payload),
  

  getUser: async (payload) => {
    console.log(payload)
    return request.get(`/users/${payload.userId}`);
  },
  getRoles: async () => request.get(`/users/roles`),
  postUser: async (payload) => {
    console.log(payload)
    return request.post(`/users`, payload);
  },
  putUser: async (payload) => {
    console.log(payload)
    return request.put(`/users/${payload.userId}`, payload);
  },
  blockUser: async (payload) => request.put(`/users/${payload.userId}/block`, payload),
  
  updateUser: async (payload, personaInquiryId) =>
    request.put(`/users/me`, { personaInquiryId, ...payload }),
};

export default apiWrapper(api);
