import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authActions from 'modules/auth/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, CheckBoxItem } from 'shared/Molecules';
import { Link } from 'react-router-dom'

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
`;

const Reports = () => {
  return (
    <ContentWrapperBlock>
        <Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1">Отчеты</Typography>
        <Link to="/reports/projects" className="text-black link">Отчеты по проектам в excel</Link>
    </ContentWrapperBlock>
  );

}

export default Reports