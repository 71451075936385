import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, IconButton, Divider } from '@material-ui/core';

import { Icon, Typography } from 'shared/Atoms';
import { ButtonMainAccent, InputBaseFile, BorderLinearProgress, OutlinedInputField, ListItem } from 'shared/Molecules';
import tasksActions from 'modules/tasks/data/actions';
import commonServices from 'services/common';

const StepOne = ({ taskId, statusId, executorName, executorId, controllerId, clientFileName, serverFileName, fileTypeName, pages, dateTimeReady, ...props }) => {
	const dispatch = useDispatch();
	const { handleSubmit, control } = useForm();

	const [progress, setProgress] = useState(0);
	const { uploadLoading } = useSelector(state => state.tasksReducer);

	const upload = (data) => {
		data.taskId = taskId;
		console.log(data);
		setProgress(0);
		dispatch(tasksActions.uloadReadyFile.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))
	}

	const { user } = useSelector(state => state.authReducer);


	return (
		<ListItem className="p-15" pointer={false}>
			<Typography className='text-bold' fontSize='2'>В работе</Typography>
			{statusId === 2 && (user.id === executorId || user.rolesId.includes(1)) &&
				<>
					<Divider className='my-10' />
					<FormControl component="form" onSubmit={handleSubmit(upload)} className="w-100 my-15 px-5">
						<Grid container spacing={2}>
							<Grid item xs={12} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
								Загрузите файл для oтправки на проверку
							</Grid>
							<Grid item xs={6}>
								<InputBaseFile
									control={control}
									name={"file"}
									rules={{ required: 'Поле обязательно к заполнению' }}
								/>
								<OutlinedInputField
									makedStyles='my-15'
									type="number"
									label="Добавить комментарий"
									placeholder="Добавить комментарий"
									name={"comment"}
									control={control}
									multiline
									minRows={5}
									defaultValue={''}
								/>
								{uploadLoading && <BorderLinearProgress className="mt-15" variant="determinate" value={progress} />}
								<ButtonMainAccent type="submit" fullWidth={true} variant="contained" className="text-white">
									Отправить на проверку
								</ButtonMainAccent>
							</Grid>
						</Grid>
					</FormControl>
				</>
			}
			{((statusId > 2 && statusId !== 7 && statusId < 8) || statusId === 10) &&
				<ListItem className='mt-15 px-15 py-10' pointer={false}>
					<Grid container spacing={2} alignItems='center' >
						<Grid item xs={5}>
							<div className="d-flex-column  justify-content-center h-100">
								<span className="semi-bold">{clientFileName}</span>
								<div className="mt-5">
									{fileTypeName} {' '} {pages} стр.
								</div>
							</div>
						</Grid>
						<Grid item xs={1}>
							<IconButton title='Скачать готовый файл' onClick={() => commonServices.downloadFile(serverFileName, clientFileName)}>
								<Icon name='cloudDownload' className='text-primary' fontSize='medium' />
							</IconButton>
						</Grid>
						<Grid item xs={3}>
							<div className="">
								<Typography className="text-gray" fontSize="1">Исполнитель</Typography>
								<Typography className="text-bold" fontSize="1.1">{executorName}</Typography>
							</div>
						</Grid>
						<Grid item xs={3}>
							<div className="">
								<Typography className="text-gray" fontSize="1">Выполненно</Typography>
								<Typography className="text-bold" fontSize="1.1">{dateTimeReady}</Typography>
							</div>
						</Grid>
					</Grid>
				</ListItem>
			}
			{
				statusId === 7 &&
				<div className="d-flex justify-content-center align-items-center semi-bold">
					<Icon name='check' fontSize='large' className='mr-10 text-primary' />
					Задание выполнено
				</div>
			}
		</ListItem>
	)
}

export default StepOne
