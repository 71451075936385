import { useState, useEffect } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, List, ListItem as MListItem, ListItemIcon, Checkbox, ListItemText, Tooltip, TableSortLabel } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from 'react-router-dom';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, NativeDatePicker, ListItem, Popover } from 'shared/Molecules';
import { Icon, Typography, Select } from 'shared/Atoms';
import projectsActions from 'modules/projects/data/actions';
import usersActions from 'modules/users/data/actions';
import { projectStatusColorMapper } from 'shared/constants';
import {checkForbiddenAccess, checkAvailableAccess} from 'utils/helperFunctions';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }

  .MuiTableSortLabel-icon {
    opacity: 0.2 ;
    color: #8f9bb3 !important;
  }
`;

const Projects = () => {
  const dispatch = useDispatch();
  const { control, watch, setValue } = useForm();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [order, setOrder] = useState('default');
  const [orderBy, setOrderBy] = useState('id');
  const [filterStatuses, setFilterStatuses] = useState([1, 2, 3]);
  const [filterSalesman, setFilterSalesman] = useState('');

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(projectsActions.getProjectsStatuses.request())
    dispatch(usersActions.getSalesmans.request())
  }, [])

  // useEffect(() => {
  //   let intervalId = setInterval(() => {
  //     dispatch(projectsActions.getProjects.request({
  //       page,
  //       perPage,
  //       order,
  //       orderBy,
  //       filterStatuses: JSON.stringify(filterStatuses),
  //       filterSalesman: filterSalesman?.value || 0,
  //       dateFrom: watch('dateFrom') || 0,
  //       dateTo: watch('dateTo') || 0,
  //       search: watch('search') || '',
  //     }))

  //   }, 60000)
  //   return () => clearInterval(intervalId);
  // }, [])

  useEffect(() => {
    let func = () => dispatch(projectsActions.getProjects.request({
      page,
      perPage,
      order,
      orderBy,
      filterStatuses: JSON.stringify(filterStatuses),
      filterSalesman: filterSalesman?.value || 0,
      dateFrom: watch('dateFrom') || 0,
      dateTo: watch('dateTo') || 0,
      search: watch('search') || '',
    }))
    func();
    let intervalId = setInterval(func, 60000);
    return () => clearInterval(intervalId);
  }, [page, perPage, order, orderBy, filterStatuses, filterSalesman, watch('dateFrom'), watch('dateTo'), watch('search')])

  useEffect(() => {
    setPage(1)
  }, [watch('search')])

  const { projects, projectsStatuses } = useSelector(state => state.projectsReducer);
  const { salesmans } = useSelector(state => state.usersReducer);

  const headCells = [
    { id: 'id', label: '№', points: 1, sort: true, filter: false },
    { id: 'status', label: 'Статус', points: 2, sort: false, filter: true },
    { id: 'client', label: 'Клиент', points: 2, sort: true, filter: false },
    { id: 'number', label: '№ в уч. клиента', points: 2, sort: true, filter: false },
    { id: 'salesman', label: 'Продажник', points: 2, sort: true, filter: true },
    { id: 'deadline', label: 'Срок сдачи', points: 3, sort: true, filter: true },
  ];

  const createSortHandler = (property) => {
    if (orderBy === property) {
      setOrder(prev => prev === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(property);
      setOrder('desc')
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(undefined);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setPopoverId(undefined)
  };

  const showFilter = (event, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(id)
  }

  const handleToggleFilterStatuses = (value) => () => {
    const currentIndex = filterStatuses.indexOf(value);
    const newChecked = [...filterStatuses];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilterStatuses(newChecked);
    handleClosePopover()
  };

  useEffect(() => {
    handleClosePopover()
  }, [filterSalesman])

  const resetDates = () => {
    handleClosePopover()
    setValue('dateFrom', 0);
    setValue('dateTo', 0);
  }

  return (
    <ContentWrapperBlock>
      <Popover
        handleClick={handleOpenPopover}
        handleClose={handleClosePopover} anchorEl={anchorEl} id={popoverId}
      >
        {popoverId === 'status' &&
          <List dense={true}>
            {projectsStatuses?.map(({ id, name }) => {
              const labelId = `checkbox-list-label-${id}`;
              return (
                <MListItem key={id} button onClick={handleToggleFilterStatuses(id)} className='py-0'>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={filterStatuses.indexOf(id) !== -1}
                      tabIndex={-1}
                      color="primary"
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={name} />
                </MListItem>
              );
            })}
          </List>}
        {popoverId === 'salesman' &&
          <div className='p-10' style={{ width: '250px', }}>
            <Select isClearable={true} value={filterSalesman} onChange={setFilterSalesman} options={salesmans?.map(({ userId, userName }) => { return { value: userId, label: userName } })} />
          </div>
        }
        {popoverId === 'deadline' &&
          <div className='p-10' style={{ width: '250px' }}>
            <NativeDatePicker
              fullWidth={true}
              label="От"
              placeholder="От"
              name="dateFrom"
              control={control}
              className='mb-15'
            />
            <NativeDatePicker
              fullWidth={true}
              label="До"
              placeholder="До"
              name="dateTo"
              control={control}
              className='mb-15'
            />
            <ButtonMainAccent fullWidth={true} onClick={resetDates}>Сбросить</ButtonMainAccent>
          </div>
        }
      </Popover>
      <Typography className=" mb-30" variant="h1">Проекты</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <OutlinedInputField
            makedStyles='mb-15'
            type="text"
            placeholder="Поиск по № проекта"
            name="search"
            control={control}
          />
        </Grid>
        {checkAvailableAccess([3]) &&
          <Grid item xs={6}>
            <ButtonLink to="/projects/new" fullWidth={true} type="submit" variant="contained">+ Создать новый проект</ButtonLink>
          </Grid>
        }
        <Grid item xs={12} component="ul">
          <Grid container spacing={2} className="p-10 mb-5" >
            {headCells.map(({ id, label, points, sort, filter }) => {
              return (
                <Grid item xs={points} key={id} className='d-flex'>
                  {sort ?
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : 'desc'}
                      onClick={() => createSortHandler(id)}
                    >
                      {label}
                    </TableSortLabel>
                    :
                    label
                  }

                  {filter &&
                    <Icon
                      aria-describedby={id}
                      name='settings'
                      className={`text-gray ${!sort ? 'ml-5' : ''}`}
                      onClick={(event) => showFilter(event, id)}
                    />}
                </Grid>
              )
            })}

          </Grid>
          {projects?.totalItems === 0 && <ListItem ><div className='text-center p-15'>Проекты не найдены</div></ListItem>}
          {projects?.items?.map(({ id, clientName, totalTasks, totalTasksReady, numberInClient, dateDiff, statusId, statusName, userNameCreate, dateTimeEnd }) => {
            return (
              <ListItem className='p-15' onClick={() => checkAvailableAccess([3,7])  ? history.push(`projects/${id}`) : null  }>
                  <Grid container spacing={2}>
                    <Grid item xs={1} className="text-bold">{id}</Grid>
                    <Grid item xs={2} className='d-flex align-items-center'>
                      <div className='mr-5 ' style={{ borderRadius: '50%', width: '10px', height: '10px', backgroundColor: projectStatusColorMapper[statusId] || 'black', flexShrink: 0 }}></div>
                      {statusName}
                      {statusId === 2 &&
                        <Tooltip title={`В проекте выполнено ${totalTasksReady}/${totalTasks} файлов`}>
                          <div className="ml-5">({totalTasksReady}/{totalTasks})</div>
                        </Tooltip>}
                    </Grid>
                    <Grid item xs={2}>{clientName}</Grid>
                    <Grid item xs={2}>{numberInClient}</Grid>
                    <Grid item xs={2}>{userNameCreate}</Grid>
                    <Grid item xs={3} style={dateDiff < 0 && statusId !== 4 && statusId !== 5 && statusId !== 6 ? { color: 'red' } : {}}>{dateTimeEnd}</Grid>
                  </Grid>
              </ListItem>
            )
          })}
        </Grid>
      </Grid>
      {projects?.totalItems / perPage > 1 && <Pagination className="d-flex justify-content-center" count={Math.ceil(projects?.totalItems / perPage)} page={page} onChange={handleChange} />}
    </ContentWrapperBlock>
  );
}

export default Projects
