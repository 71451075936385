import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { privateRoutes } from 'modules/common/constants';
import { useDispatch, useSelector } from 'react-redux';
import { checkAvailableAccess } from 'utils/helperFunctions';

import usersServices from 'services/users';
import authActions from 'modules/auth/data/actions';

export const PrivateRoute = ({ component: Component, isAuthenticated, role, path, ...rest }) => {
  const redirectPath = isAuthenticated || '/';
  const history = useHistory();

 

  // console.log(user)
  // if (!user) {
  //   return null;
  // }

  if (privateRoutes.users.userNew.regx.test(path) && !checkAvailableAccess(privateRoutes.users.userNew.availableFor)) history.push('/');
  if (privateRoutes.users.user.regx.test(path) && !checkAvailableAccess(privateRoutes.users.user.availableFor)) history.push('/')
  if (privateRoutes.users.users.regx.test(path) && !checkAvailableAccess(privateRoutes.users.users.availableFor)) history.push('/')

  if (privateRoutes.projects.projects.regx.test(path) && !checkAvailableAccess(privateRoutes.projects.projects.availableFor)) history.push('/')
  if (privateRoutes.projects.project.regx.test(path) && !checkAvailableAccess(privateRoutes.projects.project.availableFor)) history.push('/')
  if (privateRoutes.projects.projectNew.regx.test(path) && !checkAvailableAccess(privateRoutes.projects.projectNew.availableFor)) history.push('/')

  // if (privateRoutes.tasks.tasks.regx.test(path) && !checkAvailableAccess(privateRoutes.tasks.tasks.availableFor)) history.push('/')
  if (privateRoutes.tasks.task.regx.test(path) && !checkAvailableAccess(privateRoutes.tasks.task.availableFor)) history.push('/')

  if (privateRoutes.clients.clients.regx.test(path) && !checkAvailableAccess(privateRoutes.clients.clients.availableFor)) history.push('/')
  if (privateRoutes.clients.client.regx.test(path) && !checkAvailableAccess(privateRoutes.clients.client.availableFor)) history.push('/')
  if (privateRoutes.clients.clientNew.regx.test(path) && !checkAvailableAccess(privateRoutes.clients.clientNew.availableFor)) history.push('/')

  if (privateRoutes.settings.settings.regx.test(path) && !checkAvailableAccess(privateRoutes.settings.settings.availableFor)) history.push('/')
  if (privateRoutes.settings.filetypes.regx.test(path) && !checkAvailableAccess(privateRoutes.settings.filetypes.availableFor)) history.push('/')

  if (privateRoutes.reports.reports.regx.test(path) && !checkAvailableAccess(privateRoutes.reports.reports.availableFor)) history.push('/')
  if (privateRoutes.reports.projects.regx.test(path) && !checkAvailableAccess(privateRoutes.reports.projects.availableFor)) history.push('/')

  if (privateRoutes.study.newStudy.regx.test(path) && !checkAvailableAccess(privateRoutes.study.newStudy.availableFor)) history.push('/')
  if (privateRoutes.study.oneStudy.regx.test(path) && !checkAvailableAccess(privateRoutes.study.oneStudy.availableFor)) history.push('/')

  
  const { user } = useSelector(state => state.authReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    let func = () => {
      if (!user?.id) {
        return;
      }
      usersServices.getUser({ userId: user.id })
      .then((data) => {
        console.log(user.rolesId.length, data.data.rolesId.length  )
        let allowRoles = user.rolesId?.filter(value => {
          return data.data.rolesId.includes(value)
        });

        if(allowRoles.length !== user.rolesId.length ){
          dispatch(authActions.currentUser.success({ data: data.data }))
        }
      })
    };
    func()
    let intervalId = setInterval(func, 60000)
    return () => clearInterval(intervalId);

  }, [user])

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export const PublicRoute = ({ component: Component, isAuthenticated, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? <Redirect to={privateRoutes.tasks.tasks.route} /> : <Component {...props} />
      }
    />
  );
};
