import React from "react";

import { Box } from "@material-ui/core";

import styled from "styled-components";

import { CircularProgress } from "shared/Atoms";

const StyledBox = styled(Box)`
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const Loader = styled((props) => (
  <StyledBox {...props}>
    <CircularProgress />
  </StyledBox>
));

export default Loader;
