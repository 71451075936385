import http from "./http-common";

class UploadFilesService {
  upload(filesObj, data, onUploadProgress) {
    let formData = new FormData();
    let keys = [];
    console.log(filesObj)
    // formData.append("files[]", files);
    Object.values(filesObj).forEach(obj => {
      keys.push(obj.id)
      formData.append("file" + obj.id, obj.file);
      formData.append(obj.id,  JSON.stringify({
        pages: data['pages' + obj.id],
        fileType: data['fileType' + obj.id],
        requirements: data['requirements' + obj.id],
        price: data['price' + obj.id],
      }));

    });

    formData.append('name', data['name']);
    formData.append('number', data['number']);
    formData.append('date', data['date']);
    formData.append('time', data['time']);
    formData.append('keys',  JSON.stringify(keys));

    console.log(formData)

    return http.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }
}

export default new UploadFilesService();