import {
  GET_TASKS, GET_TASK, SET_EXECUTOR, DEVIDE_FILE, GET_TASKS_STATUSES, UPLOAD_READY_FILE,
  CHANGE_USER,
  UPLOAD_ERROR_FILE,
  UPLOAD_SUCCESS_FILE,
  CHANGE_PASS,
  CLAER_TASK,
} from './actions';

const initialState = {
  tasks: [],
  loading: false,
  error: false,

  task: {},
  taskLoading: false,
  taskError: false,

  executorLoading: false,
  executorError: false,

  devideFileLoading: false,
  devideFileError: false,

  tasksStatuses: [],
  tasksStatusesLoading: false,
  tasksStatusesError: false,

  changeUserLoading: false,
  changeUserError: false,

  changePassLoading: false,
  changePassError: false,

  uploadLoading: false,
  uploadError: false,

  clearTaskLoading: false,
  clearTaskError: false,

};

function tasksReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TASKS.REQUEST: return { ...state, loading: true, error: false };
    case GET_TASKS.SUCCESS: return { ...state, loading: false, error: false, tasks: payload.data };
    case GET_TASKS.FAILURE: return { ...state, loading: false, error: payload.message };

    case GET_TASK.REQUEST: return { ...state, taskLoading: true, taskError: false };
    case GET_TASK.SUCCESS: return { ...state, taskLoading: false, taskError: false, task: payload.data };
    case GET_TASK.FAILURE: return { ...state, taskLoading: false, taskError: payload.message };

    case GET_TASKS_STATUSES.REQUEST: return { ...state, tasksStatusesLoading: true, tasksStatusesError: false };
    case GET_TASKS_STATUSES.SUCCESS: return { ...state, tasksStatusesLoading: false, tasksStatusesError: false, tasksStatuses: payload.data };
    case GET_TASKS_STATUSES.FAILURE: return { ...state, tasksStatusesLoading: false, tasksStatusesError: payload.message };

    case SET_EXECUTOR.SUCCESS: return { ...state, executorLoading: false, executorError: false };
    case SET_EXECUTOR.FAILURE: return { ...state, executorLoading: false, executorError: payload.message };

    case DEVIDE_FILE.REQUEST: return { ...state, devideFileLoading: true, devideFileError: false };
    case DEVIDE_FILE.SUCCESS: return { ...state, devideFileLoading: false, devideFileError: false };
    case DEVIDE_FILE.FAILURE: return { ...state, devideFileLoading: false, devideFileError: payload.message };

    case UPLOAD_SUCCESS_FILE.REQUEST:
    case UPLOAD_READY_FILE.REQUEST:
    case UPLOAD_ERROR_FILE.REQUEST: return { ...state, uploadLoading: true, uploadError: false };

    case UPLOAD_SUCCESS_FILE.SUCCESS:
    case UPLOAD_READY_FILE.SUCCESS:
    case UPLOAD_ERROR_FILE.SUCCESS: return { ...state, uploadLoading: false, uploadError: false };

    case UPLOAD_SUCCESS_FILE.FAILURE:
    case UPLOAD_READY_FILE.FAILURE:
    case UPLOAD_ERROR_FILE.FAILURE: return { ...state, uploadLoading: false, uploadError: payload.message };

    case CHANGE_USER.REQUEST: return { ...state, changeUserLoading: true, changeUserError: false };
    case CHANGE_USER.SUCCESS: return { ...state, changeUserLoading: false, changeUserError: false };
    case CHANGE_USER.FAILURE: return { ...state, changeUserLoading: false, changeUserError: payload.message };

    case CHANGE_PASS.REQUEST: return { ...state, changePassLoading: true, changePassError: false };
    case CHANGE_PASS.SUCCESS: return { ...state, changePassLoading: false, changePassError: false };
    case CHANGE_PASS.FAILURE: return { ...state, changePassLoading: false, changePassError: payload.message };

    case CLAER_TASK.REQUEST: return { ...state, clearTaskLoading: true, clearTaskError: false };
    case CLAER_TASK.SUCCESS: return { ...state, clearTaskLoading: false, clearTaskError: false };
    case CLAER_TASK.FAILURE: return { ...state, clearTaskLoading: false, clearTaskError: payload.message };

    default:
      return state;
  }
}

export default tasksReducer;
