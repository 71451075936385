import ReactSelect from 'react-select';

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        color: state.selectProps.menuColor,
        zIndex: 1000
      }),
      control: (provided, state) => ({
        ...provided,
        // color: state.selectProps.menuColor,
        height: '40px',
      }),
   
    singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';
  
      return { ...provided,  };
    }
  }

const Select = ({ ...props }) => (
    <ReactSelect styles={customStyles} placeholder='Введите ...' {...props} />
)

export default Select;