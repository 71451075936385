import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import authActions, { SIGNUP, SIGNIN, SIGNOUT, FORGOT_PASSWORD, CHANGE_PASSWORD } from 'modules/auth/data/actions';
// import { REQUEST } from 'modules/common/actions';
import { signup, signin, signout, forgotPassword, changePassword, getNextAuthRouteAfterSignIn } from 'services/auth';
import { unSetSessionCookies, setSessionCookies } from 'modules/common/utils';

// import { routes } from 'modules/auth/constants';

// import usersActions from 'modules/user/data/actions';
// import providerActions from 'modules/provider/data/actions';
// import userServices from 'services/user';
// import { MEDICAL_RECORD_REQUEST_STATUS } from 'modules/dashboard/constants';

import { toast } from 'react-toastify'
import usersServices from 'services/users';

// export function* handleSignupSubmit() {
//   while (true) {
//     try {
//       const { payload, history } = yield take(SIGNUP[REQUEST]);
//       const data = yield call(signup, payload);
//       yield put(authActions.signup.success(data));
//       yield call(history.push, routes.signIn, { from: routes.signUp });
//     } catch (error) {
//       yield call(ToasterService.notifyOneUpdaptebleToastRequestError, error, ToasterService.containers.AUTH_CONTAINER)
//     }
//   }
// }

export function* handleSigninRequest(action) {
  try {
    console.log(action);
    const { data } = yield call(signin, action.payload);
    console.log(data);
    // setSessionCookies({ user: data.user, accessToken: data.accessToken });
    localStorage.setItem('accessToken', data.accessToken)
    yield put(authActions.signin.success(data));
    
    const { data: userData} = yield call(usersServices.getUser, {userId: data.userId});
    console.log(userData)
    yield put(authActions.currentUser.success({data: userData}))
    // yield put(usersActions.getUser.success(userData))

    // if (userData.data.medicalRecordRequests.length > 0) {
    //   yield put(providerActions.generateRecord.success({ data: userData.data.medicalRecordRequests }));
    // }


    // yield put(push(getNextAuthRouteAfterSignIn(userData?.data, userData.data.medicalRecordRequests, routes, MEDICAL_RECORD_REQUEST_STATUS)));
  } catch (error) {
    yield put(authActions.signin.failure(error));
    yield call(toast.error, 'Неправильный логин или пароль!');
  }
}

export function* handleSigninSubmit() {
  yield takeLatest(SIGNIN.REQUEST, handleSigninRequest);
}

// export function* handleSignout() {
//   while (true) {
//     try {
//       const { payload } = yield take(SIGNOUT[REQUEST]);
//       yield call(signout, payload);

//       unSetSessionCookies();
//       yield put(authActions.signout.success());
//       window.location.href = '/';
//     } catch (error) {
//       const { code, message } = error;
//       yield put(authActions.signout.success({ code, message }));
//     }
//   }
// }

// export function* handleForgotPassword() {
//   while (true) {
//     try {
//       const { payload } = yield take(FORGOT_PASSWORD[REQUEST]);
//       const data = yield call(forgotPassword, payload);
//       yield put(authActions.forgotPassword.success(data));
//     } catch (error) {
//       const { code, message } = error;
//       yield put(authActions.forgotPassword.failure({ code, message }));
//       // antMessage.error(message, 5);
//     }
//   }
// }

// export function* handleChangePassword() {
//   while (true) {
//     try {
//       const { payload } = yield take(CHANGE_PASSWORD[REQUEST]);
//       const data = yield call(changePassword, payload);
//       yield put(authActions.changePassword.success(data));
//       // setTimeout(() => (window.location.href = '/dashboard'), 1000);
//     } catch (error) {
//       const { code, message } = error;
//       yield put(authActions.changePassword.failure({ code, message }));
//     }
//   }
// }