import ReactSelect from 'react-select';
import { FormControl, FormHelperText } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { InputLabel, OutlinedInput } from 'shared/Atoms';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    zIndex: 1000
  }),
  control: (provided, state) => ({
    ...provided,
    // color: state.selectProps.menuColor,
    height: '40px',
  }),

  singleValue: (provided, state) => {
    //   const opacity = state.isDisabled ? 0.5 : 1;
    //   const transition = 'opacity 300ms';

    return { ...provided, };
  }
}


const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '1.07em',
    backgroundColor: '#fff',

    '& input': {
      height: '40px',
      paddingTop: '0',
      paddingBottom: '0',


    },

  },
});



const Select = (props) => {
  const { label, name, makedStyles, rules, required, options, ...rest } = props;
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control: props.control,
    rules,
    defaultValue: props.defaultValue || '',
  });

  const styleClasses = useStyles({ isTouched });
  return (
    <FormControl className={clsx(styleClasses.formControl, makedStyles)}>
      {label && (
        <InputLabel shrink htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
      <ReactSelect
        isClearable={true}
        styles={customStyles}
        placeholder='Введите ...'
        value={options.find(c => c.value === value)}
        onChange={val => onChange(val?.value || null)}
        options={options}
        {...inputProps}
        {...rest} />
      {/* <OutlinedInput
        id={name}
        className={styleClasses.input}
        inputRef={ref}
        {...inputProps}
        {...rest}
      /> */}

      {/* <Select
        isClearable={true}
        inputRef={ref}
        classNamePrefix="addl-class"
        value={options.find(c => c.value === value)}
        onChange={val => onChange(val?.value || null)}
        options={options} />
      ) */}

      {errors?.[name] && (
        <FormHelperText error id={name}>
          {errors?.[name]?.message}
          {errors?.[name]?.type === "minLength" && `Must be at least ${rules.minLength} characters`}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default Select;