import styled from "styled-components";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authActions from 'modules/auth/data/actions';
import { ButtonLink, ButtonMainAccent, OutlinedInputField, Footer, CheckBoxItem } from 'shared/Molecules';
import { Link } from 'react-router-dom'

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;
`;

// const useStyles = makeStyles({
//   outerPositioning: {
//     marginBottom: '2em',
//   },
//   headerButtonLinkOuterPositioning: {
//     marginLeft: '1em',
//   },
//   formControl: {
//     display: 'block',
//     width: '400px',
//   },
// });


const Tasks = () => {

  // const classes = useStyles();
  const dispatch = useDispatch();
  // const history = useHistory();
  const { handleSubmit, control } = useForm();

  const onSubmit = data => {
    dispatch(authActions.signin.request(data));
  };

  return (
    <ContentWrapperBlock>
      {/* <FormControl
        className={classes.formControl}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      > */}
        <Typography className="my-20 align-items-center" style={{ textAlign: 'center' }} variant="h1">Настройки</Typography>
        <Link to="/settings/filetypes" className="text-black link">Управление типами файлов</Link>
        {/* <OutlinedInputField
          makedStyles={classes.outerPositioning}
          type="email"
          label="E-mail:"
          placeholder="Введите Ваш e-mail"
          name="login"
          control={control}
          rules={{
            required: 'E-mail обязателен',
            validate: value => validateEmail(value),
          }}
        />
        <OutlinedInputField
          makedStyles={classes.outerPositioning}
          type="password"
          label="Пароль:"
          placeholder="Введите Ваш пароль"
          name="password"
          control={control}
          rules={{
            required: 'Пароль обязателен',
            validate: value => true,
          }}
        />
        <Controller
        className="m-auto"
          name="rememberMe"
          control={control}
          render={({ field }) => (
            <CheckBoxItem  {...field} label="Запомнить меня" />
          )}
        />

        <ButtonMainAccent className="mt-20" fullWidth={true} type="submit" variant="contained">Войти в систему</ButtonMainAccent> */}
      {/* </FormControl> */}
    </ContentWrapperBlock>
  );

}

export default Tasks