import axios from 'axios';

import { USERS_BASE_URL } from 'constants/config';
import { apiWrapper, errorInterceptor, requestInterceptor } from './interceptors';

const request = axios.create({ baseURL: USERS_BASE_URL });
request.interceptors.response.use(null, errorInterceptor);
request.interceptors.request.use(requestInterceptor);

const api = {
  getClients: async (payload) => request.get(`/clients?page=${payload.page}&perPage=${payload.perPage}&order=${payload.order}&orderBy=${payload.orderBy}&search=${payload.search}`),
  getClient: async (payload) => request.get(`/clients/${payload.id}?page=${payload.page}&perPage=${payload.perPage}&order=${payload.order}&orderBy=${payload.orderBy}&filterStatuses=${payload.filterStatuses}&dateFromCreated=${payload.dateFromCreated}&dateToCreated=${payload.dateToCreated}&dateFromEnd=${payload.dateFromEnd}&dateToEnd=${payload.dateToEnd}`),
  postClient: async (payload) => request.post(`/clients`, payload),
  putClient: async (payload) => request.put(`/clients/${payload.id}`, payload),
};

export default apiWrapper(api);
