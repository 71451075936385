import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Icon, Typography } from 'shared/Atoms';
import { ButtonMainAccent, InputBaseFile, BorderLinearProgress, OutlinedInputField, ListItem } from 'shared/Molecules';
import tasksActions from 'modules/tasks/data/actions';

const StepTwo = ({ taskId, statusId, commentForAll, executorId, controllerId, executorName, controllerName, clientFileName, fileTypeName, readyComment, pages, dateTimeReady, ...props }) => {
	const dispatch = useDispatch();
	const { handleSubmit, control, watch, setValue, reset } = useForm();
	const { task } = useSelector(state => state.tasksReducer);

	const [progress, setProgress] = useState(0);
	const { uploadLoading } = useSelector(state => state.tasksReducer);

	const uploadReadyFile = (data) => {
		data.taskId = taskId;
		data.nextStatus = 6;
		console.log(data);
		setProgress(0);
		dispatch(tasksActions.uloadReadyFile.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))

		setValue('file', null)
		setValue('comment', null)
	}

	const uploadErrorFile = (data) => {
		if (!data.commentError && !data.fileError) {
			toast.error('Необходимо заполнить одно из полей!')
			return;
		}

		data.taskId = taskId;
		data.file = data.fileError;
		data.comment = data.commentError;
		console.log(data);
		setProgress(0);
		dispatch(tasksActions.uploadErrorFile.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))

		setValue('fileError', null)
		setValue('commentError', null)
	}


	const uploadSuccessFile = (data) => {
		data.taskId = taskId;
		data.file = data.fileSuccess;
		console.log(data);
		setProgress(0);
		dispatch(tasksActions.uploadSuccessFile.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))
	}

	const [openedTasks, setOpenedTasks] = useState({});

	const addOpenTask = (id, type) => {
		if (openedTasks[id] === type) {
			setOpenedTasks(prev => {
				let newO = { ...prev };
				delete newO[id];
				return newO
			})
		} else {
			setOpenedTasks(prev => {
				return { [id]: type }
			})
		}
	}
	const { user } = useSelector(state => state.authReducer);
	console.log(user.id , executorId, user.rolesId.includes(1), controllerId)

	return (
		<ListItem className="p-15" pointer={false}>
			{/* когда первы раз сдал  */}
			<Typography className='text-bold' fontSize='2'>
				{statusId === 4 && 'В работе (доработка)'}
				{statusId === 5 && 'В работе (доработка c прим. клиента)'}
				{statusId === 10 && 'Заверстка'}
				{(statusId === 3 || statusId === 7) && `На проверке у ${controllerName}`}
				{statusId === 6 && `На повторной проверке у ${controllerName}`}
			</Typography>
			{(statusId === 3 || statusId === 6) && (user.id === controllerId || user.rolesId.includes(1)) &&
				<>
					Скачайте файл и проверьте его
					<Divider className='my-15' />
					{readyComment !== null && readyComment !== '' &&
						<>
							<Typography className='text-bold text-danger'>Комментарий исполнителя:</Typography> {readyComment}
							<Divider className='my-15' />
						</>
					}
					{commentForAll !== null && commentForAll !== '' &&
						<>
							<Typography className='text-bold text-danger'>Комментарий возврата:</Typography> {commentForAll}
							<Divider className='my-15' />
						</>
					}
					<Grid container spacing={2}>
						<Grid item xs={6} >
							<ButtonMainAccent onClick={() => addOpenTask(1, 'error')} fullWidth={true} variant="contained" className="text-white bg-danger">
								Указать ошибки
							</ButtonMainAccent>
						</Grid>
						<Grid item xs={6} className='d-flex' alignItems='center'>
							<ButtonMainAccent onClick={() => addOpenTask(2, 'success')} fullWidth={true} variant="contained" className="text-white">
								На одобрение
							</ButtonMainAccent>
						</Grid>
						{/* раскрывющиеся блоки */}
						{Object.keys(openedTasks).includes('1') && openedTasks[1] === 'error' &&
							<>
								<Grid item xs={12}><Divider className='' /> </Grid>
								<Grid item xs={12} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
									Можете загрузить файл с ошибками для отправки в доработку
								</Grid>
								<Grid item xs={6} alignItems='center'>
									<FormControl component="form" onSubmit={handleSubmit(uploadErrorFile)} className="w-100 my-15 px-5">
										<InputBaseFile
											disabled={uploadLoading}
											id={'error'}
											key={'error'}
											control={control}
											name={"fileError"}
										/>
										<OutlinedInputField
											disabled={uploadLoading}
											makedStyles='my-15'
											type="number"
											label="Добавить комментарий"
											placeholder="Добавить комментарий"
											name={"commentError"}
											control={control}
											multiline
											minRows={5}
											defaultValue={''}
										/>
										{uploadLoading && <BorderLinearProgress className="mb-15" variant="determinate" value={progress} />}
										<ButtonMainAccent
											disabled={uploadLoading}
											loading={uploadLoading}
											type="submit"
											fullWidth={true}
											variant="contained"
											className="text-white bg-danger"
										>
											Вернуть в доработку
										</ButtonMainAccent>
									</FormControl>
								</Grid>
							</>
						}
						{Object.keys(openedTasks).includes('2') && openedTasks[2] === 'success' &&
							<>
								<Grid item xs={12}><Divider className='' /> </Grid>
								<Grid item xs={12} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
									Можете загрузить исправленный файл для одобрения
								</Grid>
								<Grid item xs={6} alignItems='center'>
									<FormControl component="form" onSubmit={handleSubmit(uploadSuccessFile)} className="w-100 my-15 px-5">
										<InputBaseFile
											id={'success'}
											key={'success'}
											control={control}
											name={"fileSuccess"}
											disabled={uploadLoading}
										/>
										{uploadLoading && <BorderLinearProgress className="mb-15" variant="determinate" value={progress} />}
										<ButtonMainAccent
											disabled={uploadLoading}
											type="submit"
											fullWidth={true}
											variant="contained"
											className="text-white mt-15"
										>
											Одобрить файл
										</ButtonMainAccent>
									</FormControl>
								</Grid>
							</>
						}
					</Grid>
				</>
			}

			{(statusId === 4 || statusId === 5 || statusId === 10) && (user.id === executorId || user.rolesId.includes(1)) &&
				<>
					{task.errorComment && <><Typography className='text-bold text-danger'>Комментарий контролера:</Typography> {task.errorComment}</>}
					{commentForAll !== null && commentForAll !== '' &&
						<>
							<Divider className='my-15' />
							<Typography className='text-bold text-danger'>Комментарий возврата:</Typography>
							 {commentForAll}

						</>
					}
					<Divider className='my-15' />
					<FormControl component="form" onSubmit={handleSubmit(uploadReadyFile)} className="w-100 my-15 px-5">
						<Grid container spacing={2}>
							<Grid item xs={12} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
								Загрузите файл для oтправки на проверку
							</Grid>
							<Grid item xs={6} alignItems='center'>
								<InputBaseFile
									id='executor'
									key='executor'
									control={control}
									name={"file"}
									rules={{ required: 'Поле обязательно к заполнению' }}
									disabled={uploadLoading}
								/>
								<OutlinedInputField
									disabled={uploadLoading}
									makedStyles='my-15'
									type="number"
									label="Добавить комментарий"
									placeholder="Добавить комментарий"
									name={"comment"}
									control={control}
									multiline
									minRows={5}
									defaultValue={''}
								/>
								{uploadLoading && <BorderLinearProgress className="mb-15" variant="determinate" value={progress} />}
								<ButtonMainAccent
									disabled={uploadLoading}
									loading={uploadLoading}
									type="submit"
									fullWidth={true}
									variant="contained"
									className="text-white"
								>
									Отправить на проверку
								</ButtonMainAccent>
							</Grid>
						</Grid>
					</FormControl>
				</>
			}
			{
				statusId === 7 &&
				<>
					<Divider className='my-15' />
					<div className="d-flex justify-content-center align-items-center semi-bold">
						<Icon name='check' fontSize='large' className='mr-10 text-primary' />
						Задание одобрено
					</div>
				</>
			}
		</ListItem>
	)
}

export default StepTwo
