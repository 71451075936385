import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl } from '@material-ui/core';
import { ButtonMainAccent,  ReactSelect} from 'shared/Molecules';

import tasksActions from 'modules/tasks/data/actions';
import usersActions from 'modules/users/data/actions';

const Task = ({ type, taskId, controllerId, executorId, handleClosePopover, ...props }) => {
	const dispatch = useDispatch();
	const { handleSubmit, control } = useForm();

	const { executors, controllers } = useSelector(state => state.usersReducer);

	useEffect(() => {
		dispatch(usersActions.getExecutors.request())
		dispatch(usersActions.getControllers.request())
	}, [])

	const onSubmit = data => {
		console.log(data)
		data.type = type;
		data.taskId = taskId;
		handleClosePopover();
		dispatch(tasksActions.changeUser.request(data))
	}

	let options = type === 'executor' ? executors?.map(({ userId, userName }) => { return { value: userId, label: userName } }) : controllers?.map(({ userId, userName }) => { return { value: userId, label: userName } })

	let userId = type === 'executor' ? executorId : controllerId;

	return (
		<FormControl component="form" onSubmit={handleSubmit(onSubmit)} className=" px-5 p-10" style={{ width: '250px', }}>
			<ReactSelect
				isClearable={true}
				name="userId"
				control={control}
				options={options}
				rules={{ required: 'Поле обязательно к заполнению' }}
				defaultValue={options.filter(c => c.value === +userId)[0]}
			/>
			<ButtonMainAccent
				type="submit"
				variant="contained"
				className="mt-15"
				fullWidth={true}
			>
				Назначить
			</ButtonMainAccent>
		</FormControl>
	);
}

export default Task
