import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Logo from 'assets/images/logo.png';
import notifActions from 'modules/notifications/data/actions';
import { Badge } from '@material-ui/core';

const Wrapper = styled.div`
  .user {
    font-weight: bold;
    text-align: center;
  }
  .roles {
    font-size: 11px;
    color: #ccc;
    font-weight: normal;
  }
`;

const Notifications = styled(Link)`
  // padding: 15px;
  background-color: #e1e1e1;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

const MainMenu = () => {
  const { user } = useSelector(state => state.authReducer)

  const dispatch = useDispatch();

  useEffect(() => {
    let func = () => dispatch(notifActions.getNotifs.request())
    func()
    let intervalId = setInterval(func, 60000)
    return () => clearInterval(intervalId);
  }, [])

  const { notifs } = useSelector(state => state.notifsReducer)

const length = notifs?.filter(({status}) => status === 1).length
  return (
    <Wrapper className="d-flex  align-items-center justify-content-space-between w-100 p-5">
      <img src={Logo} alt="logo" height="50" />
      <div className="d-flex  align-items-center">
        <Notifications to="/notifications">
          <Badge color='error' badgeContent={length} max={999} invisible={length === 0 ? true : false} >
            <NotificationsIcon />
          </Badge>
        </Notifications>
        <div className="user">
          {user?.surname} {user?.name?.[0]}. {user?.patronymic?.[0]}. <br />
          <span className="roles">
            {user?.roles?.map(({ label }) => label + ' ')}
          </span>
        </div>
      </div>

    </Wrapper>
  );
};

export default MainMenu;
