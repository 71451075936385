import React from "react";
import styled from "styled-components";

const ContainerDiv = styled.div`
  background-color: #43487a;
  width: 100%;
  text-align: center;
  padding-top: 126px;
  height: calc(100vh - 58px);

  h1 {
    color: #e6e6e6;
  }
  p {
    color: #d2d2d2ef;
    font-size: 18px;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    if (
      this.state.error === null &&
      window.localStorage.chunkReloaded === undefined &&
      error.request &&
      error.request.indexOf("chunk") > -1
    ) {
      window.localStorage.chunkReloaded = true;
      window.location.reload();
    } else {
      window.localStorage.removeItem("chunkReloaded");
    }
    this.setState({
      hasError: true,
      error,
      info,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContainerDiv>
          <h1>Упсс, что-то пошло не так :(</h1>
          <p>Попробуйте обновить страницу.</p>
        </ContainerDiv>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
