import { useState } from 'react';
import styled from "styled-components";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormControl, Grid, IconButton, Divider } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup as MToggleButtonGroup } from '@material-ui/lab';
import { toast } from 'react-toastify';

import { Icon, Typography } from 'shared/Atoms';
import { ButtonMainAccent, OutlinedInputField, InputBaseFile, BorderLinearProgress, ListItem, NativeDatePicker } from 'shared/Molecules';
import commonServices from 'services/common';
import tasksActions from 'modules/tasks/data/actions';
import { checkForbiddenAccess } from 'utils/helperFunctions';

const ToggleButtonGroup = styled(MToggleButtonGroup)`
	.Mui-selected {
		background: #8c6fff;
		color: white;
	}

	.Mui-selected.green {
		background: green;
	}
	.Mui-selected.red {
		background: red;
	}
`;

const ReadyItem = ({ id, clientFileName, projectId, serverFileName, clientReadyFileName, serverReadyFileName, pages, fileTypeName, statusId, statusName, dateTimeReady, executorName, dateTimeEnd, pass, pagesTypes }) => {
	const [openReturn, setOpenReturn] = useState(false);
	const [nextStatus, setNextStatus] = useState(5);

	const dispatch = useDispatch();
	const { handleSubmit, control, watch, setValue, reset } = useForm();

	const changePass = (event, value) => {
		dispatch(tasksActions.changePass.request({ taskId: id, pass: value }))
	}

	const [progress, setProgress] = useState(0);
	const { uploadLoading } = useSelector(state => state.tasksReducer);

	const uploadErrorFile = (data) => {
		if (!data.comment && !data.file) {
			toast.error('Необходимо заполнить одно из полей (файл / комментарий)!')
			return;
		}
		data.taskId = id;
		data.nextStatus = nextStatus;
		setProgress(0);
		dispatch(tasksActions.uploadErrorFile.request(data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))

		setValue('file', null)
		setValue('comment', null)
	}

	return (
		<ListItem key={serverFileName} className='mt-15 px-15 py-10' pointer={false}>
			<Grid container spacing={2} alignItems='center' >
				<Grid item xs={5}>
					<div className="d-flex-column  justify-content-center h-100">
						<span className="semi-bold">{projectId}_{clientFileName}</span>
						{pagesTypes.map(({ id, fileTypeName, pages }) => {
							return (
								<div className="mt-5">
									{fileTypeName} {' '} {pages} стр.
								</div>
							)
						})}
					</div>
				</Grid>
				<Grid item xs={3} className='d-flex' alignItems='center' >
					<ToggleButtonGroup
						value={pass}
						exclusive
						onChange={changePass}
						aria-label="text alignment"
					>
						<ToggleButton title="Сдано" value={1} aria-label="left aligned" style={{ padding: '2px' }} className="green">
							<Icon name='done' fontSize='medium' />
						</ToggleButton>
						<ToggleButton title="Не сдано" value={0} aria-label="centered" style={{ padding: '2px' }} className="red">
							<Icon name='close' fontSize='medium' />
						</ToggleButton>
					</ToggleButtonGroup>

					<IconButton title='Вернуть в доработку' onClick={() => setOpenReturn(prev => !prev)}>
						<Icon name='redo' className='text-primary' fontSize='medium' />
					</IconButton>

					<IconButton title='Скачать готовый файл' onClick={() => commonServices.downloadFile(serverReadyFileName, clientReadyFileName)}>
						<Icon name='cloudDownload' className='text-primary' fontSize='medium' />
					</IconButton>
					{!checkForbiddenAccess([3]) &&
						<Link to={'/tasks/' + id}>
							<IconButton title='Перейти к заданию'>
								<Icon name='doubleArrow' className='text-primary' fontSize='medium' />
							</IconButton>
						</Link>
					}
				</Grid>
				<Grid item xs={2}>
					<div className="">
						<Typography className="text-gray" fontSize="1">Исполнитель</Typography>
						<Typography className="text-bold" fontSize="1.1">{executorName}</Typography>
					</div>
				</Grid>
				<Grid item xs={2}>
					<div className="">
						<Typography className="text-gray" fontSize="1">Выполнено</Typography>
						<Typography className="text-bold" fontSize="1.1">{dateTimeReady}</Typography>
					</div>
				</Grid>

				{openReturn &&
					<>
						<Grid item xs={12}><Divider className='' /> </Grid>
						<Grid item xs={12} className='d-flex text-bold' style={{ fontSize: '16px' }} alignItems='center' justifyContent='center'>
							Можете загрузить файл с ошибками для отправки в доработку
						</Grid>
						<Grid item xs={6} alignItems='center'>
							<FormControl component="form" onSubmit={handleSubmit(uploadErrorFile)} className="w-100 my-15 px-5">
								<Typography fontSize='1.4' className='mb-15 text-gray'>
									Вы можете вернуть задание на доработку с примечаниями от клиента. Для этого загрузите файл от клиента (скриншот, файл с ошибкам и т.п.) и/или укажите текстовый комментарий.
								</Typography>

								<ToggleButtonGroup
									value={nextStatus}
									exclusive
									onChange={(event, value) => setNextStatus(prev => value === 5 ? 5 : 10)}
									aria-label="text alignmentdef"
									className="mb-10"
								>
									<ToggleButton value={5} aria-label="left aligned" className="w-100 semi-bold" >
										Доработка от клиента
									</ToggleButton>
									<ToggleButton value={10} aria-label="centered" className="w-100  semi-bold">
										Заверстка
									</ToggleButton>
								</ToggleButtonGroup>
								<Grid container spacing={2} alignItems='center' className="mb-10" >
									<Grid item xs={6}>
										<NativeDatePicker
											name="date"
											type="date"
											control={control}
											rules={{ required: 'Поле обязательно к заполнению' }}
											disabled={uploadLoading}
										/></Grid>
									<Grid item xs={6}>
										<NativeDatePicker
											name="time"
											type="time"
											control={control}
											rules={{ required: 'Поле обязательно к заполнению' }}
											disabled={uploadLoading}
										/>
									</Grid>
								</Grid>
								<InputBaseFile
									control={control}
									disabled={uploadLoading}
									name={"file"}
								/>
								<OutlinedInputField
									makedStyles='my-15'
									type="number"
									label="Добавить комментарий"
									placeholder="Добавить комментарий"
									name={"comment"}
									control={control}
									multiline
									minRows={5}
									defaultValue={''}
									disabled={uploadLoading}
								/>
								{uploadLoading && <BorderLinearProgress className="mt-15" variant="determinate" value={progress} />}
								<ButtonMainAccent
									loading={uploadLoading}
									disabled={uploadLoading}
									type="submit"
									fullWidth={true}
									variant="contained"
									className="text-white bg-danger">
									Вернуть
								</ButtonMainAccent>
							</FormControl>
						</Grid>
					</>
				}
			</Grid>
		</ListItem>
	)
}

export default ReadyItem
