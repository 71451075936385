import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import tasksActions, {
    GET_TASKS,
    GET_TASK,
    SET_EXECUTOR,
    DEVIDE_FILE,
    GET_TASKS_STATUSES,
    UPLOAD_READY_FILE,
    CHANGE_USER,
    UPLOAD_ERROR_FILE,
    UPLOAD_SUCCESS_FILE,
    CHANGE_PASS,
    CLAER_TASK,
} from 'modules/tasks/data/actions';
import { REQUEST } from 'modules/common/actions';

import tasksServices from 'services/tasks';
import { toast } from 'react-toastify';

import projectsActions from 'modules/projects/data/actions';

export function* handleGetTasks({ payload }) {
    try {
        const data = yield call(tasksServices.getTasks, payload);
        yield put(tasksActions.getTasks.success(data));
    } catch (error) {
        yield put(tasksActions.getTasks.failure(error));
        yield call(toast.error, 'Ошибка получения задач!')
    }
}

export function* handleGetTask({ payload }) {
    try {
        const data = yield call(tasksServices.getTask, payload);
        yield put(tasksActions.getTask.success(data));
    } catch (error) {
        yield put(tasksActions.getTask.failure(error));
        yield call(toast.error, 'Ошибка получения задачи!')
    }
}

export function* handleSetExecutor({ payload }) {
    try {
        const data = yield call(tasksServices.setExecutor, payload);
        yield put(tasksActions.setExecutor.success(data));
        const { location } = yield select(state => state.router);
        yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.setExecutor.failure(error));
        yield call(toast.error, 'Ошибка назначения исполнителя!')
    }
}

export function* handleDevideFile({ payload, callback }) {
    try {
        let formData = new FormData();

        for (let i = 0; i < payload.count; i++) {
            formData.append("file" + i, payload["file" + i]);
            // formData.append("pages" + i, +payload["pages" + i]);
            let pagesTipes = [];
            let countPagesTypes = payload['countPagesTypes' + i];
            for (let q = 0; q < countPagesTypes; q++) {

                let typeId = +payload['fileType' + i + '_' + q];
                let pages = +payload['pages' + i + '_' + q];
                let price = +payload['price' + i + '_' + q];

                pagesTipes.push({ typeId, pages, price })
            }
            formData.append('data' + i, JSON.stringify({
                countPagesTypes: countPagesTypes,
                pagesTypes: pagesTipes,
            }));
        }


        // formData.append('taskId', payload['taskId']);
        formData.append('count', payload['count']);

        console.log(formData)
        const data = yield call(tasksServices.devideFile, formData, payload['taskId'], callback);
        yield put(tasksActions.devideFile.success(data));
        yield call(toast.success, 'Файл успешно разделен!')
        const { location } = yield select(state => state.router);
        yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.devideFile.failure(error));
        yield call(toast.error, 'Ошибка разделения файла!')
    }
}


export function* handleGetTasksStatuses() {
    try {
        const data = yield call(tasksServices.getTasksStatuses);
        yield put(tasksActions.getTasksStatuses.success(data));
    } catch (error) {
        yield put(tasksActions.getTasksStatuses.failure(error));
        yield call(toast.error, 'Ошибка статусов заданий!')
    }
}


export function* handleUploadReadyFile({ payload, callback }) {
    try {
        let formData = new FormData();

        formData.append("file", payload["file"]);
        formData.append("comment", payload["comment"] || '');
        formData.append("nextStatus", payload["nextStatus"] || 3);

        console.log(formData)

        const data = yield call(tasksServices.uloadReadyFile, formData, payload['taskId'], callback);
        yield put(tasksActions.uloadReadyFile.success(data));
        yield call(toast.success, 'Файл успешно загружен на проверку!')

        const { location } = yield select(state => state.router);
        yield put(tasksActions.getTask.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.uloadReadyFile.failure(error));
        yield call(toast.error, 'Ошибка загрузки файла!')
    }
}


export function* handleChangeUser({ payload }) {
    try {
        const data = yield call(tasksServices.changeUser, payload);
        yield put(tasksActions.changeUser.success(data));
        yield call(toast.success, 'Изменение прошло успешно!')

        const { location } = yield select(state => state.router);
        yield put(tasksActions.getTask.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.changeUser.failure(error));
        yield call(toast.error, 'Ошибка изменения параметров задания!')
    }
}

export function* handleUploadErrorFile({ payload, callback }) {
    try {
        let formData = new FormData();

        formData.append("file", payload["file"] || 0);
        formData.append("comment", payload["comment"] || '');
        formData.append("nextStatus", payload["nextStatus"] || 4);
        formData.append("date", payload["date"] || '');
        formData.append("time", payload["time"] || '');

        console.log(formData)

        const data = yield call(tasksServices.uploadErrorFile, formData, payload['taskId'], callback);
        yield put(tasksActions.uploadErrorFile.success(data));
        yield call(toast.success, 'Операция успешна!')

        const { location } = yield select(state => state.router);
        if (location.pathname.split('/')[1] === 'tasks') {
            yield put(tasksActions.getTask.request({ id: location.pathname.split('/')[2] }))
        } else {
            yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
        }

    } catch (error) {
        yield put(tasksActions.uploadErrorFile.failure(error));
        yield call(toast.error, 'Ошибка загрузки файла!')
    }
}

export function* handleUploadSuccessFile({ payload, callback }) {
    try {
        let formData = new FormData();

        formData.append("file", payload["file"]);

        console.log(formData)

        const data = yield call(tasksServices.uploadSuccessFile, formData, payload['taskId'], callback);
        yield put(tasksActions.uploadSuccessFile.success(data));
        yield call(toast.success, 'Файл успешно загружен!')

        const { location } = yield select(state => state.router);
        yield put(tasksActions.getTask.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.uploadSuccessFile.failure(error));
        yield call(toast.error, 'Ошибка загрузки файла!')
    }
}

export function* handleChangePass({ payload }) {
    try {
        const data = yield call(tasksServices.changePass, payload);
        yield put(tasksActions.changePass.success(data));
        const { location } = yield select(state => state.router);
        yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.changePass.failure(error));
        yield call(toast.error, 'Ошибка изменения!')
    }
}

export function* handleClearTask({ payload }) {
    try {
        const data = yield call(tasksServices.clearTask, payload);
        yield put(tasksActions.clearTask.success(data));

        const { location } = yield select(state => state.router);
        yield put(tasksActions.getTask.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        yield put(tasksActions.clearTask.failure(error));
        yield call(toast.error, 'Ошибка изменения!')
    }
}

export default function* tasksWatcher() {
    yield takeLatest(GET_TASKS[REQUEST], handleGetTasks);
    yield takeLatest(GET_TASK[REQUEST], handleGetTask);
    yield takeLatest(SET_EXECUTOR[REQUEST], handleSetExecutor);
    yield takeLatest(DEVIDE_FILE[REQUEST], handleDevideFile);
    yield takeLatest(GET_TASKS_STATUSES[REQUEST], handleGetTasksStatuses);
    yield takeLatest(UPLOAD_READY_FILE[REQUEST], handleUploadReadyFile);
    yield takeLatest(CHANGE_USER[REQUEST], handleChangeUser);
    yield takeLatest(UPLOAD_ERROR_FILE[REQUEST], handleUploadErrorFile);
    yield takeLatest(UPLOAD_SUCCESS_FILE[REQUEST], handleUploadSuccessFile);
    yield takeLatest(CHANGE_PASS[REQUEST], handleChangePass);
    yield takeLatest(CLAER_TASK[REQUEST], handleClearTask);
}
