import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import notifsActions, { GET_NOTIFS, READ_ONE, DELETE_ALL_NOTIFS, READ_ALL } from 'modules/notifications/data/actions';
import { REQUEST } from 'modules/common/actions';

import notifServices from 'services/notif';
import { toast } from 'react-toastify'

export function* handleGetNotifs({ payload }) {
    try {
        const data = yield call(notifServices.getNotifs, payload);
        yield put(notifsActions.getNotifs.success(data));
        data.data.forEach((item) => {
            if(item.getted === 0) {
                toast.info(item.msg + ' ' + item.dateTime)
            }
        })
    } catch (error) {
        yield put(notifsActions.getNotifs.failure(error));
        yield call(toast.error, 'Ошибка получения уведомлений!')
    }
}

export function* handleReadOne({ payload }) {
    try {
        const data = yield call(notifServices.readOne, payload);
        yield put(notifsActions.readOne.success(data));
        yield put(notifsActions.getNotifs.request());
    } catch (error) {
        yield put(notifsActions.readOne.failure(error));
        yield call(toast.error, 'Ошибка чтения одного уведомления!')
    }
}

export function* handleReadAll({ payload }) {
    try {
        const data = yield call(notifServices.readAll, payload);
        yield put(notifsActions.readAll.success(data));
        yield put(notifsActions.getNotifs.request());
    } catch (error) {
        yield put(notifsActions.readAll.failure(error));
        yield call(toast.error, 'Ошибка чтения всех уведомлений!')
    }
}

export function* handleDeleteAllNotifs({ payload }) {
    try {
        const data = yield call(notifServices.deleteAllNotifs, payload);
        yield put(notifsActions.deleteAllNotifs.success(data));
        yield put(notifsActions.getNotifs.request());
    } catch (error) {
        yield put(notifsActions.deleteAllNotifs.failure(error));
        yield call(toast.error, 'Ошибка удаления уведомлений!')
    }
}

export default function* projectsWatcher() {
    yield takeLatest(GET_NOTIFS[REQUEST], handleGetNotifs);
    yield takeLatest(READ_ONE[REQUEST], handleReadOne);
    yield takeLatest(DELETE_ALL_NOTIFS[REQUEST], handleDeleteAllNotifs);
    yield takeLatest(READ_ALL[REQUEST], handleReadAll);
}
