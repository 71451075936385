import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import reportsActions, { GET_PROJECT_EXCEL_REPORT } from 'modules/reports/data/actions';
import { REQUEST } from 'modules/common/actions';
import { signup, signin, signout, forgotPassword, changePassword, getNextAuthRouteAfterSignIn } from 'services/auth';
import { unSetSessionCookies, setSessionCookies } from 'modules/common/utils';

// import { routes } from 'modules/auth/constants';

// import usersActions from 'modules/user/data/actions';
// import providerActions from 'modules/provider/data/actions';
import reportsServices from 'services/reports';
// import { MEDICAL_RECORD_REQUEST_STATUS } from 'modules/dashboard/constants';

import { toast } from 'react-toastify'

export function* getProjectExcelReport({ payload }) {
    try {
        const data = yield call(reportsServices.getProjectExcelReport, payload);
        yield put(reportsActions.getProjectExcelReport.success(data));
        yield call(reportsServices.downloadReport, {fileName: data.data, fileType: 'xlsx'});
         
        // yield put(settingsActions.getFileTypes.request());
        // yield call(callback)
        // yield call(toast.success, 'Редактирования типа файла успешно!')
    } catch (error) {
        yield put(reportsActions.getProjectExcelReport.failure(error));
        yield call(toast.error, 'Ошибка получения отчета!')
    }
}

export default function* reportsWatcher() {
    yield takeLatest(GET_PROJECT_EXCEL_REPORT[REQUEST], getProjectExcelReport);
}

