import { GET_PROJECT_EXCEL_REPORT } from './actions';

const initialState = {
  projectExcelReportLoading: false,
  projectExcelReportError: false,
};

function reportsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROJECT_EXCEL_REPORT.REQUEST: return { ...state, projectExcelReportLoading: true, projectExcelReportError: false, };
    case GET_PROJECT_EXCEL_REPORT.SUCCESS: return { ...state, projectExcelReportLoading: false, projectExcelReportError: false, };
    case GET_PROJECT_EXCEL_REPORT.FAILURE: return { ...state, projectExcelReportLoading: false, projectExcelReportError: payload.message, };

    default:
      return state;
  }
}

export default reportsReducer;
