import moment from 'moment';
import reduce from 'lodash/reduce';
import intersection from 'lodash/intersection';
import { DATE_FORMAT } from 'shared/constants';
import { store } from 'core/app';

export const replaceKeysByMapper = (obj, mapper, placeholderKey = '') =>
  reduce(
    obj,
    (result, value, key) => {
      const newKey = mapper[key] || placeholderKey;
      result[newKey] = value;
      return result;
    },
    {}
  );

export const formatDate = dateObj => moment(dateObj).format(DATE_FORMAT);

export const checkForbiddenAccess = (forbiddenFor) => {
  const rolesId = store.getState().authReducer?.user?.rolesId;
  if (forbiddenFor.length === 0) {
    return false
  }
  let allowRoles = rolesId?.filter(value => {
    // console.log(forbiddenFor.includes(value))
    return !forbiddenFor.includes(value)
  })
  // console.log(forbiddenFor, rolesId);
  // console.log(allowRoles);
  return allowRoles?.length > 0 ? false : true;
}

export const checkAvailableAccess = (availableFor) => {
  const rolesId = store.getState().authReducer?.user?.rolesId;
  // console.log(rolesId)
  if(rolesId?.includes(1) || rolesId?.includes(5)) return true;
  if (availableFor.length === 0) return false;
  
  let allowRoles = rolesId?.filter(value => {
    // console.log(forbiddenFor.includes(value))
    return availableFor.includes(value)
  })
  // console.log(forbiddenFor, rolesId);
  // console.log(allowRoles);
  return allowRoles?.length > 0 ? true : false;
}