import { FormControl, FormHelperText } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { InputLabel, OutlinedInput } from 'shared/Atoms';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '1.07em',
    backgroundColor: '#fff',

    '& input': {
      height: '40px',
      paddingTop: '0',
      paddingBottom: '0',

     
    },

  },
});

const OutlinedInputField = props => {
  const { label, name, makedStyles, rules, control, required, inputProps: inputPropsOuter, ...rest } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue || '',
  });


  useEffect(() => {
    const handleWheel = e => e.preventDefault();
    ref?.current?.addEventListener("wheel", handleWheel);

    return () => {
      ref?.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const styleClasses = useStyles({ isTouched });
// console.log(inputPropsOuter)
  return (
    <FormControl className={clsx(styleClasses.formControl, makedStyles)}>
      {label && (
        <InputLabel shrink htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={name}
        className={styleClasses.input}
        inputRef={ref}
        inputProps={{...inputPropsOuter, onWheel: event => event.target.blur()  }}
       
        {...inputProps}
        {...rest}
      />
      {errors?.[name] && (
        <FormHelperText error id={name}>
          {errors?.[name]?.message}
          {errors?.[name]?.type === "minLength" && `Must be at least ${rules.minLength} characters`}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default OutlinedInputField;
