import { createRequestTypes, action } from '../../common/actions';

export const GET_CLIENTS = createRequestTypes('GET_CLIENTS');
export const GET_CLIENT = createRequestTypes('GET_CLIENT');
export const PUT_CLIENT = createRequestTypes('PUT_CLIENT');
export const POST_CLIENT = createRequestTypes('POST_CLIENT');

const clientsActions = {
  getClients: {
    request: (data) => action(GET_CLIENTS.REQUEST, { payload: data }),
    success: (data) => action(GET_CLIENTS.SUCCESS, { payload: data }),
    failure: (error) => action(GET_CLIENTS.FAILURE, { payload: error }),
  },

  getClient: {
    request: (data) => action(GET_CLIENT.REQUEST, { payload: data }),
    success: (data) => action(GET_CLIENT.SUCCESS, { payload: data }),
    failure: (error) => action(GET_CLIENT.FAILURE, { payload: error }),
  },

  putClient: {
    request: (data) => action(PUT_CLIENT.REQUEST, { payload: data }),
    success: (data) => action(PUT_CLIENT.SUCCESS, { payload: data }),
    failure: (error) => action(PUT_CLIENT.FAILURE, { payload: error }),
  },

  postClient: {
    request: (data, callback) => action(POST_CLIENT.REQUEST, { payload: data, callback }),
    success: (data) => action(POST_CLIENT.SUCCESS, { payload: data }),
    failure: (error) => action(POST_CLIENT.FAILURE, { payload: error }),
  },
};

export default clientsActions;
