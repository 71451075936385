import styled from 'styled-components';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete  } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { useController } from 'react-hook-form';
import clsx from 'clsx';

import { InputLabel, OutlinedInput } from 'shared/Atoms';
import { Select } from 'shared/Molecules';

const StyledFormControl = styled(FormControl)`
  width: 100%;

  .helper-text {
    color: red;
    font-size: 1em;
    margin: 5px 0 0 0;
  }
`;

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  input: {
    width: '100%',
    borderRadius: '4px',
    boxSizing: 'border-box',
    fontSize: '1.07em',
    backgroundColor: '#fff',
    height: '48px',

    '& input': {
      height: '0.7em',
      paddingLeft: '1em',
      color: '#000',
      lineHeight: 1,
      '&::placeholder': {
        color: '#8f9bb3',
        opacity: 1,
      },
    },
    
    '& input + fieldset': {
      borderColor: '#e4e9f2',
      backgroundColor: props => (props.isTouched ? 'transparent' : '#cccccc18'),
    },
  },
  formHelperText: {
    color: 'red',
    fontSize: '1em',
    margin: '5px 0  0 0',
  },
});
const AutocompleteSelectField = props => {
  // const { options, label, name, makedStyles, control, placeholder, ...rest } =
  //   props;
  // const {
  //   field: { ref, value, type, ...inputProps },
  //   fieldState: { invalid, isTouched, isDirty },
  //   formState: { errors, touchedFields, dirtyFields },
  // } = useController({
  //   name,
  //   control,
  //   rules: props.rules,
  //   defaultValue: props.defaultValue && "",
  // });

  const { options, label, name, makedStyles, control, required, ...rest } = props;
  const {
    field: { ref, value, type, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: props.rules,
    defaultValue: props.defaultValue || '',
  });

  const styleClasses = useStyles();

  return (
    <StyledFormControl className={clsx(makedStyles)}>
      {label && (
        <InputLabel shrink htmlFor={name} required={required}>
          {label}
        </InputLabel>
      )}
    
      <Autocomplete
        isTouched={isTouched}
        value={value}
        name={name}
        options={options?.map(({value, label}) => `${value} - ${label}` )}
        noOptionsText="Нет вариантов"
        clearText="Очистить"
        closeText="Закрыть"
        onChange={(event, value) => onChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            className={styleClasses.input}
            variant="outlined"
            {...rest}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        {...inputProps}
        
      />
      {errors?.[name] && (
        <FormHelperText className={'helper-text'} id={name}>
          {errors?.[name]?.message}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default AutocompleteSelectField;
