import MInputLabel from '@material-ui/core/InputLabel';

import styeld from 'styled-components';

const StyledMInputLabel = styeld(MInputLabel)`
    font-size: ${({ fontSize }) => fontSize}rem !important;
    font-weight: 600 !important;
    position:relative !important;
    margin-bottom:5px;
    line-height: 1.4em;
    transform: scale(1);
    color: #222b45;
    .help-text{
        font-weight: 100 !important;
    }
`;

const InputLabel = ({
  children,
  helpText,
  fontSize = 0.815,
  colorVariant = 'black',
  ...props
}) => (
  <StyledMInputLabel {...props} fontSize={fontSize}> 
    {children} <span className="help-text">{helpText}</span>
  </StyledMInputLabel>
);

export default InputLabel;
