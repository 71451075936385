import { call, take, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import projectsActions, { UPLOAD_FILES, GET_PROJECTS, GET_PROJECT, GET_PROJECTS_STATUSES, SET_PROJECT_STATUS } from 'modules/projects/data/actions';
import { REQUEST } from 'modules/common/actions';

import projectsServices from 'services/projects';
import { toast } from 'react-toastify'

export function* handleUploadFiles({ files, payload, callback }) {
    try {
        let formData = new FormData();
        let keys = [];
        console.log(files, payload)

        // Object.values(files).forEach(obj => {
        //     keys.push(obj.id)
        //     formData.append("file" + obj.id, obj.file);
        //     formData.append(obj.id, JSON.stringify({
        //         pages: payload['pages' + obj.id],
        //         fileType: payload['fileType' + obj.id],
        //         comment: payload['comment' + obj.id],
        //         price: payload['price' + obj.id],
        //         extraPrice: payload['extraPrice' + obj.id] || 0,
        //     }));

        // });

        Object.values(files).forEach(obj => {
            keys.push(obj.id)
            formData.append("file" + obj.id, obj.file);

            let pagesTipes = [];
            let countPagesTypes = payload['countPagesTypes' + obj.id];
            for (let q = 0; q < countPagesTypes; q++) {

                let typeId = +payload['fileType' + obj.id + '_' + q];
                let pages = +payload['pages' + obj.id + '_' + q];
                let price = +payload['price' + obj.id + '_' + q];

                pagesTipes.push({ typeId, pages, price })
            }
            formData.append(obj.id, JSON.stringify({
                countPagesTypes: countPagesTypes,
                pagesTypes: pagesTipes,
                // pages: payload['pages' + obj.id],
                // fileType: payload['fileType' + obj.id],
                comment: payload['comment' + obj.id],
                // price: payload['price' + obj.id],
                extraPrice: payload['extraPrice' + obj.id] || 0,
            }));

        });
        // const countPagesTypes = watch("countPagesTypes" + fileId);

        formData.append('clientId', payload['clientId']);
        formData.append('number', payload['number']);
        formData.append('date', payload['date']);
        formData.append('time', payload['time']);
        formData.append('keys', JSON.stringify(keys));

        console.log(formData)

        const { data } = yield call(projectsServices.uploadFiles, formData, callback);
        yield put(projectsActions.uploadFiles.success(data));
        yield call(toast.success, 'Проект успешно создан!')
        console.log(data)
        yield put(push('/projects/' + data))
    } catch (error) {
        yield put(projectsActions.uploadFiles.failure(error));
        yield call(toast.error, 'Ошибка создания проекта!')
    }
}

export function* handleGetProjects({ payload }) {
    try {
        const data = yield call(projectsServices.getProjects, payload);
        yield put(projectsActions.getProjects.success(data));
    } catch (error) {
        yield put(projectsActions.getProjects.failure(error));
        yield call(toast.error, 'Ошибка получения проектов!')
    }
}

export function* handleGetProject({ payload }) {
    try {
        const data = yield call(projectsServices.getProject, payload);
        yield put(projectsActions.getProject.success(data));
    } catch (error) {
        yield put(projectsActions.getProject.failure(error));
        yield call(toast.error, 'Ошибка получения проектов!')
    }
}

export function* handleGetProjectsStatuses() {
    try {
        const data = yield call(projectsServices.getProjectsStatuses);
        yield put(projectsActions.getProjectsStatuses.success(data));
    } catch (error) {
        yield put(projectsActions.getProjectsStatuses.failure(error));
        yield call(toast.error, 'Ошибка получения статусов проектов!')
    }
}

export function* handleSetProjectStatus({ payload }) {
    try {
        const data = yield call(projectsServices.setProjectStatus, payload);
        yield put(projectsActions.setProjectStatus.success());
        const { location } = yield select(state => state.router);
        yield put(projectsActions.getProject.request({ id: location.pathname.split('/')[2] }))
    } catch (error) {
        console.log(error)
        yield put(projectsActions.setProjectStatus.failure());
        yield call(toast.error, 'Ошибка изменения статуса проекта!')
    }
}


export default function* projectsWatcher() {
    yield takeLatest(UPLOAD_FILES[REQUEST], handleUploadFiles);
    yield takeLatest(GET_PROJECTS[REQUEST], handleGetProjects);
    yield takeLatest(GET_PROJECT[REQUEST], handleGetProject);
    yield takeLatest(GET_PROJECTS_STATUSES[REQUEST], handleGetProjectsStatuses);
    yield takeLatest(SET_PROJECT_STATUS[REQUEST], handleSetProjectStatus);
}

