import { Grid, IconButton } from '@material-ui/core';
import { Icon, Typography } from 'shared/Atoms';
import { ListItem } from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { checkForbiddenAccess } from 'utils/helperFunctions';

const ReadyItem = ({ id, clientFileName, projectId, serverFileName, pages, fileTypeName, statusId, statusName, dateTimeReady, controllerName, executorName, dateTimeEnd, pagesTypes, pass }) => {
	return (
		<ListItem key={serverFileName} className='mt-15 px-15 py-10' pointer={false}>
			<Grid container spacing={2} alignItems='center' >
				<Grid item xs={5}>
					<div className="d-flex-column  justify-content-center h-100">
						<span className="semi-bold">{projectId}_{clientFileName}</span>
						{pagesTypes.map(({id, fileTypeName, pages}) => {
							return (
								<div className="mt-5">
								{fileTypeName} {' '} {pages} стр.
							</div>
							)
						})}
						{(statusId === 4 || statusId === 5 || statusId === 6 || statusId === 10) &&
							<span className="semi-bold text-gray">{statusName}</span>}
					</div>
				</Grid>
				<Grid item xs={1}>
					{!checkForbiddenAccess([3]) &&
						<Link to={'/tasks/' + id}>
							<IconButton title='Перейти к заданию'>
								<Icon name='doubleArrow' className='text-primary' fontSize='medium' />
							</IconButton>
						</Link>
					}
				</Grid>
				<Grid item xs={2}>
					<div className="">
						<Typography className="text-gray" fontSize="1">Исполнитель</Typography>
						<Typography className="text-bold" fontSize="1.1">{executorName}</Typography>
					</div>
				</Grid>
				{controllerName &&
					<Grid item xs={2}>
						<div className="">
							<Typography className="text-gray" fontSize="1">Контролер</Typography>
							<Typography className="text-bold" fontSize="1.1">{controllerName}</Typography>
						</div>
					</Grid>
				}
				<Grid item xs={2}>
					<div className="">
						<Typography className="text-gray" fontSize="1">Срок сдачи</Typography>
						<Typography className="text-bold" fontSize="1.1">{dateTimeEnd}</Typography>
					</div>
				</Grid>
			</Grid>
		</ListItem>
	)
}

export default ReadyItem
