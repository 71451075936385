import { useEffect, useState } from 'react';
import styled from "styled-components";
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { validateEmail } from 'utils/serviceUtils/validators';
import { FormControl, Typography, Grid, TextField, IconButton, Autocomplete } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clientsActions from 'modules/clients/data/actions';
import projectsActions from 'modules/projects/data/actions';
import settingsActions from 'modules/settings/data/actions';

import { Icon } from 'shared/Atoms';
import {
  ButtonLink, ButtonMainAccent, OutlinedInputField, ReactSelect, Footer, CheckBoxItem, ListItem, Loader, StrokeLabel, OutlinedSelectField, NativeDatePicker,
  AutocompleteSelectField, BorderLinearProgress
} from 'shared/Molecules';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadImages from './upload-images.component'
import UploadService from "./upload-files.service";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Button, withStyles } from '@material-ui/core';
import FileItem from './FileItem'
import projectsService from 'services/projects';

const ContentWrapperBlock = styled.section`
  border-radius: 10px;
  background-color: #fff;
  padding: 15px;

  .link{
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
  }
`;

const NewProject = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control, watch, setValue, reset } = useForm();
  const { touchedFields } = useFormState({control});

  useEffect(() => {
    dispatch(settingsActions.getFileTypes.request())
    dispatch(clientsActions.getClients.request({ page: 1, perPage: 10000 }))
  }, [])

  const { fileTypes } = useSelector(state => state.settingsReducer);
  const { clients } = useSelector(state => state.clientsReducer);
  const { uploadLoading } = useSelector(state => state.projectsReducer);

  const [currentFiles, setCurrentFiles] = useState([]);
  const [progress, setProgress] = useState(0);


  const [message] = useState('');
  const [isError] = useState(false);
  const [imageInfos] = useState([]);

  const selectFile = (event) => {
    setCurrentFiles(prev => {
      // console.log(prev);
      // console.log(event.target.files);
      if (prev?.length > 0) {
        return [...prev, ...Object.values(event.target.files).map((value, index) => {
          let obj = { file: value, id: prev[prev.length - 1].id + 1 + index }
          return obj
        })]
      } else {
        return [...Object.values(event.target.files).map((value, index) => {
          let obj = { file: value, id: index }

          return obj
        })]
      }
    })
    // this.setState(prev => {
    //     console.log(prev.currentFiles);
    //     console.log(event.target.files);
    //     let currentFiles;
    //     if (prev.currentFiles) {

    //         currentFiles = [...prev.currentFiles, ...Object.values(event.target.files)]
    //     } else {
    //         currentFiles = [...Object.values(event.target.files)]
    //     }

    //     return {
    //         currentFiles: currentFiles,
    //         previewImage: URL.createObjectURL(event.target.files[0]),
    //         progress: 0,
    //         message: ""
    //     }
    // });
  }
  const upload = (data) => {

    if (currentFiles.length === 0) {
      toast.error('Необходимо прикрепить минимум 1 файл для создания проекта!');
      return;
    }
    setProgress(0);
    dispatch(projectsActions.uploadFiles.request(currentFiles, data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) }))
    // projectsService.uploadFiles(currentFiles, data, (event) => { setProgress(Math.round((100 * event.loaded) / event.total)) })
    //   .then((response) => {
    // this.setState({
    //     message: response.data.message,
    //     isError: false
    // });
    // })
    // .then((files) => {
    // this.setState({
    //     imageInfos: files.data,
    // });
    // })
    // .catch((err) => {
    // this.setState({
    //     progress: 0,
    //     message: "Could not upload the image!",
    //     currentFiles: undefined,
    //     isError: true
    // });
    // });
  }


  const removeFile = (fileId) => {
    // console.log(fileId)
    setCurrentFiles(prev => [...prev.filter(value => value.id !== fileId)])
  }

  console.log(currentFiles)

  const [pagesTipes, setPagesTipes] = useState([]);
  const [totalProjectSum, setTotalProjectSum] = useState(0);

  const getCurrentPagesTipes = () => {
    let total = {};
    let totalSum = 0;
    for (let i = 0; i < currentFiles.length; i++) {
      const fileId = currentFiles[i].id;
      const countPagesTypes = watch("countPagesTypes" + fileId);
      
      for (let q = 0; q < countPagesTypes; q++) {
        let type = +watch('fileType' + fileId + '_' + q);
        let pages = +watch('pages' + fileId + '_' + q);
        let sum = +watch('price' + fileId + '_' + q);
        total[type] ? total[type].pages += pages : total[type] = { type, pages, name: fileTypes.filter(({ id }) => id === watch('fileType' + fileId + '_' + q))[0].name }

        if (pages && sum) {
          totalSum += pages * sum ;
        }
      }
      totalSum += +watch('extraPrice' + fileId);
    }

    setPagesTipes(Object.values(total));
    setTotalProjectSum(totalSum)
  }

  return (
    <ContentWrapperBlock>
      <Typography className=" mb-30" variant="h1">Новый проект</Typography>
      <FormControl component="form" onSubmit={handleSubmit(upload)} className="w-100">
        <Grid container spacing={2} xs={12}>
          <Grid item xs={6}>
            <ReactSelect
              isDisabled={uploadLoading}
              makedStyles='mt-20'
              required
              label='Клиент'
              placeholder="Клиент"
              control={control}
              name={"clientId"}
              rules={{ required: 'Поле обязательно к заполнению', }}
              options={clients?.items?.map(({ id, name }) => { return { value: id, label: name } }) || []}
            />
            <NativeDatePicker
              disabled={uploadLoading}
              required
              fullWidth={true}
              makedStyles='mt-20'
              label="Дата сдачи проекта"
              placeholder="Дата сдачи проекта"
              name="date"
              control={control}
              rules={{ required: 'Поле обязательно к заполнению' }}
            />
          </Grid>
          <Grid item xs={6} >
            <OutlinedInputField
              disabled={uploadLoading}
              makedStyles='mt-20'
              type="text"
              label="Номер в учете клиента"
              placeholder="Номер в учете клиента"
              name="number"
              control={control}
              defaultValue={''}
            />
            <NativeDatePicker
              disabled={uploadLoading}
              required
              fullWidth={true}
              makedStyles='mt-20'
              label="Время сдачи проекта"
              type="time"
              placeholder="Время сдачи проекта"
              name="time"
              control={control}
              rules={{ required: 'Поле обязательно к заполнению' }}
            />
          </Grid>
          <Grid item xs={12}>

            <div className="mg20">
              <ul className="file-name">
                {currentFiles ? Object.values(currentFiles)?.map((obj) => {
                  return (
                    <FileItem
                      fileTypes={fileTypes}
                      control={control}
                      watch={watch}
                      fileId={obj.id}
                      removeFile={removeFile}
                      disabled={uploadLoading}
                      setValue={setValue}
                      prices={clients?.items?.filter(({ id }) => id === watch('clientId'))[0]?.prices}
                      touchedFields={touchedFields}
                    >
                      {obj.file.name}
                    </FileItem>
                  )
                }) : ''}
              </ul>
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  multiple
                  onChange={selectFile}
                />
                <ButtonMainAccent
                  disabled={uploadLoading}
                  fullWidth={true}
                  className="btn-choose"
                  variant="outlined"
                  component="span"
                >
                  Добавить новый файл
                </ButtonMainAccent>
              </label>

              {uploadLoading && <BorderLinearProgress className="mt-15" variant="determinate" value={progress} />}
            </div >

          </Grid>
          <Grid item xs={12}>
            <ButtonMainAccent className="bg-primary" onClick={() => getCurrentPagesTipes()} disabled={uploadLoading} className="mt-10 bg-primary" fullWidth={true} variant="contained">
              Подвести итоги создания проекта
            </ButtonMainAccent>
          </Grid>
          <Grid item xs={6}>
            <Typography className='text-bold'>Итого по типам страниц в проекте:</Typography>
            <Grid container >
              {pagesTipes.map(({ name, pages }) => {
                return (
                  <>
                    <Grid item xs={8}>{name}</Grid>
                    <Grid item xs={4}>{pages} стр.</Grid>
                  </>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography className='text-bold'>Итоговая сумма проекта:</Typography>
            {totalProjectSum} руб.
          </Grid>

          <Grid item xs={12}>
            <ButtonMainAccent disabled={uploadLoading} loading={uploadLoading} className="mt-10" fullWidth={true} type="submit" variant="contained">
              Создать проект
            </ButtonMainAccent>
          </Grid>
        </Grid>
      </FormControl>
    </ContentWrapperBlock >
  );
}

export default NewProject
