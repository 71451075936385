import { FormControl, FormHelperText } from '@material-ui/core';
import { useController } from 'react-hook-form';
import clsx from 'clsx';

import styled, { css } from 'styled-components';
import { MaskedInput, InputLabel, OutlinedInput } from 'shared/Atoms';

const StyledFormControl = styled(FormControl)`
  width: 100%;

  .outlined-input-field {
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1.1em;

    input {
      height: 40px;
      // padding-left: 1em;
      padding-top: 0;
      padding-bottom: 0;
      color: #000;
    }

    input + fieldset {
      border-color: #e4e9f2;
      ${props => css`
        ${props.isTouched ? 'transparent' : '#cccccc18'}
      `}
    }
  }
`;

const TextMaskCustom = ({ inputRef, mask, ...restProps }) => (
  <MaskedInput {...restProps} guide={false} inputRef={inputRef} mask={mask} placeholderChar={'\u2000'} />
);

const MaskedOutlinedInputField = props => {
  const { label, name, makedStyles, rules, control, mask, ...rest } = props;
  const {
    field: { ref, ...inputProps },
    fieldState: {

    },
    formState: {
      errors,
      // touchedFields, dirtyFields
    },
  } = useController({
    name,
    control,
    rules,
    defaultValue: props.defaultValue && '',
  });

  return (
    <StyledFormControl className={clsx(makedStyles)}>
      {label && (
        <InputLabel shrink htmlFor={name}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={name}
        className={'outlined-input-field'}
        inputRef={ref}
        inputComponent={TextMaskCustom}
        inputProps={{ mask }}
        {...inputProps}
        {...rest}
      />
      {errors?.[name] && <FormHelperText error id={name}>{errors?.[name]?.message}</FormHelperText>}
    </StyledFormControl>
  );
};

export default MaskedOutlinedInputField;
