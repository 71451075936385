import { LinearProgress, withStyles } from '@material-ui/core'
 
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: "#EEEEEE",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);

const Progress = ({...props}) => (
    <BorderLinearProgress {...props} />
)

export default Progress