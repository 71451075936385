import {
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
} from "@material-ui/core";

import { InputLabel } from "../../Atoms";

import styled from "styled-components";

export const ErrorLabel = styled.label`
  color: red;
`;

const RadioButtonField = ({
  options = [],
  name,
  label = "",
  helpText = "",
  error,
  ...props
}) =>{
  
  return (
  <>
    <FormControl>
      <InputLabel shrink htmlFor={name} helpText={helpText} fontSize="1">
        {label}
      </InputLabel>

      <RadioGroup
        aria-label={name}
        name={name}
        // value={value}
        // onChange={handleChange}
        {...props}
      >
        {options.map((options) => (
          <FormControlLabel
            control={<Radio color="primary" />}
            {...options} // value={value} label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
    {!!error?.message && <ErrorLabel>{error?.message}</ErrorLabel>}
  </>
);
}
export default RadioButtonField;
